import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { slashimg } from 'resources/images/images';
import CustomSideBar from 'components/customsidebar';
import Header from 'components/header';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useLocation } from 'react-router-dom';
import { handleLeaveColor, handleLeaveText } from 'hooks/usecolorhook';
import PendingRequestCard from 'components/cards/pendingrequestcard';
import { getAllLeaveRequestApi } from 'networking/api/employee';
import { useAuthContext } from 'hooks/useauthcontext';
import moment from 'moment';
import LoadingSpinner from 'components/loadingspinner';

const PastRecords = () => {
  //context
  const { setSideBar, loader, setLoader } = useAppDataContext();
  const { user } = useAuthContext();
  //location
  const location = useLocation();
  //state
  const [activeTab, setActiveTab] = useState('All');
  const [leavesType, setLeaveType] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  //Mapping data for customsideBar
  const leftSideBarData = [
    {
      title: 'All requests',
      value: 'All',
    },
    {
      title: 'Approved requests',
      value: 'Approved',
    },
    {
      title: 'Denied requests',
      value: 'Denied',
    },
  ];

  //path based used to hide the leftsidebar
  useEffect(() => {
    if (location.pathname === '/pastrecords') {
      setSideBar(false);
    }
  }, []);

  //fetch data when the page onloads
  useEffect(() => {
    getAllLeaveRequests();
  }, []);

  useEffect(() => {
    if (activeTab === 'All') {
      setFilteredData(leavesType);
    } else if (activeTab === 'Approved') {
      setFilteredData(
        [...leavesType].filter((item) => item.status === 'Approved')
      );
    } else {
      setFilteredData(
        [...leavesType].filter((item) => item.status === 'Rejected')
      );
    }
  }, [leavesType, activeTab]);

  //api call get all leave requests
  const getAllLeaveRequests = async () => {
    try {
      setLoader(true);
      let response = await getAllLeaveRequestApi();

      if (response.data.type === 'success') {
        setLoader(false);
        setLeaveType(response.data.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.customSideBarStyles}>
          <CustomSideBar
            topTitle={'Filter options'}
            image={slashimg}
            dataOne={leftSideBarData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <div className={styles.headerAndEmployeeStyles}>
            <Header />
            <div className={styles.requestStyles}>
              <p
                className={styles.titleTextStyles}
              >{`${activeTab} requests`}</p>
              <div className={styles.requestStatusStyles}>
                {filteredData.length > 0 &&
                  filteredData.map((item, index) => {
                    const roleName = item?.employee?.roles?.map(
                      (role) => role.name
                    );
                    const experinace = item?.employee?.roles?.map((role) =>
                      role.experience > 18 ? 'Senior' : 'Junior'
                    );
                    return (
                      <PendingRequestCard
                        key={index}
                        backgroundColor={handleLeaveColor(item?.leave_type)}
                        borderTop={handleLeaveColor(item?.leave_type)}
                        border={handleLeaveColor(item?.leave_type)}
                        name={item?.employee?.full_name}
                        role={[...experinace, ...roleName]}
                        userImage={item?.employee?.image}
                        leaveStatus={`${item?.status} ${moment(
                          item.updated_at
                        ).format('MMM DD')}`}
                        startDate={moment(item.start_date).format('DD/MM/YYYY')}
                        endDate={moment(item.end_date).format('DD/MM/YYYY')}
                        totalDays={`${
                          Math.ceil(
                            (moment(item.end_date) - moment(item.start_date)) /
                              (1000 * 60 * 60 * 24)
                          ) + 1
                        } ${
                          Math.ceil(
                            (moment(item.end_date) - moment(item.start_date)) /
                              (1000 * 60 * 60 * 24)
                          ) +
                            1 ===
                          1
                            ? 'day'
                            : 'days'
                        }`}
                        leaveType={handleLeaveText(item.leave_type)}
                        desc={item.reason}
                        approval={true}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PastRecords;
