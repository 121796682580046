import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';
const TextArea = (props) => {
  const {
    customTextAreaStyles,
    customLabelTextStyles,
    customErrorTextStyle,
    label,
    name,
    placeholder,
    value,
    onFocus,
    onBlur,
    onChange,
    rows,
    disabled,
    hasError,
    ref,
    customTextAreaContainerStyles,
  } = props;

  return (
    <div
      className={classNames(
        styles.textAreaContainerStyles,
        customTextAreaContainerStyles
      )}
    >
      {label && (
        <label
          className={classNames(styles.labelTextStyles, customLabelTextStyles)}
        >
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        className={classNames(styles.textAreaStyles, customTextAreaStyles)}
        placeholder={placeholder}
        name={name}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        rows={rows}
        disabled={disabled}
      />
      {hasError && (
        <p className={classNames(styles.errorTextStyle, customErrorTextStyle)}>
          {hasError}
        </p>
      )}
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

export default TextArea;
