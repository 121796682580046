import React, { useEffect, useState } from 'react';
import OnGoingProjectCard from 'components/cards/ongoingprojectcard';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import useUserViewsHook from 'hooks/userViewshook';
import useTapOnProject from 'hooks/useTapOnProject';
import styles from './styles.module.css';

const AdminProjects = () => {
  // contexts:
  const { allProjectsData, allEmployeeProjects } = useProjectsDataContext();
  const { setRoutName, setSideBar, sideBar, activeTabs } = useAppDataContext();
  const { isAdmin, isEmployee } = useUserViewsHook();

  //routing
  const navigate = useNavigate();
  const location = useLocation();

  // hooks:
  const tapOnProject = useTapOnProject(
    isAdmin,
    isEmployee,
    setRoutName,
    navigate,
    setSideBar
  );

  // useEffect
  useEffect(() => {
    if (location.pathname === '/admin-projects') {
      setSideBar(true);
    }
  }, [sideBar]);

  const renderTopProjects = () => {
    const projectData = isAdmin
      ? allProjectsData.onGoing
      : isEmployee && allEmployeeProjects.onGoing;
    return (
      <div className={styles.topSectionStyles}>
        <div className={styles.topStyles}>
          <p className={styles.topTitleStyles}>
            {isAdmin ? 'Ongoing projects' : 'Assigned projects'}
          </p>
          <p className={styles.topCountStyles}>
            {projectData?.length &&
              (projectData?.length).toString().padStart(2, '0')}
          </p>
        </div>
        {projectData.length > 0 ? (
          <div className={styles.topCardStyles}>
            {projectData &&
              projectData.map((item, index) => {
                return (
                  <OnGoingProjectCard
                    key={index}
                    title={item.title}
                    icon="#"
                    technologies={
                      item.category_tags &&
                      item.category_tags.map((item) => item)
                    }
                    onClick={() =>
                      tapOnProject(item.title, item._id, item?.status)
                    }
                  />
                );
              })}
          </div>
        ) : (
          <p>No Projects Assigned</p>
        )}
      </div>
    );
  };

  const renderBottomProjects = () => {
    const projectData = isAdmin
      ? allProjectsData.completed
      : isEmployee && allEmployeeProjects.completed;
    return (
      <div className={styles.bottomSectionStyles}>
        <div className={styles.completedStyles}>
          <p className={styles.completedTitleStyles}>Completed projects</p>
          <p className={styles.completedCountStyles}>
            {projectData?.length &&
              (projectData?.length).toString().padStart(2, '0')}
          </p>
        </div>
        {projectData.length > 0 ? (
          <div className={styles.completedCardStyles}>
            {projectData &&
              projectData.map((item, index) => {
                return (
                  <OnGoingProjectCard
                    key={index}
                    title={item.title}
                    icon="#"
                    technologies={
                      item.category_tags &&
                      item.category_tags.map((item) => item)
                    }
                    onClick={() =>
                      tapOnProject(item.title, item._id, item?.status)
                    }
                  />
                );
              })}
          </div>
        ) : (
          <p>No Completed Projects</p>
        )}
      </div>
    );
  };

  return (
    <div className={styles.containerStyles}>
      {renderTopProjects()}
      {renderBottomProjects()}
    </div>
  );
};
export default AdminProjects;
