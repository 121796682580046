import {
  adminLogin,
  adminLoginApi,
  getUserDetailsApi,
} from 'networking/api/adminregister';
import { employeeLoginApi } from 'networking/api/employee';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { setAxiosSession } from 'utils/auth';
import { getDataFromToken, isTokenExpired } from 'utils/jwt';

export const AuthContext = createContext();

const AuthProvider = (props) => {
  const navigate = useNavigate();

  const [isInitialized, setIsInitialized] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [user, setUser] = useState(null);
  const initialize = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');

      if (token && isTokenExpired(token)) {
        setIsInitialized(false);
        setAxiosSession(token);
        const tokenData = await getDataFromToken(token);

        let response = await getUserDetailsApi(tokenData.id);
        if (response.data.type === 'success') {
          setUser(response.data.data);
          setUserType(tokenData.account_type);
          setAxiosSession(token);
          setIsLoggedIn(true);
          setIsInitialized(true);
        } else {
          setAuthError(response.data.message);
          setIsInitialized(true);
        }
      }
    } catch (error) {
      setIsInitialized(true);
      setIsLoggedIn(false);
      setAuthError(error.message);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const adminLoginData = async (loginData) => {
    try {
      setAuthError(null);
      let response = await adminLoginApi(loginData);

      const { type, data, token, message } = response.data;
      if (type === 'success') {
        localStorage.setItem('accessToken', token);
        const tokenData = await getDataFromToken(token);
        setIsLoggedIn(true);
        setUser(data);
        setUserType(tokenData.account_type);
        setAxiosSession(token);
        navigate('/dashboard');
      } else {
        setUser(null);
        setAuthError(message);
        setIsLoggedIn(false);
      }
    } catch (error) {
      setUser(null);
      setAuthError(error.message);
      setIsLoggedIn(false);
    }
  };

  const employeeLoginData = async (loginData) => {
    try {
      setAuthError(null);
      let respone = await employeeLoginApi(loginData);
      const { type, token, data, message } = respone.data;
      if (type === 'success') {
        localStorage.setItem('accessToken', token);
        const tokenData = await getDataFromToken(token);
        setIsLoggedIn(true);
        setUser(data);
        setUserType(tokenData.account_type);
        setAxiosSession(token);
        navigate('/dashboard');
      } else {
        setUser(null);
        setAuthError(message);
        setIsLoggedIn(false);
      }
    } catch (error) {
      setUser(null);
      setAuthError(error.message);
      setIsLoggedIn(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
    setUser(null);
    setAxiosSession(null);
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      setIsInitialized,
      isLoggedIn,
      setIsLoggedIn,
      userType,
      setUserType,
      authError,
      setAuthError,
      user,
      setUser,
      adminLoginData,
      employeeLoginData,
      logout,
    }),
    [
      isInitialized,
      setIsInitialized,
      isLoggedIn,
      setIsLoggedIn,
      userType,
      setUserType,
      authError,
      setAuthError,
      user,
      setUser,
      adminLoginData,
      employeeLoginData,
      logout,
    ]
  );
  return (
    <AuthContext.Provider value={memoizedValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
