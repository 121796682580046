import React, { useEffect, useState } from 'react';
import {
  avatar1,
  clockimg,
  closeIconWitOutBg,
  closeSquareBgImg,
  crossimg,
  dropDownThickArrow,
  dropdownarrow,
  excelIcon,
  leftArrow1,
  leftcurvearrow,
  rightArrow1,
  rightarrow,
  rightcurvearrow,
} from 'resources/images/images';

import InputWithDropDown from 'components/inputwithdropdown';
import useDateRange, {
  getCurrentMonthDates,
  getCurrentWeekDates,
  getLast30Days,
} from 'hooks/usecalenderhook';
import moment from 'moment/moment';
import { monthsData } from 'constants/commondata/commondata';
import DatesSection from 'components/datesSection';
import { useAuthContext } from 'hooks/useauthcontext';
import { useTimeSheetContext } from 'hooks/usetimesheetcontext';
import SearchInput from 'components/searchinput';
import EmployeeCard from 'components/cards/employeecard';
import Button from 'components/button';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import ReactPaginate from 'react-paginate';
import useUserViewsHook from 'hooks/userViewshook';
import Calender from 'components/calender';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import styles from './styles.module.css';

const AdminTimeSheet = () => {
  // contexts:
  const { user } = useAuthContext();
  const { allTimesSheetsData, employeeTimeSheet } = useTimeSheetContext();
  const { employee } = useEmployeeDataContext();
  const { isAdmin, isEmployee } = useUserViewsHook();

  // states:
  const [submittedTimeSheets, setSubmittedProjects] = useState();
  const [showMonthPopUp, setShowMonthPopUP] = useState(false);
  const [employeePopup, setEmployeePopUp] = useState(false);
  const [filterTimeType, setFilterTimeType] = useState('This month');
  const [searchEmployee, setSearchEmployee] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [showDatePopup, setShowDatePopup] = useState({
    fromDate: false,
    toDate: false,
  });
  const [selectDate, setSelectDate] = useState({
    fromDate: '',
    toDate: '',
  });

  const [activeDay, setActiveDay] = useState(
    moment(new Date()).format('DD/MM/YYYY')
  );

  const [pageNumber, setPageNumber] = useState(0);
  const timeSheetsPerPage = 8;

  const previousDatesData = useDateRange().dates;
  const timeFilterOptions = [
    'This month',
    'This week',
    monthsData[moment().subtract(1, 'months').month()],
    monthsData[moment().subtract(2, 'months').month()],
  ];

  // format date

  useEffect(() => {
    const adminOrEmployeeTimeSheets =
      (isAdmin && allTimesSheetsData) || (isEmployee && employeeTimeSheet);

    const filteredTimeSheetsData =
      adminOrEmployeeTimeSheets &&
      adminOrEmployeeTimeSheets.filter(
        (item) => moment(item?.created_at).format('DD/MM/YYYY') === activeDay
      );

    const filteredProjects =
      filteredTimeSheetsData &&
      filteredTimeSheetsData.map((item) => ({
        ...item,
        submittedProjects: item.submittedProjects?.filter(
          (project) => project.created_at === activeDay
        ),
      }));

    setSubmittedProjects(filteredProjects);
  }, [
    activeDay,
    employeeTimeSheet,
    allTimesSheetsData,
    filterTimeType,
    isAdmin,
    isEmployee,
  ]);

  const formatDate = (dateString) => {
    const date = moment(dateString, 'DD/MM/YYYY');
    const formattedDate = date.format('dddd, DD');
    return formattedDate;
  };

  // handle search employee profile
  const handleSearchInputChange = (query) => {
    setSearchEmployee(query);
  };

  // based on search filter employees
  const filteredEmployees = employee?.filter((item) =>
    item.full_name.toLowerCase().includes(searchEmployee.toLowerCase())
  );

  // function to select employee profile
  const handleEmployeeSelection = (item) => {
    setSelectedEmployee(item);
    setEmployeePopUp(false);
  };

  // function to handle select from and to date
  const handleToSelectFromAndToDate = (dateType, date) => {
    setSelectDate((prevState) => ({
      ...prevState,
      [dateType]: date,
    }));

    setShowDatePopup((prevState) => ({
      ...prevState,
      [dateType]: false,
    }));

    if (dateType === 'toDate' && selectDate?.fromDate) {
      setShowMonthPopUP(false);
    }
  };

  // dates displayed based on month-week-lastmonths-select(from & to dates)
  let filteredDates = [];
  if (selectDate.fromDate && selectDate.toDate) {
    filteredDates = previousDatesData.filter((date) =>
      moment(date).isBetween(
        selectDate.fromDate,
        selectDate.toDate,
        undefined,
        '[]'
      )
    );
  } else {
    // If either fromDate or toDate is not selected, use filterTimeType
    filteredDates =
      filterTimeType === 'This week'
        ? getCurrentWeekDates()
        : filterTimeType === 'This month'
        ? previousDatesData
        : filterTimeType === monthsData[moment().subtract(1, 'months').month()]
        ? getCurrentMonthDates({
            date: moment().subtract(1, 'months').toDate(),
            totalMonth: true,
          })
        : filterTimeType === monthsData[moment().subtract(2, 'months').month()]
        ? getCurrentMonthDates({
            date: moment().subtract(2, 'months').toDate(),
            totalMonth: true,
          })
        : [];
  }

  const handleExportToExcel = (activeDay) => {
    if (!submittedTimeSheets) {
      return;
    }

    // Define headers including the Date column
    const headers = [
      'Date',
      'Project',
      'Task title',
      'Task description',
      'Time spent',
    ];

    // Map submitted timesheets data and include the activeDay date as the 'Date' column
    const formattedData = submittedTimeSheets.map((item) => ({
      Date: activeDay,
      Project: item.project.title,
      'Task title': item.task,
      'Task description': item.description,
      'Time spent': item.time,
    }));

    // Convert the formatted data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData, {
      header: headers,
    });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TimeSheet');

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a Blob for the Excel file
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    // Save the Excel file
    saveAs(blob, `${activeDay}timesheet.xlsx`);
  };

  const renderFilterSection = () => {
    return (
      <div className={styles.monthAndAddEmployeeDropDownStyles}>
        <InputWithDropDown
          popupOpen={showMonthPopUp}
          onClick={() => setShowMonthPopUP(true)}
          rightIcon={dropDownThickArrow}
          customSubWrapperStyles={styles.monthFilterBtnStyles}
          customPopUpContainerStyles={styles.monthFilterPopUpStyles}
          customPlaceHolderStyles={styles.timeFilterTextStyles}
          showOverlay={true}
          selectedItem={filterTimeType}
        >
          <>
            <div className={styles.popupTopStyles}>
              <p className={styles.popupTopTextStyles}>Filter by time:</p>
              <div
                onClick={() => setShowMonthPopUP(false)}
                className={styles.crossIconStyles}
              >
                <img src={crossimg} alt="cross icon" />
              </div>
            </div>
            <div className={styles.filterTypesContainerStyles}>
              {timeFilterOptions &&
                timeFilterOptions?.map((item, index) => (
                  <p
                    className={styles.filterTypeTextStyles}
                    style={
                      item === filterTimeType ? { background: '#ECEEF4' } : {}
                    }
                    key={index}
                    onClick={() => {
                      setFilterTimeType(item);
                      setShowMonthPopUP(false);
                      setSelectDate({});
                    }}
                  >
                    {item}
                  </p>
                ))}
            </div>
            <div className={styles.dateRangeButtonWrapperStyles}>
              <InputWithDropDown
                placeholder={'From date'}
                popupOpen={showDatePopup.fromDate}
                onClick={() =>
                  setShowDatePopup({ ...showDatePopup, fromDate: true })
                }
                onClose={() =>
                  setShowDatePopup({ ...showDatePopup, fromDate: false })
                }
                rightIcon={dropdownarrow}
                selectedItem={
                  selectDate.fromDate
                    ? moment(selectDate?.fromDate)
                        .format('DD/MM/YYYY')
                        .toString()
                    : 'From date'
                }
                customPopUpContainerStyles={styles.fromDatePopUpStyles}
                customPlaceHolderStyles={
                  selectDate.fromDate && styles.fromDateTextStyles
                }
              >
                <Calender
                  onSelectDate={(date) =>
                    handleToSelectFromAndToDate('fromDate', date)
                  }
                  enablePreviousMonth={true}
                />
              </InputWithDropDown>

              <InputWithDropDown
                placeholder={'To date'}
                popupOpen={showDatePopup.toDate}
                onClick={() =>
                  setShowDatePopup({ ...showDatePopup, toDate: true })
                }
                onClose={() =>
                  setShowDatePopup({ ...showDatePopup, toDate: false })
                }
                rightIcon={dropdownarrow}
                customPopUpContainerStyles={styles.toDatePopUpStyles}
                selectedItem={
                  selectDate.toDate
                    ? moment(selectDate.toDate).format('DD/MM/YYYY').toString()
                    : 'To date'
                }
                customPlaceHolderStyles={
                  selectDate.toDate && styles.toDateTextStyles
                }
              >
                <Calender
                  onSelectDate={(date) =>
                    handleToSelectFromAndToDate('toDate', date)
                  }
                  enablePreviousMonth={true}
                />
              </InputWithDropDown>
            </div>
          </>
        </InputWithDropDown>

        {isAdmin && (
          <InputWithDropDown
            popupOpen={employeePopup}
            onClick={() => setEmployeePopUp(true)}
            rightIcon={dropDownThickArrow}
            customSubWrapperStyles={styles.employeeFilterBtnStyles}
            customPopUpContainerStyles={styles.employeeFilterPopUpStyles}
            customPlaceHolderStyles={styles.employeeFilterTextStyles}
            showOverlay={true}
            selectedItem={
              selectedEmployee ? selectedEmployee?.full_name : 'All employees'
            }
          >
            <div className={styles.topFilterEmployeeHeaderStyles}>
              <p className={styles.filterPeopleTextStyles}>Filter by people:</p>
              <div
                className={styles.closeImgBlockStyles}
                onClick={() => setEmployeePopUp(false)}
              >
                <img
                  src={closeIconWitOutBg}
                  alt="closeIconWitOutBg"
                  className={styles.imageStyles}
                />
              </div>
            </div>

            <div className={styles.middleSearchAndEmployeeDataStyles}>
              <SearchInput
                placeholder="Search people..."
                onSearchInputChange={handleSearchInputChange}
                searchContent={searchEmployee}
                customContainerStyles={styles.searchInputStyles}
                customCloseImgStyles={styles.closeImgStyles}
              />
              <div className={styles.employeeDataViewStyles}>
                {filteredEmployees?.map((item, index) => {
                  return (
                    <EmployeeCard
                      key={index}
                      image={item?.image ? item?.image : avatar1}
                      name={item?.full_name}
                      designation={item?.roles?.map(
                        (role) =>
                          ` ${role.experience > 18 ? 'Senior' : 'Junior'} ${
                            role.name
                          }`
                      )}
                      onInputClick={() => handleEmployeeSelection(item)}
                      customCardContainerStyles={
                        selectedEmployee._id === item._id
                          ? styles.activeEmployeeDataStyles
                          : styles.employeeDataStyles
                      }
                    />
                  );
                })}
              </div>
            </div>

            <div className={styles.allEmployeeBtnBlockStyles}>
              <Button
                title="All employees"
                btnStyle={styles.addEmployeeBtnStyles}
              />
            </div>
          </InputWithDropDown>
        )}
      </div>
    );
  };

  const renderTimesheetsSubHeadingSection = () => (
    <div className={styles.containerBottomHeadBlockStyles}>
      <h5 className={styles.subHeadTextStyles}>Submitted timesheets</h5>
      {/* {user}{' '} */}
      {isAdmin && (
        <div
          className={styles.exportExcelTBlockStyles}
          onClick={() => handleExportToExcel(activeDay)}
        >
          <img src={excelIcon} alt="icon" />
          <p className={styles.exportExcelTextStyles}>Export to excel</p>
        </div>
      )}
    </div>
  );

  const renderSubmittedTimeSheetsTableSection = () => {
    // based on employee selection display time sheets
    const filteredSubmittedTimeSheets = selectedEmployee
      ? submittedTimeSheets.filter(
          (item) => item.employee._id === selectedEmployee._id
        )
      : submittedTimeSheets;

    // handle pagination
    const pageCount = Math.ceil(
      filteredSubmittedTimeSheets?.length / timeSheetsPerPage
    );

    const formattedPageCount = pageCount.toString().padStart(2, '0');

    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const startIndex = pageNumber * timeSheetsPerPage;
    const endIndex = Math.min(
      startIndex + timeSheetsPerPage,
      filteredSubmittedTimeSheets?.length
    );
    const displayedTimeSheets = filteredSubmittedTimeSheets
      ? filteredSubmittedTimeSheets?.slice(startIndex, endIndex)
      : employeeTimeSheet && employeeTimeSheet.slice(startIndex, endIndex);

    return filteredSubmittedTimeSheets?.length ? (
      <div className={styles.timesheetsTableBlockStyles}>
        <div className={styles.timesheetsTableHeadingBlockStyles}>
          <p className={styles.projectTitleStyles}>Project</p>
          <p className={styles.taskTextStyles}>Task title</p>
          <p className={styles.projectTitleStyles}>Task description</p>
          <p className={styles.timeSpentTextStyles}>Time spent</p>
        </div>

        <div className={styles.timesheetsTableRowStyle}>
          {displayedTimeSheets?.map((item, index) => {
            return (
              <div className={styles.timesheetsTableSubRowStyle} key={index}>
                <div className={styles.projectDetailsBlockStyles}>
                  <div className={styles.employeeAvatarStyles}>
                    <img
                      src={avatar1}
                      alt="avatar of an employee"
                      className={styles.imageWidthStyles}
                    />
                  </div>
                  <p className={styles.projectTitleTextStyles}>
                    {item.project.title}
                  </p>
                </div>
                <p className={styles.projectTaskTextStyles}>{item.task}</p>
                <p className={styles.projectDescTextStyles}>
                  {item.description}
                </p>
                <p className={styles.timeTextStyles}>{item.time}</p>
              </div>
            );
          })}
        </div>
        <ReactPaginate
          previousLabel={
            <img
              src={leftArrow1}
              alt="Previous"
              className={styles.arrowImgStyles}
            />
          }
          nextLabel={
            <img
              src={rightArrow1}
              alt="Next"
              className={styles.arrowImgStyles}
            />
          }
          pageCount={formattedPageCount}
          onPageChange={changePage}
          marginPagesDisplayed={2}
          containerClassName={styles.paginationContainer}
          previousLinkClassName={styles.paginationPrevious}
          nextLinkClassName={styles.paginationNext}
          disabledClassName={styles.paginationDisabled}
          activeClassName={styles.paginationActive}
          pageRangeDisplayed={5}
          breakLabel={'...'}
          breakClassName={styles.paginationBreak}
          pageLinkClassName={styles.paginationPageLink}
          previousClassName={styles.paginationArrow}
          nextClassName={styles.paginationArrow}
        />
      </div>
    ) : (
      <p className={styles.noDataFoundTextStyles}>
        {selectedEmployee
          ? 'There are no submitted time sheets for this employee.'
          : 'There are no submitted time sheets.'}
      </p>
    );
  };

  return (
    <div className={styles.containerStyles}>
      <div className={styles.subContainerStyles}>
        {renderFilterSection()}
        <DatesSection
          datesData={filteredDates}
          activeDay={activeDay}
          setActiveDay={setActiveDay}
        />
      </div>
      <div className={styles.subContainerStyles}>
        {renderTimesheetsSubHeadingSection()}
        <div className={styles.dateBlockStyles}>
          <p className={styles.dateButtonStyles}>{formatDate(activeDay)}</p>
          <div className={styles.dateDividerStyles}></div>
        </div>
        {renderSubmittedTimeSheetsTableSection()}
      </div>
    </div>
  );
};
export default AdminTimeSheet;
