import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
const DatesSection = (props) => {
  const { datesData, activeDay, setActiveDay } = props;
  return (
    <div className={styles.datesContainerStyles}>
      {datesData.length > 0 &&
        datesData.map((item, index) => (
          <div
            key={index}
            onClick={() => setActiveDay(moment(item).format('DD/MM/YYYY'))}
            className={styles.dateContainerStyles}
            style={
              moment(item).format('DD/MM/YYYY') === activeDay
                ? { background: '#A3FFA0' }
                : moment(item).format('dd') === 'Sa' ||
                  moment(item).format('dd') === 'Su'
                ? { background: '#fff', border: '1px solid #ECEEF4' }
                : {}
            }
          >
            <p className={styles.dayTextStyles}>{moment(item).format('dd')}</p>
            <p className={styles.dateTextStyles}>{moment(item).format('DD')}</p>
          </div>
        ))}
    </div>
  );
};
DatesSection.propTypes = {
  datesData: PropTypes.array.isRequired,
};

export default DatesSection;
