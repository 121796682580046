import React, { useEffect, useRef, useState } from 'react';
import {
  attherateimg,
  avatar2,
  deleteimg,
  dropdownarrow,
  dropdownblackimg,
  xlimg,
} from 'resources/images/images';
import ButtonTab from 'components/buttontab';
import {
  checkInOutTableHeaderData,
  user_Types,
} from 'constants/commondata/commondata';
import { useAppDataContext } from 'hooks/useappdatacontext';
import OnGoingProjectCard from 'components/cards/ongoingprojectcard';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomSideBar from 'components/customsidebar';
import Header from 'components/header';
import {
  deleteEmployeeApi,
  getAIndividualEmployeeDataApi,
} from 'networking/api/adminregister';
import LoadingSpinner from 'components/loadingspinner';
import useUserViewsHook from 'hooks/userViewshook';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import { getIndividualEmployeeTimeSheetApi } from 'networking/api/employee';
import Popup from 'components/popup';
import Button from 'components/button';
import moment from 'moment';
import { handleColor } from 'hooks/usecolorhook';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import styles from './styles.module.css';
import useTapOnProject from 'hooks/useTapOnProject';

const IndividualEmployee = () => {
  // routing:
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeName, employeeId } = location.state;

  // contexts:
  const {
    userType,
    setSideBar,
    loader,
    setLoader,
    activeTabs,
    setActiveTabs,
    setRoutName,
  } = useAppDataContext();
  const { employee, getAllEmployeesData } = useEmployeeDataContext();

  const assignedEmployees = employee?.filter(
    (item) => item?.projects_assigned > 0 && item
  );

  const unAssignedEmployees = employee?.filter(
    (item) => item?.projects_assigned <= 0 && item
  );

  // hooks:
  const { isAdmin, isEmployee } = useUserViewsHook();
  const tapOnProject = useTapOnProject(
    isAdmin,
    isEmployee,
    setRoutName,
    navigate,
    setSideBar
  );

  // refs:
  const popupRef = useRef();

  // states:
  const [employeeData, setEmployeeData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checkInOutData, setCheckInOutData] = useState(null);

  // useEffects:
  useEffect(() => {
    if (location.pathname === '/admin-individualemployee') {
      setSideBar(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (
        activeTabs?.assigned?._id ||
        activeTabs?.unAssigned?._id ||
        employeeId
      ) {
        setLoader(true);
        await getAllIndividualEmployeeData(
          activeTabs?.assigned?._id || activeTabs?.unAssigned?._id || employeeId
        );
        await getIndividualEmployeeTimeheet(
          activeTabs?.assigned?._id || activeTabs?.unAssigned?._id || employeeId
        );
        setLoader(false);
      }
    };

    fetchData();
  }, [activeTabs?.assigned?._id || activeTabs?.unAssigned?._id || employeeId]);

  // function to handle delete employee

  const handleDeleteEmployee = async () => {
    try {
      setLoader(true);
      const response = await deleteEmployeeApi(
        activeTabs?.assigned?._id || activeTabs?.unAssigned?._id || employeeId
      );
      if (response.data.type === 'success') {
        navigate('/admin-employees');
        getAllEmployeesData();
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // get individual employee data

  const getAllIndividualEmployeeData = async () => {
    try {
      const response = await getAIndividualEmployeeDataApi(
        activeTabs?.assigned?._id || activeTabs?.unAssigned?._id || employeeId
      );
      if (response.data.type === 'success') {
        setLoader(false);
        setEmployeeData(response.data.data);
      } else {
        setLoader(false);
        setEmployeeData(null);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // get employee time sheet data
  const getIndividualEmployeeTimeheet = async () => {
    try {
      const response = await getIndividualEmployeeTimeSheetApi(
        activeTabs?.assigned?._id
          ? activeTabs?.assigned?._id
          : activeTabs?.unAssigned?._id
          ? activeTabs?.unAssigned?._id
          : employeeId && employeeId
      );
      if (response.data.type === 'success') {
        setCheckInOutData(response.data.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // function to handle export the check in and check out data of employee
  const handleExportToExcel = (employeeName) => {
    if (!checkInOutData || !employeeName) {
      return;
    }
    // Define headers for the Excel file
    const headers = [
      'Date',
      'Check In',
      'Check Out',
      'Duration',
      'Leave Status',
    ];

    const formattedData = [];

    checkInOutData.forEach((entry) => {
      if (entry.check_in_out && entry.check_in_out.length > 0) {
        entry.check_in_out.forEach((item) => {
          const row = [];
          row.push(moment(entry.date).format('DD/MM/YYYY'));
          row.push(moment(item.check_in).format('HH:mm'));
          row.push(moment(item.check_out).format('HH:mm'));

          // Calculate duration and add to row
          const checkInTime = moment(item.check_in);
          const checkOutTime = moment(item.check_out);
          const durationMinutes = checkOutTime.diff(checkInTime, 'minutes');
          row.push(
            durationMinutes <= 0
              ? 'N/A'
              : `${Math.floor(durationMinutes / 60)}:${durationMinutes % 60}`
          );
          row.push('N/A');
          formattedData.push(row.slice());
        });
      } else {
        const row = [];
        row.push(moment(entry.date).format('DD/MM/YYYY'));
        row.push('N/A', 'N/A');
        row.push('N/A');

        if (entry.leave_status) {
          row.push(entry.leave_status);
        } else {
          row.push('N/A');
        }

        formattedData.push(row.slice());
      }
    });
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...formattedData]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, employeeName);

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(blob, `${employeeName}_employee_data.xlsx`);
  };

  const renderTopSection = () => {
    const employeeExperinace = employeeData?.roles
      ?.map((item) => item.experience)
      .join(',');

    const employeeExperinaceRole =
      employeeExperinace > 18 ? 'Senior' : 'Junior';

    const employeeRole = employeeData?.roles
      ?.map((item) => item.name)
      .join(',');

    const employeeDesignation = employeeExperinaceRole + '' + employeeRole;

    const assignedTasks = employeeData?.projects?.filter(
      (item) => item?.status === 'Ongoing'
    );

    const completedProjects = employeeData?.projects?.filter(
      (item) => item?.status === 'Completed'
    );

    return (
      <div className={styles.overViewStyles}>
        <div className={styles.overViewGapStyles}>
          <p className={styles.overViewTitleStyles}>Overview </p>
          {isAdmin && (
            <div className={styles.deleteStyles}>
              <div className={styles.deleteImgStyles}>
                <img src={deleteimg} alt="" className={styles.imageStyles} />
              </div>
              <p
                className={styles.deleteTextStyles}
                onClick={() => setShowDeleteModal(true)}
              >
                Delete employee
              </p>
            </div>
          )}
        </div>
        <div className={styles.employeeDetialsAndExpStyles}>
          <div
            className={styles.employeeCardStyles}
            style={{
              backgroundColor: handleColor(
                assignedTasks?.length.toString().padStart(2, '0')
              ),
            }}
          >
            <div className={styles.employeeCardTopStyles}>
              <div className={styles.employeeImgStyles}>
                <img
                  src={employeeData?.image ? employeeData?.image : avatar2}
                  alt=""
                  className={styles.imageStyles}
                />
              </div>
              <div className={styles.employeeRoleAndNameStyles}>
                <p className={styles.nameStyles}>{employeeData?.full_name}</p>
                <p className={styles.roleStyles}>{employeeDesignation}</p>
              </div>
            </div>
            <div className={styles.employeeCardBottomStyles}>
              <p className={styles.countStyles}>
                <span className={styles.assignedTitleStyles}>
                  Assigned tasks:
                </span>
                {assignedTasks?.length.toString().padStart(2, '0')}
              </p>
              <p className={styles.countStyles}>
                <span className={styles.assignedTitleStyles}>
                  Account type:
                </span>
                {employeeData?.account_type}
              </p>
              <p className={styles.countStyles}>
                <span className={styles.assignedTitleStyles}>
                  Completed projects:
                </span>
                {completedProjects?.length.toString().padStart(2, '0')}
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: handleColor(
                assignedTasks?.length.toString().padStart(2, '0')
              ),
            }}
            className={styles.employeeExperinaceCardStyles}
          >
            {employeeData?.roles?.map((item, index) => {
              // Calculate years and months from experience (assuming it's in months)
              const years = Math.floor(item.experience / 12);
              const months = item.experience % 12;

              // Convert experience to years and months format
              let experienceText;
              if (years === 0) {
                experienceText = `${months} months`;
              } else if (months === 0) {
                experienceText = `${years} years`;
              } else {
                experienceText = `${years} years ${months} months`;
              }
              return (
                <div
                  className={styles.employeeExperinaceDataStyles}
                  key={index}
                >
                  <p className={styles.roleNameTextStyles}>{item?.name}</p>
                  &nbsp; -
                  <p className={styles.experinaceTextStyles}>
                    {experienceText}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {renderDeleteEmployeeModalSection()}
      </div>
    );
  };

  const renderDeleteEmployeeModalSection = () => {
    return (
      <Popup
        show={showDeleteModal}
        showOverlay={true}
        popupRef={popupRef}
        customPopUpStyles={styles.deleteModalStyles}
      >
        <div className={styles.deleteEmployeeModalContentStyles}>
          <div className={styles.deleteEmployeeDescViewStyles}>
            <h5 className={styles.projectCompleteHeadingStyles}>
              Delete Employee Profile?
            </h5>
          </div>

          <p className={styles.deleteProfileDescStyles}>
            Are you Sure want to delete {employeeData?.full_name} profile
          </p>
          <div className={styles.bottmBtnBlockStyles}>
            <Button
              title="Cancel"
              btnStyle={styles.cancelBtnStyles}
              onClick={() => setShowDeleteModal(false)}
            />
            <Button
              title="Proceed"
              btnStyle={styles.proceedBtnStyles}
              onClick={() => handleDeleteEmployee()}
            />
          </div>
        </div>
      </Popup>
    );
  };

  const renderMiddleSection = () => {
    return (
      <div className={styles.checkInOutStyles}>
        <div className={styles.checkInOutTopStyles}>
          <p className={styles.checkInTitleStyles}>Check-In and Check-Out</p>
          {userType === user_Types.employee ? (
            <ButtonTab
              titleText={'Last 30 days'}
              icon={dropdownarrow}
              btnTextStyles={styles.tabTextStyles}
              buttonTabStyles={styles.tabStyles}
              customimgContainerStyle={styles.customImgStyles}
            />
          ) : (
            <ButtonTab
              titleText={'Export to excel'}
              icon={xlimg}
              onClick={() =>
                handleExportToExcel(
                  activeTabs?.assigned
                    ? activeTabs?.assigned.full_name
                    : activeTabs?.assigned
                    ? activeTabs?.unAssigned?.full_name
                    : employeeName && employeeName
                )
              }
              btnTextStyles={styles.xlTextStyles}
              buttonTabStyles={styles.xltabStyles}
              customimgContainerStyle={styles.customXlImgStyles}
            />
          )}
        </div>
        {userType === user_Types.admin && (
          <div className={styles.checkInOutMiddleStyles}>
            <ButtonTab
              titleText={'This month'}
              icon={dropdownblackimg}
              btnTextStyles={styles.thisMonthTextStyles}
              buttonTabStyles={styles.thisMonthTabStyles}
              customimgContainerStyle={styles.customImgStyles}
            />
          </div>
        )}
        <div className={styles.checkInOutBottomStyles}>
          <div className={styles.tableHeaderStyles}>
            {checkInOutTableHeaderData.map((item, index) => {
              return (
                <p key={index} className={styles.headerTitleStyle}>
                  {item.header}
                </p>
              );
            })}
          </div>
          <div className={styles.tableSubDescStyles}>
            {checkInOutData?.length > 0 ? (
              checkInOutData?.map((entry, entryIndex) => {
                return (
                  <React.Fragment key={entryIndex}>
                    {entry.check_in_out && entry.check_in_out.length > 0 ? (
                      entry.check_in_out.map((item, index) => {
                        // check in - out time
                        const checkInTime = moment(item.check_in);
                        const checkOutTime = moment(item.check_out);
                        const diffrenceInMinutes = checkOutTime.diff(
                          checkInTime,
                          'minutes'
                        );
                        const diffrenceInHours = Math.floor(
                          diffrenceInMinutes / 60
                        );
                        const remainingMinutes = diffrenceInMinutes % 60;
                        return (
                          <div
                            key={`${entryIndex}-${index}`}
                            className={styles.tableDescStyles}
                          >
                            <p className={styles.yearStyles}>
                              <span className={styles.totalMonthStyles}>
                                {moment(item.check_in).format(
                                  'DD MMM YYYY, ddd'
                                )}
                              </span>
                            </p>

                            <p className={styles.checkInStyles}>
                              {checkInTime.format('HH:mm')}
                            </p>
                            <p className={styles.checkOutStyles}>
                              {checkOutTime.format('HH:mm')}
                            </p>
                            <p className={styles.checkOutStyles}>
                              {diffrenceInMinutes <= 60
                                ? `${diffrenceInMinutes} minutes`
                                : `${diffrenceInHours}.${remainingMinutes} hours`}
                            </p>
                          </div>
                        );
                      })
                    ) : entry.leave_status ? (
                      <div className={styles.tableDescStyles}>
                        <p className={styles.yearStyles}>
                          <span className={styles.totalMonthStyles}>
                            {moment(entry.date).format('DD MMM YYYY, ddd')}
                          </span>
                        </p>
                        {[1, 2, 3].map((status, index) => (
                          <p className={styles.leaveStatusStyles} key={index}>
                            {`${entry.leave_status} - leave`}
                          </p>
                        ))}
                      </div>
                    ) : (
                      <div className={styles.tableDescStyles}>
                        <p className={styles.yearStyles}>
                          <span className={styles.totalMonthStyles}>
                            {moment(entry.date).format('DD MMM YYYY, ddd')}
                          </span>
                        </p>
                        {[1, 2, 3].map((status, index) => (
                          <p
                            className={styles.notAvailableTextStyles}
                            key={index}
                          >
                            N/A
                          </p>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <p className={styles.noDataFoundTextStyles}>--No Data Found--</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    const assignedTasks = employeeData?.projects?.filter(
      (item) => item?.status === 'Ongoing'
    );
    return (
      <div className={styles.assiginedStyles}>
        <p className={styles.assiginedTitleStyles}>Assigned projects</p>
        <div className={styles.projectCardStyles}>
          {assignedTasks?.length > 0 ? (
            assignedTasks?.map((item, index) => {
              return (
                <OnGoingProjectCard
                  key={index}
                  title={item.title}
                  technologies={item.category_tags.map((tag) => `#${tag}`)}
                  onClick={() => tapOnProject(item?.title, item?._id)}
                />
              );
            })
          ) : (
            <p className={styles.noDataFoundTextStyles}>--No Data Found--</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.customSideBarStyles}>
          <CustomSideBar
            topTitle={'Assigned'}
            bottomTitle={'Unassigned'}
            bottomSection={true}
            image={attherateimg}
            dataOne={assignedEmployees}
            dataTwo={unAssignedEmployees}
            activeTab={activeTabs?.assigned}
            setActiveTab={(value) =>
              setActiveTabs({ ...activeTabs, assigned: value })
            }
            isActiveTabTwo={activeTabs?.unAssigned}
            setActiveTabTwo={(value) =>
              setActiveTabs({ ...activeTabs, unAssigned: value, assigned: '' })
            }
          />
          <div className={styles.headerAndEmployeeStyles}>
            <Header />
            <div className={styles.individualEmployeeStyles}>
              {renderTopSection()}
              {renderMiddleSection()}
              {renderBottomSection()}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default IndividualEmployee;
