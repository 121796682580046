import React from 'react';
import styles from './styles.module.css';

const NotificationCard = (props) => {
  const { image, altText, message, time, index } = props;
  return (
    <div className={styles.notificationCardContainer}>
      <div
        className={
          index === 1 || index === 3 || index === 4
            ? styles.activeLeftImgStyles
            : styles.leftImageBlockStyles
        }
      >
        <img src={image} alt={altText} className={styles.imageStyles} />
      </div>
      <div className={styles.rightNotificationContentBlockStyles}>
        <p className={styles.messageTextStyles}>{message}</p>
        <p className={styles.timeTextStyles}>{time}</p>
      </div>
    </div>
  );
};

export default NotificationCard;
