import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const InputWithDropDown = (props) => {
  const {
    labelText,
    onClick = () => {},
    onClickInput = () => {},
    placeholder,
    customPlaceHolderStyles,
    rightIcon,
    customPopUpContainerStyles,
    children,
    popupOpen,
    customLabelTextStyle,
    customSubWrapperStyles,
    customWrapperStyles,
    showOverlay,
    onClose = () => {},
    selectedItem,
    leftIcon,
    hasError,
  } = props;
  const popUpRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (popupOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupOpen, onClose]);

  return (
    <div
      className={classNames(
        styles.inputWithDropDownWrapperStyle,
        customWrapperStyles
      )}
      onClick={onClickInput}
    >
      {labelText && (
        <p className={classNames(styles.labelTextStyle, customLabelTextStyle)}>
          {labelText}
        </p>
      )}
      <div
        className={classNames(
          styles.inputWithDropDownSubWrapperStyle,
          customSubWrapperStyles
        )}
      >
        {leftIcon && (
          <div className={styles.leftImgViewStyle}>
            <img src={leftIcon} alt="leftIcon" className={styles.imageStyles} />
          </div>
        )}

        <p
          className={classNames(
            styles.placeHolderTextStyle,
            customPlaceHolderStyles
          )}
        >
          {selectedItem || placeholder}
        </p>
        {rightIcon && (
          <div onClick={onClick} className={styles.rightImgViewStyle}>
            <img
              src={rightIcon}
              alt="dropdown"
              className={styles.imageStyles}
            />
          </div>
        )}
      </div>
      {hasError && <p className={styles.errorMessageStyles}>{hasError}</p>}
      {popupOpen && (
        <>
          {showOverlay && <div className={styles.overlayStyles}></div>}
          <div
            ref={popUpRef}
            className={classNames(
              styles.popUpStyles,
              customPopUpContainerStyles
            )}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};

InputWithDropDown.propTypes = {
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  placeholder: PropTypes.any,
  children: PropTypes.node,
  popupOpen: PropTypes.bool,
  customLabelTextStyle: PropTypes.string,
  showOverlay: PropTypes.bool,
  onClose: PropTypes.func,
};

export default InputWithDropDown;
