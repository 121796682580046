import React from 'react';
import styles from './styles.module.css';
import { Tooltip } from 'react-tooltip';

const ProjectAndEmployeeCard = (props) => {
  const {
    projectImage,
    employeeImage,
    altText,
    name,
    description,
    status,
    assignedStatus,
    onClick = () => {},
  } = props;

  return (
    <div
      className={styles.projectEmployeeContainerStyles}
      // data-tooltip-content={description}
      // data-tooltip-id={`tooltip-${name}`}
      onClick={onClick}
    >
      <div className={styles.leftDetailsSection}>
        {projectImage && (
          <div className={styles.leftImageBlockStyles}>
            <img
              src={projectImage}
              alt={altText}
              className={styles.imageStyles}
            />
          </div>
        )}
        {employeeImage && (
          <div className={styles.personImageBlockStyles}>
            <img
              src={employeeImage}
              alt={altText}
              className={styles.imageStyles}
            />
          </div>
        )}
        <div className={styles.detailsBlockStyles}>
          <h5 className={styles.nameHeadingStyles}>{name}</h5>
          <p className={styles.descTextStyles}>{description}</p>
          {/* <Tooltip id={`tooltip-${name}`} place="bottom" effect="solid" /> */}
        </div>
      </div>
      {status && (
        <p
          className={
            status === 'Completed'
              ? styles.completedStatusStyles
              : status === 'Ongoing'
              ? styles.onGoingStatusStyles
              : styles.leftTextStyles
          }
        >
          {status}
        </p>
      )}
      {assignedStatus && (
        <p className={styles.assignedStatusStyles}>{assignedStatus}</p>
      )}
    </div>
  );
};

export default ProjectAndEmployeeCard;
