import React from 'react';
import styles from './styles.module.css';
import { clockimg } from 'resources/images/images';

const SelectTimeCard = (props) => {
  const { onClickTimeOptions, time, selectTime } = props;
  return (
    <div
      className={
        selectTime
          ? styles.activeSelectTimeStyles
          : styles.selectTimeCardContainerStyles
      }
      onClick={() => onClickTimeOptions()}
    >
      <div className={styles.leftArrowImgStyles}>
        <img src={clockimg} alt="searchimg" className={styles.imageWidth} />
      </div>
      <h5 className={styles.selectTimeTextStyles}>{time}</h5>
    </div>
  );
};

export default SelectTimeCard;
