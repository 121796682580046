import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const ProjectCategoryCard = ({
  categories,
  icon,
  selectedCategories,
  onCategoryClick,
  customSelectCategoryCardStyles,
}) => {
  return (
    <div
      className={classNames(
        styles.selectCategoryCardStyles,
        customSelectCategoryCardStyles
      )}
    >
      {categories.map((category, index) => (
        <p
          key={index}
          className={`${styles.selectOptionTextStyles} ${
            selectedCategories.includes(category) ? styles.selectedCategory : ''
          }`}
          onClick={() => onCategoryClick(category)}
        >
          {icon} {category}
        </p>
      ))}
    </div>
  );
};

ProjectCategoryCard.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  onCategoryClick: PropTypes.func,
};

export default ProjectCategoryCard;
