import React, { useRef, useState } from 'react';
import { eclocklogo, workingimg } from 'resources/images/images';
import Input from 'components/input';
import Button from 'components/button';
import styles from './styles.module.css';
import { forGetPasswordApi } from 'networking/api/adminregister';
import { useNavigate } from 'react-router-dom';
import Popup from 'components/popup';
import { useAppDataContext } from 'hooks/useappdatacontext';
import LoadingSpinner from 'components/loadingspinner';

const ForgotPassword = () => {
  //context:-
  const { loader, setLoader } = useAppDataContext();

  //ref:-
  const popUpRef = useRef();
  //state:-
  const [sendPasswordData, setSendPasswordData] = useState('');
  const [modal, setModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    email: '',
    forGetPassword: '',
  });

  const handleSendPassword = () => {
    if (sendPasswordData === '') {
      setErrorMsg({ ...errorMsg, email: 'Please enter email' });
    } else {
      handleConfirmPassword();
    }
  };

  const handleConfirmPassword = async () => {
    try {
      setLoader(true);
      let data = {
        email: sendPasswordData,
      };
      const response = await forGetPasswordApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        setModal(true);
      } else {
        setLoader(false);
        setErrorMsg({ ...errorMsg, forGetPassword: response.data.message });
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const renderHeaderTopSection = () => {
    return (
      <div className={styles.eclockImgBlockStyles}>
        <img src={eclocklogo} alt="eclocklogo" className={styles.imageWidth} />
      </div>
    );
  };
  const renderLeftSection = () => {
    return (
      <div className={styles.leftSectionImgStyles}>
        <img src={workingimg} alt="workingimg" className={styles.imageStyles} />
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className={styles.forgetPasswordSectionStyles}>
        <p className={styles.forgotPasswordTextStyles}>Forget password</p>
        <div className={styles.inputAndBtnStyles}>
          <Input
            type="text"
            name="email"
            placeholder="Enter registered email"
            lableText="Email*"
            value={sendPasswordData}
            onChange={(e) => setSendPasswordData(e.target.value)}
            onFocus={() => {
              setErrorMsg('');
            }}
            hasError={errorMsg.email}
          />
          {errorMsg.forGetPassword && (
            <p className={styles.errorMsgStyles}>{errorMsg.forGetPassword}</p>
          )}
          <Button
            onClick={() => handleSendPassword()}
            title="Send link"
            btnStyle={
              sendPasswordData === ''
                ? styles.unActiveBtnStyles
                : styles.activeBtnStyles
            }
          />
        </div>
      </div>
    );
  };

  const renderForgetPasswordPopUp = () => {
    return (
      <Popup
        show={modal}
        customPopUpStyles={styles.forGetPasswordPopUpStyles}
        popupRef={popUpRef}
        showOverlay={true}
      >
        <div className={styles.successFullPopUpStyles}>
          <div className={styles.successFulTitleStyles}>
            <p className={styles.registerTitleStyles}>
              Your password link sent to your email
            </p>
            <p className={styles.registerTitleStyles}>
              please check your email
            </p>
          </div>
        </div>
      </Popup>
    );
  };

  return (
    <>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.forgotPasswordContainerStyles}>
          {renderHeaderTopSection()}
          <div className={styles.leftRightSectionStyles}>
            {renderLeftSection()} {renderRightSection()}
          </div>
          {renderForgetPasswordPopUp()}
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
