import AdminRegister from 'pages/admin/adminregister';
import { AppDataProvider } from 'provider/appdataprovider';
import Authprovider from 'provider/authprovider';
import AuthenticationProvider from 'provider/authprovider';
import UserDataProvider from 'provider/authprovider';
import EmployeeDataProvider from 'provider/employeedataprovider';
import ProjectsDataProvider from 'provider/projectsdataprovider';
import TimeSheetsProvider from 'provider/timesheetsprovider';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Authprovider>
          <AppDataProvider>
            <EmployeeDataProvider>
              <ProjectsDataProvider>
                <TimeSheetsProvider>
                  <AppRoutes />
                </TimeSheetsProvider>
              </ProjectsDataProvider>
            </EmployeeDataProvider>
          </AppDataProvider>
        </Authprovider>
      </BrowserRouter>
    </div>
  );
}

export default App;
