import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

const EmployeeCard = (props) => {
  const {
    image,
    name,
    designation,
    count,
    onInputClick,
    customCardContainerStyles,
  } = props;
  return (
    <div
      className={classNames(
        styles.employeeCardContainerStyles,
        customCardContainerStyles
      )}
      onClick={onInputClick}
    >
      <div className={styles.leftEmployeeDetailsStyles}>
        <div className={styles.employeeImgBlockStyles}>
          <img src={image} alt={image} className={styles.imageWidth} />
        </div>
        <div className={styles.employeeDetailsStyles}>
          <p className={styles.employeeNameStyles}>{name}</p>
          <p className={styles.employeeDesignationStyles}>{designation}</p>
        </div>
      </div>
      {count && <div className={styles.rightEmployeeCountStyles}>{count}</div>}
    </div>
  );
};

export default EmployeeCard;
