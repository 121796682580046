import React, { useEffect, useState } from 'react';
import { user_Types } from 'constants/commondata/commondata';
import { useAuthContext } from './useauthcontext';

const useUserViewsHook = () => {
  // contexts
  const { userType } = useAuthContext();

  // states
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);

  //   useEffect
  useEffect(() => {
    setIsAdmin(userType === user_Types.admin);
    setIsEmployee(userType === user_Types.employee);
  }, [userType]);
  return {
    isAdmin,
    isEmployee,
  };
};

export default useUserViewsHook;
