import React, { useState } from 'react';
import {
  avatar4,
  closeBackgroundImg,
  crosscolorimg,
  tickcolorimg,
} from 'resources/images/images';
import ButtonTab from 'components/buttontab';
import styles from './styles.module.css';
import Button from 'components/button';
import { handleLeaveColor } from 'hooks/usecolorhook';

const PendingRequestCard = (props) => {
  const [denyRequest, setDenyRequest] = useState(true);

  const {
    name,
    role,
    startDate,
    endDate,
    totalDays,
    leaveType,
    desc,
    backgroundColor,
    borderTop,
    userImage,
    border,
    showActionBtns,
    approval,
    leaveStatus,
    onClickApprove = () => {},
    onClickDeny = () => {},
  } = props;
  const topSection = () => {
    return (
      <div className={styles.topSectionStyles}>
        <div className={styles.userDetailsStyles}>
          <div className={styles.userLeftProfileStyles}>
            <div className={styles.userProfileStyles}>
              <img
                src={userImage || avatar4}
                alt=""
                className={styles.imageStyles}
              />
            </div>

            <div className={styles.userNameRoleStyles}>
              <p className={styles.nameStyles}>{name}</p>
              <p className={styles.roleStyles}>{role}</p>
            </div>
          </div>
          {approval && (
            <div className={styles.approvalStyles}>
              <div className={styles.closeImgStyles}>
                <img
                  src={closeBackgroundImg}
                  alt="closeicon"
                  className={styles.imageStyles}
                />
              </div>
              <p className={styles.deniedTextStyles}>{leaveStatus}</p>
            </div>
          )}
        </div>
        <div className={styles.dateSectionStyles}>
          <div className={styles.startDayStyles}>
            <p className={styles.startFromNumStyles}>
              <span className={styles.startFromTextStyles}>Starts from:</span>
              {startDate}
            </p>
          </div>
          <div className={styles.endDayStyles}>
            <p className={styles.endOnNumStyles}>
              <span className={styles.endOnTextStyles}>Ends on:</span> {endDate}
            </p>
          </div>
          <div className={styles.totalDayStyles}>
            <p className={styles.totalNumStyles}>
              <span className={styles.totalTextStyles}>Total:</span>
              {totalDays}
            </p>
          </div>
        </div>
        <ButtonTab
          titleText={leaveType}
          btnTextStyles={styles.leaveTypeTextStyles}
          buttonTabStyles={styles.leaveTypeTabStyles}
        />
        <p className={styles.resaonDescStyles}>
          <span className={styles.reasonTitleStyles}>Reason:</span>
          {desc}
        </p>
      </div>
    );
  };

  const bottomSection = () => {
    return (
      <div
        className={styles.bottomStyles}
        style={{ borderTopColor: borderTop }}
      >
        <Button
          title="Deny"
          icon={crosscolorimg}
          onClick={onClickDeny}
          btnStyle={styles.denyBtnStyles}
          customImgStyle={styles.crossImgStyles}
        />
        <Button
          title="Approve"
          icon={tickcolorimg}
          onClick={onClickApprove}
          btnStyle={styles.approveBtnStyles}
          customImgStyle={styles.crossImgStyles}
        />
      </div>
    );
  };
  return (
    <>
      {denyRequest && (
        <div
          className={styles.pendingRequestStyles}
          style={{ background: backgroundColor }}
        >
          {topSection()}
          {showActionBtns && bottomSection()}
        </div>
      )}
    </>
  );
};

export default PendingRequestCard;
