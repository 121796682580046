import { EmployeeDataContext } from 'provider/employeedataprovider';
import { useContext } from 'react';

export const useEmployeeDataContext = () => {
  const context = useContext(EmployeeDataContext);
  if (!context) {
    throw new Error(
      'useEmployeeContext context must be use inside EmployeeDataProvider'
    );
  }
  return context;
};
