import React from 'react';
import ButtonTab from 'components/buttontab';
import styles from './styles.module.css';

const TodayLeaveCard = (props) => {
  const { userName, userRole, leaveType, userProfile } = props;
  return (
    <div className={styles.gapStyles}>
      <div className={styles.todayLeaveLeftStyles}>
        <div className={styles.userImgStyles}>
          <img src={userProfile} alt="" className={styles.imageStyles} />
        </div>
        <div className={styles.userNameAndRoleStyles}>
          <p className={styles.userNameStyles}>{userName}</p>
          <p className={styles.userRoleStyles}>{userRole}</p>
        </div>
      </div>
      <ButtonTab
        titleText={leaveType}
        btnTextStyles={styles.paidTextStyles}
        buttonTabStyles={styles.paidBtnTabStyles}
      />
    </div>
  );
};

export default TodayLeaveCard;
