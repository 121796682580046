import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from 'hooks/useauthcontext';
import { user_Types } from 'constants/commondata/commondata';
import LoadingSpinner from 'components/loadingspinner';

const AuthGuard = (props) => {
  const { userType: guardUserType, children } = props;
  const { isLoggedIn, isInitialized, userType } = useAuthContext();

  let loginRoute = '/login';
  if (guardUserType.includes(userType) && userType === user_Types.employee) {
    loginRoute = '/login';
  } else if (
    guardUserType.includes(userType) &&
    userType === user_Types.admin
  ) {
    loginRoute = '/admin/login';
  }

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  if (isLoggedIn) {
    if (!guardUserType?.includes(userType)) {
      return <Navigate to={loginRoute} />;
    }
  }

  if (!isLoggedIn && !localStorage.getItem('accessToken')) {
    return <Navigate to={loginRoute} />;
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  userType: PropTypes.arrayOf(
    PropTypes.oneOf([user_Types.admin, user_Types.employee])
  ),
  children: PropTypes.node,
};

export default AuthGuard;
