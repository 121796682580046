import React from 'react';
import styles from './styles.module.css';

const ButtonTab = (props) => {
  const {
    titleText,
    btnTextStyles,
    buttonTabStyles,
    image,
    customimgContainerStyle,
    icon,
    onClick = () => {},
  } = props;
  return (
    <div
      className={[styles.buttonTabStyles, buttonTabStyles].join(' ')}
      onClick={onClick}
    >
      {icon && (
        <div
          className={[styles.imgContainerStyle, customimgContainerStyle].join(
            ' '
          )}
        >
          {image ? <>{icon}</> : <img src={icon} className={styles.imgStyle} />}
        </div>
      )}
      <p className={[styles.btnTextStyles, btnTextStyles].join(' ')}>
        {titleText}
      </p>
    </div>
  );
};

export default ButtonTab;
