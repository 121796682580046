import React, { useState } from 'react';
import { attherateimg, backarrowimg, hashIcon } from 'resources/images/images';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

const CustomSideBar = (props) => {
  const {
    topTitle,
    bottomTitle,
    image,
    dataOne,
    dataTwo,
    bottomSection,
    activeTab,
    setActiveTab = () => {},
    isActiveTabTwo,
    setActiveTabTwo = () => {},
  } = props;
  const navigate = useNavigate();

  const renderBackButtonSection = () => {
    return (
      <div
        onClick={() => {
          navigate(-1);
          setActiveTab('');
          setActiveTabTwo('');
        }}
        className={styles.backButtonStyles}
      >
        <div className={styles.backArrowStyles}>
          <img src={backarrowimg} alt="" className={styles.imageStyles} />
        </div>
        <p className={styles.backTitleStyles}>Back</p>
      </div>
    );
  };

  const renderMainSection = () => {
    return (
      <div className={styles.mainStyles}>
        <div className={styles.topStyles}>
          <p className={styles.topTitleStyles}>{topTitle}</p>
          {dataOne &&
            dataOne?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    (activeTab?._id && activeTab?._id === item?._id) ||
                    (activeTab && activeTab === item?.value)
                      ? styles.assignedStyles
                      : styles.unAssignedStyles
                  }
                >
                  <div
                    onClick={() => setActiveTab(item?.value || item)}
                    className={styles.topDescStyles}
                  >
                    <div className={styles.topImgStyles}>
                      <img src={image} alt="" className={styles.imageStyles} />
                    </div>
                    <p className={styles.topDescTextStyles}>
                      {item.title || item?.full_name}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
        {bottomSection && (
          <div className={styles.bottomStyles}>
            <p className={styles.topTitleStyles}>{bottomTitle}</p>
            {dataTwo?.length > 0 ? (
              dataTwo?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      (isActiveTabTwo?._id &&
                        isActiveTabTwo?._id === item?._id) ||
                      (isActiveTabTwo && isActiveTabTwo === item?.value)
                        ? styles.assignedStyles
                        : styles.unAssignedStyles
                    }
                    onClick={() => setActiveTabTwo(item?.value || item)}
                  >
                    <div className={styles.topDescStyles}>
                      <div className={styles.topImgStyles}>
                        <img
                          src={image}
                          alt=""
                          className={styles.imageStyles}
                        />
                      </div>
                      <p className={styles.topDescTextStyles}>
                        {item.title || item?.full_name}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className={styles.noCompletedTextStyles}>
                No Completed Projects
              </p>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.customSideBarStyles}>
      {renderBackButtonSection()}
      {renderMainSection()}
    </div>
  );
};

export default CustomSideBar;
