import React, { useRef, useState, useEffect, useCallback } from 'react';
import ButtonTab from 'components/buttontab';
import {
  avatar1,
  avatar3,
  calenderBlackIcon,
  closeBackgroundImg,
  closeIconWitOutBg,
  closeSquareBgImg,
  crossimg,
  descpimg,
  doubleTickIcon,
  doubletickimg,
  dropDownThickArrow,
  dropdownarrow,
  dropdownblackimg,
  jamesprofile,
  leftArrow1,
  menuthreedottedimg,
  rightArrow1,
  slashimg,
  tickimg,
  timerimg,
  userprofileimg,
  xlimg,
} from 'resources/images/images';
import { useAppDataContext } from 'hooks/useappdatacontext';
import TextArea from 'components/textarea';
import Popup from 'components/popup';
import Button from 'components/button';
import { monthsData, user_Types } from 'constants/commondata/commondata';
import SearchInput from 'components/searchinput';
import EmployeeCard from 'components/cards/employeecard';
import SelectedEmployeeCard from 'components/cards/selectedmployeecard';
import moment from 'moment';
import useDateRange, {
  getCurrentMonthDates,
  getCurrentWeekDates,
} from 'hooks/usecalenderhook';
import { useLocation } from 'react-router-dom';
import CustomSideBar from 'components/customsidebar';
import Header from 'components/header';
import { useAuthContext } from 'hooks/useauthcontext';
import {
  adminMarkAsProjectCompleteApi,
  getIndividualProjectApi,
  updateProjectDetailsApi,
} from 'networking/api/adminregister';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import LoadingSpinner from 'components/loadingspinner';
import InputWithDropDown from 'components/inputwithdropdown';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import _ from 'lodash';
import Input from 'components/input';
import useUserViewsHook from 'hooks/userViewshook';
import DatesSection from 'components/datesSection';
import ReactPaginate from 'react-paginate';
import Calender from 'components/calender';
import { employeeMarkAsTaskCompleteApi } from 'networking/api/employee';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import styles from './styles.module.css';

const AdminIndividualProject = () => {
  // routing:
  const location = useLocation();
  const { projectId, projectTitle } = location?.state;

  //context data
  const {
    setSideBar,
    loader,
    setLoader,
    routeName,
    activeTabs,
    setActiveTabs,
  } = useAppDataContext();
  const { user, userType } = useAuthContext();
  const { dates, totalDates } = useDateRange();
  const { projectsData, allProjectsData, allEmployeeProjects } =
    useProjectsDataContext();
  const { employee } = useEmployeeDataContext();

  // hooks:
  const { isAdmin, isEmployee } = useUserViewsHook();

  // refs:
  const popupRef = useRef();

  //state
  const [activeTab, setActiveTab] = useState('Summary');
  const [showDescModal, setShowDescModal] = useState(false);
  const [showModalType, setShowModalType] = useState('');
  const [showSelectEmployeePopup, setShowSelectEmployeePopup] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState();
  const [individualProjectData, setIndividualProjectData] = useState();

  const [ongoing, setOngoing] = useState();
  const [completed, setCompleted] = useState();
  const [editDescription, setEditDescription] = useState('');
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [editCategoryTags, setEditCategoryTags] = useState([]);
  const [showChangesSavedBtn, setShowChangesSavedBtn] = useState(false);

  const [showProjectCompletedModal, setShowProjectCompletedModal] =
    useState(false);
  const [showProjectCompletePopup, setShowProjectCompletePopup] =
    useState(false);
  const [
    showEmployeeProjectCompletePopup,
    setShowEmployeeProjectCompletePopup,
  ] = useState(false);

  // employee states:
  const [showMonthPopUp, setShowMonthPopUP] = useState(false);
  const [filterTimeType, setFilterTimeType] = useState('This month');
  const [activeDay, setActiveDay] = useState(
    moment(new Date()).format('DD/MM/YYYY')
  );
  const [employeePopup, setEmployeePopUp] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const [showDatePopup, setShowDatePopup] = useState({
    fromDate: false,
    toDate: false,
  });
  const [selectDate, setSelectDate] = useState({
    fromDate: '',
    toDate: '',
  });
  const previousDatesData = useDateRange().dates;

  const [showTimeLineMoreInfo, setShowTimeLineMoreInfo] = useState([]);

  // links state
  const [links, setLinks] = useState({
    showAddNewProjectLink: false,
    newProjectAddedMessage: false,
    editLinkMessage: false,
    deleteLink: false,
    linkDeleteMessage: false,
  });

  const [addNewProjectLink, setAddNewProjectLink] = useState({
    figmaLink: '',
    url: '',
  });

  const [selectedLink, setSelectedLink] = useState(null);
  const [deleteLink, setDeleteLink] = useState(null);
  const [submittedTimeSheets, setSubmittedProjects] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const timeSheetsPerPage = 8;

  useEffect(() => {
    const filteredTimeSheetsData = individualProjectData?.timesheets?.filter(
      (item) => {
        return moment(item?.created_at).format('DD/MM/YYYY') === activeDay;
      }
    );

    const filteredProjects = filteredTimeSheetsData?.map((item) => ({
      ...item,
      projectTitle: individualProjectData?.title,
      submittedProjects: item.submittedProjects?.filter(
        (project) => project.created_at === activeDay
      ),
    }));

    setSubmittedProjects(filteredProjects);
  }, [activeDay, individualProjectData?.timesheets, filterTimeType]);

  /*
   display the description-category tags- select employee details-
   while edit the project details.
  */

  useEffect(() => {
    setEditCategoryTags(individualProjectData?.category_tags.sort());
    setEditDescription(individualProjectData?.description);
    setSelectEmployee(individualProjectData?.assigned_to);
  }, [individualProjectData]);

  //remove the savechanges btn, after editing the projet
  useEffect(() => {
    setTimeout(() => {
      setShowChangesSavedBtn(false);
    }, 3000);
  }, [showChangesSavedBtn]);

  //remove the markproject as complete btn, onClick the proceed btn
  useEffect(() => {
    if (links.newProjectAddedMessage) {
      setTimeout(() => {
        setLinks((prevLinks) => ({
          ...prevLinks,
          newProjectAddedMessage: false,
        }));
      }, 3000);
    }
  }, [links.newProjectAddedMessage]);

  useEffect(() => {
    if (links.editLinkMessage) {
      setTimeout(() => {
        setLinks((prevLinks) => ({ ...prevLinks, editLinkMessage: false }));
      }, 3000);
    }
  }, [links.editLinkMessage]);

  useEffect(() => {
    if (links.linkDeleteMessage) {
      setTimeout(() => {
        setLinks((prevLinks) => ({ ...prevLinks, linkDeleteMessage: false }));
      }, 3000);
    }
  }, [links.linkDeleteMessage]);

  useEffect(() => {
    setTimeout(() => {
      setLinks({ ...links, linkDeleteMessage: false });
    }, 3000);
  }, [links.linkDeleteMessage]);

  // onload display the ongoing/completed projects
  useEffect(() => {
    if (projectsData) {
      setOngoing(projectsData.filter((item) => item.status === 'Ongoing'));
      setCompleted(projectsData.filter((item) => item.status === 'Completed'));
    }
  }, [projectsData]);

  useEffect(() => {
    setTimeout(() => {
      setShowEmployeeProjectCompletePopup(false);
    }, 3000);
  }, [showEmployeeProjectCompletePopup]);

  // onload display the employee data
  useEffect(() => {
    setFilteredEmployees(employee);
  }, [employee]);

  // based on location remove sidebar
  useEffect(() => {
    if (location.pathname === '/admin-individualProject') {
      setSideBar(false);
    }
  }, []);

  const modalOpenData = {
    description: 'description',
    category: 'category',
    assigned: 'assigned',
  };

  //mapping data
  const overViewData = [
    {
      title: 'Summary',
    },
    {
      title: 'Timeline',
    },
    {
      title: 'Timesheets',
    },
    {
      title: 'Links',
    },
  ];

  const projectStatusData = [
    {
      image: timerimg,
      text: 'Ongoing project',
      type: ['Admin', 'Employee'],
    },
    {
      image: tickimg,
      text: 'Mark as complete',
      type: ['Admin'],
    },
  ];

  // to get individual project data if it has projectid
  useEffect(() => {
    if (
      activeTabs?.ongoing?._id
        ? activeTabs?.ongoing?._id
        : activeTabs?.completed?._id
        ? activeTabs?.completed?._id
        : projectId
    ) {
      handleTogetProjectDetails();
    }
  }, [projectId, activeTabs?.ongoing?._id, activeTabs?.completed?._id]);

  // function to handle ongoing and mark as completed

  const tapOnOngoingAndMarkCompleteBtns = (item) => {
    const allAcknowledge = individualProjectData?.assigned_to.every(
      (employee) => employee.task_status === 'Acknowledge'
    );
    if (item === 'Ongoing project') {
      // alert('1');
    } else if (item === 'Mark as complete' && allAcknowledge) {
      setShowProjectCompletedModal(true);
    }
  };

  // function to get project details

  const handleTogetProjectDetails = async () => {
    try {
      setLoader(true);
      const response = await getIndividualProjectApi(
        activeTabs?.ongoing?._id
          ? activeTabs?.ongoing?._id
          : activeTabs?.completed?._id
          ? activeTabs?.completed?._id
          : projectId
      );
      if (response.data.type === 'success') {
        setIndividualProjectData(response.data.data);
        setLoader(false);
      } else {
        console.log('error', response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoader(false);
    }
  };

  // function to handle edit project category
  const handleEditCategory = (item) => {
    setEditCategoryTags((prevTags) => {
      if (prevTags.includes(item)) {
        return prevTags.filter((tag) => tag !== item);
      } else {
        return [...prevTags, item];
      }
    });
  };

  // function to handle date change
  const handleDateChange = (item, start_date, end_date) => {
    if (item && item._id) {
      const updatedSelectEmployee = selectEmployee.map((employee) =>
        employee.employee_id._id === item._id
          ? { ...employee, start_date, end_date }
          : employee
      );
      setSelectEmployee(updatedSelectEmployee);
    } else {
      console.error('Invalid item or missing _id property:', item);
    }
  };

  const handleSearchInputChange = (value) => {
    const filtered = employee.filter((item) => {
      // place employee
      return item.full_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredEmployees(filtered);
  };

  // function to handle select employee
  const handleSelectEmployeeClick = (item, start_date, end_date) => {
    const isEmployeeSelected = selectEmployee.some(
      (employee) => employee.employee_id._id === item._id
    );

    if (isEmployeeSelected) {
      // If employee is already selected, remove it
      const updatedSelectEmployee = selectEmployee.filter(
        (employee) => employee.employee_id._id !== item._id
      );
      setSelectEmployee(updatedSelectEmployee);
    } else {
      // If employee is not selected, add new entry with dates
      setSelectEmployee([
        ...selectEmployee,
        { employee_id: item, start_date, end_date },
      ]);
    }

    setShowSelectEmployeePopup(false);
  };

  // function to handle delete an employee from employee dropdown option
  const deleteSelectedEmployee = (index) => {
    setSelectEmployee(selectEmployee.filter((item, i) => i !== index));
  };

  // function to handle edit category

  const handleEditAssignProjectDetails = useCallback(() => {
    if (showModalType === 'description') {
      if (individualProjectData.description !== editDescription) {
        return styles.saveChangesBtnStyles;
      }
    } else if (showModalType === 'category') {
      const editAssignedTask = !_.isEqual(
        individualProjectData?.category_tags.sort(),
        editCategoryTags.sort()
      );
      if (editAssignedTask) {
        return styles.saveChangesBtnStyles;
      } else {
        return styles.disableBtnStyles;
      }
    } else {
      const assignedToChanged = !_.isEqual(
        individualProjectData?.assigned_to,
        selectEmployee
      );

      const allDatesSelected =
        selectEmployee &&
        Array.isArray(selectEmployee) &&
        selectEmployee.every(
          (employee) => employee.start_date && employee.end_date
        );

      if (assignedToChanged && allDatesSelected) {
        return styles.saveChangesBtnStyles;
      }
    }

    return styles.disableBtnStyles;
  }, [
    showModalType,
    editCategoryTags,
    editDescription,
    individualProjectData,
    selectEmployee,
  ]);

  // functon to send select employee data to edit project api
  const employeeData = selectEmployee?.map((item) => ({
    employee_id: item?.employee_id?._id,
    start_date: item?.start_date,
    end_date: item?.end_date,
    task_status:
      item.task_status === 'Completed' ? 'Acknowledge' : item.task_status,
    completed_at: item?.completed_at,
  }));

  // function to handle edit assign project category api

  const handleEditAssignProjectData = async (linkToDelete) => {
    try {
      setLoader(true);
      let updatedLinks = [...individualProjectData.links];

      if (linkToDelete) {
        updatedLinks = updatedLinks.filter(
          (link) => link._id !== linkToDelete._id
        );
      } else {
        updatedLinks = updatedLinks.map((link) => {
          if (selectedLink && link._id === selectedLink._id) {
            return selectedLink;
          }
          return link;
        });

        if (
          selectedLink &&
          !individualProjectData.links.find(
            (link) => link._id === selectedLink._id
          )
        ) {
          updatedLinks.push(selectedLink);
        } else if (!selectedLink) {
          const newLink = {
            label: addNewProjectLink.figmaLink,
            url: addNewProjectLink.url,
          };
          updatedLinks.push(newLink);
        }
      }

      const data = {
        _id: individualProjectData._id,
        title: individualProjectData?.title,
        description: editDescription,
        category_tags: editCategoryTags,
        start_date: individualProjectData.start_date,
        end_date: individualProjectData.end_date,
        assigned_to: employeeData,
        links: updatedLinks,
      };

      const response = await updateProjectDetailsApi(data);

      if (response.data.type === 'success') {
        handleTogetProjectDetails();
        setLoader(false);
        setShowDescModal(false);
        setShowChangesSavedBtn(true);

        if (linkToDelete) {
          setLinks((prevLink) => ({
            ...prevLink,
            deleteLink: false,
            showAddNewProjectLink: false,
            linkDeleteMessage: true,
          }));
        }

        if (selectedLink) {
          setLinks((prevLinks) => ({
            ...prevLinks,
            deleteLink: false,
            showAddNewProjectLink: false,
            editLinkMessage: true,
          }));
        }

        if (!selectedLink && !linkToDelete) {
          setLinks((prevLinks) => ({
            ...prevLinks,
            deleteLink: false,
            showAddNewProjectLink: false,
            newProjectAddedMessage: true,
          }));
        }

        setAddNewProjectLink({});
        setSelectedLink(null);
      } else {
        setLoader(false);
        setShowDescModal(true);
        setShowChangesSavedBtn(false);
        setLinks((prevLinks) => ({
          ...prevLinks,
          showAddNewProjectLink: false,
          newProjectAddedMessage: false,
          deleteLink: false,
          linkDeleteMessage: false,
        }));

        console.log('error', response.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const handleEditProjectData = async () => {
    try {
      setLoader(true);
      const data = {
        _id: individualProjectData._id,
        title: individualProjectData?.title,
        description: editDescription,
        category_tags: editCategoryTags,
        start_date: individualProjectData.start_date,
        end_date: individualProjectData.end_date,
        assigned_to: employeeData,
      };
      const response = await updateProjectDetailsApi(data);
      if (response.data.type === 'success') {
        handleTogetProjectDetails();
        setLoader(false);
        setShowDescModal(false);
        setShowChangesSavedBtn(true);
      } else {
        setLoader(false);
        setShowDescModal(true);
        setShowChangesSavedBtn(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // function to handle markas project complete

  const handleMarkAsProjectComplete = async () => {
    try {
      setLoader(true);
      const response = await adminMarkAsProjectCompleteApi(
        individualProjectData?._id
      );

      if (response.data.type === 'success') {
        setLoader(false);
        setShowProjectCompletedModal(false);
        setShowProjectCompletePopup(true);
      } else {
        setLoader(false);
        setShowProjectCompletedModal(false);
        setShowProjectCompletePopup(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  // function to select employee profile
  const handleEmployeeSelection = (item) => {
    setSelectedEmployee(item);
    setEmployeePopUp(false);
  };

  const handleEditLink = (item) => {
    setSelectedLink(item);
  };

  const timeFilterOptions = [
    'This month',
    'This week',
    monthsData[moment(new Date()).month() - 1],
    monthsData[moment(new Date()).month() - 2],
  ];

  // format date
  const formatDate = (dateString) => {
    const date = moment(dateString, 'DD/MM/YYYY');
    const formattedDate = date.format('dddd, DD');
    return formattedDate;
  };

  // format timeline date
  const formatTimeLineDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // function to handle select from and to date
  const handleToSelectFromAndToDate = (dateType, date) => {
    setSelectDate((prevState) => ({
      ...prevState,
      [dateType]: date,
    }));

    setShowDatePopup((prevState) => ({
      ...prevState,
      [dateType]: false,
    }));

    if (dateType === 'toDate' && selectDate?.fromDate) {
      setShowMonthPopUP(false);
    }
  };

  // dates displayed based on month-week-lastmonths-select(from & to dates)
  let filteredDates = [];
  if (selectDate.fromDate && selectDate.toDate) {
    filteredDates = previousDatesData.filter((date) =>
      moment(date).isBetween(
        selectDate.fromDate,
        selectDate.toDate,
        undefined,
        '[]'
      )
    );
  } else {
    // If either fromDate or toDate is not selected, use filterTimeType
    filteredDates =
      filterTimeType === 'This week'
        ? getCurrentWeekDates()
        : filterTimeType === 'This month'
        ? previousDatesData
        : filterTimeType === monthsData[moment().subtract(1, 'months').month()]
        ? getCurrentMonthDates({
            date: moment().subtract(1, 'months').toDate(),
            totalMonth: true,
          })
        : filterTimeType === monthsData[moment().subtract(2, 'months').month()]
        ? getCurrentMonthDates({
            date: moment().subtract(2, 'months').toDate(),
            totalMonth: true,
          })
        : [];
  }

  // function to toggle time line info
  const toggleTimeLineMoreInfo = (index) => {
    const updatedShowTimeLineMoreInfo = [...showTimeLineMoreInfo];
    updatedShowTimeLineMoreInfo[index] = !updatedShowTimeLineMoreInfo[index];
    setShowTimeLineMoreInfo(updatedShowTimeLineMoreInfo);
  };

  // function to handleEmployeeMarkAsTaskComplete
  const handleEmployeeMarkAsTaskComplete = async () => {
    try {
      setLoader(true);
      const response = await employeeMarkAsTaskCompleteApi(
        individualProjectData?._id
      );
      if (response.data.type === 'success') {
        console.log('response', response);
        setLoader(false);
        setShowDescModal(false);
        setShowEmployeeProjectCompletePopup(true);
        setIndividualProjectData(response?.data?.data);
      } else {
        setLoader(false);
        setShowDescModal(false);
        setShowEmployeeProjectCompletePopup(false);
      }
    } catch (error) {
      setShowDescModal(false);
      setShowEmployeeProjectCompletePopup(false);
      setLoader(false);
      console.log('error', error);
    }
  };

  const handleSearchEmployeeDataChange = (query) => {
    setSearchEmployee(query);
  };
  const filteredEmployeesData = employee?.filter((item) =>
    item.full_name.toLowerCase().includes(searchEmployee.toLowerCase())
  );

  // function to handle export data to excel
  const handleExportToExcel = (activeDay) => {
    if (!submittedTimeSheets) {
      return;
    }

    // Define headers including the Date column
    const headers = [
      'Date',
      'Project',
      'Task title',
      'Task description',
      'Time spent',
    ];

    // Map submitted timesheets data and include the activeDay date as the 'Date' column
    const formattedData = submittedTimeSheets.map((item) => ({
      Date: activeDay, // Include the activeDay as the Date column
      Project: item.projectTitle,
      'Task title': item.task,
      'Task description': item.description,
      'Time spent': item.time,
    }));

    // Convert the formatted data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData, {
      header: headers,
    });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TimeSheet');

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a Blob for the Excel file
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    // Save the Excel file
    saveAs(blob, `${activeDay}timesheet.xlsx`);
  };

  const renderTopSection = () => {
    return (
      <div className={styles.projectDetailsStyles}>
        <div className={styles.projectTitleAndDateStyles}>
          <h3 className={styles.projectTitleStyles}>
            {individualProjectData?.title}
          </h3>
          <div className={styles.dateStyles}>
            <p className={styles.startDateTextStyles}>Start date:</p>
            <p className={styles.dateNumStyles}>
              {`${moment(individualProjectData?.start_date).format(
                'DD/MM/YYYY'
              )}`}
            </p>
          </div>
        </div>
        <div className={styles.projectOverViewStyles}>
          {overViewData.map((item, index) => {
            if (
              item?.title === 'Links' &&
              individualProjectData?.status !== 'Completed'
            ) {
              return null;
            }
            return (
              <ButtonTab
                key={index}
                onClick={() => setActiveTab(item.title)}
                titleText={item.title}
                buttonTabStyles={
                  item.title === activeTab
                    ? styles.activeTabStyles
                    : styles.unActiveTabStyles
                }
                btnTextStyles={
                  item.title === activeTab
                    ? styles.activeTextStyles
                    : styles.unActiveTextStyles
                }
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderOnGoingSection = () => {
    if (individualProjectData?.status === 'Completed') {
      return (
        <ButtonTab
          titleText="Completed project"
          icon={calenderBlackIcon}
          buttonTabStyles={styles.completedProjectBtnStyles}
          btnTextStyles={styles.completeProjectBtnTextStyles}
        />
      );
    }
    return (
      <div className={styles.onGoingStyles}>
        {projectStatusData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.type.includes(userType) && (
                <>
                  {!(
                    individualProjectData?.status === 'Completed' &&
                    projectStatusData.length - 1 === index
                  ) && (
                    <ButtonTab
                      titleText={item.text}
                      buttonTabStyles={
                        projectStatusData.length - 1 === index
                          ? styles.completedTabStyles
                          : styles.onGoingTabStyles
                      }
                      btnTextStyles={
                        projectStatusData.length - 1 === index
                          ? styles.completedTextStyles
                          : styles.onGoingTextStyles
                      }
                      icon={item.image}
                      onClick={() => tapOnOngoingAndMarkCompleteBtns(item.text)}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
        {renderMarkAsProjectCompleteSection()}
      </div>
    );
  };

  const renderMarkAsProjectCompleteSection = () => {
    return (
      <Popup
        show={showProjectCompletedModal}
        showOverlay={true}
        customPopUpStyles={styles.markAsCompleteModalStyles}
        popupRef={popupRef}
      >
        <div className={styles.markAsProjectModalContentStyles}>
          <div className={styles.projectCompletedDescViewStyles}>
            <h5 className={styles.projectCompleteHeadingStyles}>
              Mark the project as complete?
            </h5>
            <p className={styles.projectCompleteDescStyles}>
              We recommend you to proceed only if the project is completed.
              Certain features like employee timeline will stop updating once
              you mark the project as complete.
            </p>
          </div>
          <div className={styles.bottmBtnBlockStyles}>
            <Button
              title="Cancel"
              btnStyle={styles.cancelBtnStyles}
              onClick={() => setShowProjectCompletedModal(false)}
            />
            <Button
              title="Proceed"
              btnStyle={styles.proceedBtnStyles}
              onClick={() => handleMarkAsProjectComplete()}
            />
          </div>
        </div>
      </Popup>
    );
  };

  const renderProjectMarkedAsCompletedSection = () => {
    return (
      <Popup
        show={showProjectCompletePopup}
        showOverlay={true}
        onClose={() => setShowProjectCompletePopup(false)}
        popupRef={popupRef}
        customPopUpStyles={styles.changesSavedPopupStyles}
        customOverLayStyles={styles.changesSavedOverLayStyles}
      >
        <Button
          title="Project is marked as complete"
          btnStyle={styles.changesSavedBtnStyles}
        />
      </Popup>
    );
  };

  const renderEmployeeMarkedAsProjectCompletedSection = () => {
    return (
      <Popup
        show={showEmployeeProjectCompletePopup}
        showOverlay={true}
        onClose={() => showEmployeeProjectCompletePopup(false)}
        popupRef={popupRef}
        customPopUpStyles={styles.changesSavedPopupStyles}
        customOverLayStyles={styles.changesSavedOverLayStyles}
      >
        <Button
          title="Notification sent to the admin"
          btnStyle={styles.changesSavedBtnStyles}
        />
      </Popup>
    );
  };

  const renderAssignTaskAcknowledgeSection = () => {
    return (
      <>
        {individualProjectData?.assigned_to?.map((item, index) => {
          const startDate = moment(item.start_date);
          const endDate = moment(item.end_date);
          const completed_at = moment(item?.completed_at);
          const diffInDays = endDate?.diff(startDate, 'days');
          const diffenceInCompletedDays = completed_at?.diff(startDate, 'days');

          console.log('data', item);

          return (
            <>
              {item?.task_status === 'Completed' && isAdmin && (
                <div className={styles.acknowLedgeContainerStyles} key={index}>
                  <div className={styles.topEmployeeTaskDetailsBlockStyles}>
                    <div className={styles.leftEmplyeeImgStyles}>
                      <img
                        src={
                          item?.employee_id?.image
                            ? item?.employee_id?.image
                            : avatar3
                        }
                        alt="employeeImg"
                        className={styles.imageStyles}
                      />
                    </div>
                    <div className={styles.rightEmployeeTaskDetailsStyles}>
                      <h5
                        className={styles.employeeTaskMarkedTextStyles}
                      >{`${item.employee_id.full_name} has marked the assigned task as complete`}</h5>
                      <p className={styles.assignedTaskDurationTextStyles}>
                        Assigned from{' '}
                        {moment(item.start_date).format('DD-MM-YYYY')}
                        to &nbsp; {moment(item.end_date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className={styles.middleTaskDurationDataBlockStyles}>
                    <p className={styles.assignTimeTextStyles}>
                      Assigned time:&nbsp;
                      <span className={styles.assignTimeSubTextStyle}>
                        {diffInDays > 1
                          ? `${diffInDays}days`
                          : `${diffInDays}day`}
                      </span>
                    </p>
                    <p className={styles.completedTimeTextStyles}>
                      Completed in:&nbsp;
                      <span className={styles.completedTimeSubTextStyles}>
                        {diffenceInCompletedDays} days
                      </span>
                    </p>
                  </div>
                  <Button
                    title="Acknowledge"
                    onClick={() => {
                      handleEditAssignProjectData();
                    }}
                    btnStyle={styles.acknowledgeBtnStyles}
                  />
                </div>
              )}
            </>
          );
        })}
      </>
    );
  };

  const renderProjectDeadLineSection = () => {
    // individual project data
    const start_date = moment(individualProjectData?.start_date);
    const end_date = moment(individualProjectData?.end_date);
    const diffInDays = end_date?.diff(start_date, 'days');

    return (
      <div className={styles.projectDeadLineStyles}>
        <div
          className={
            userType === user_Types.admin
              ? styles.projectDeadLineTitleStyles
              : styles.projectDeadLineTitleEmpStyles
          }
        >
          <p className={styles.titleTextStyles}>
            <span className={styles.weekTextStyles}>
              {userType === user_Types.admin
                ? 'Project deadline:'
                : 'Overall project deadline:'}
            </span>
            &nbsp;{diffInDays > 1 ? `${diffInDays} days` : `${diffInDays} day`}
          </p>
        </div>
        <div className={styles.descriptionSectionStyles}>
          <div className={styles.descriptionGapStyles}>
            <p className={styles.descpTitleStyles}>Description:</p>
            {userType === user_Types.admin && (
              <div
                className={styles.descpImageStyles}
                onClick={() => {
                  setShowDescModal(true);
                  setShowModalType(modalOpenData.description);
                }}
              >
                <img src={descpimg} alt="" className={styles.imageWidth} />
              </div>
            )}
          </div>
          <p className={styles.descpTextStyles}>
            {individualProjectData?.description}
          </p>
        </div>
        <div className={styles.categorySectionStyles}>
          <div className={styles.categoryGapStyles}>
            <p className={styles.categoryTitleStyles}>Category tags:</p>
            {userType === user_Types.admin && (
              <div
                className={styles.descpImageStyles}
                onClick={() => {
                  setShowDescModal(true);
                  setShowModalType(modalOpenData.category);
                }}
              >
                <img src={descpimg} alt="" className={styles.imageWidth} />
              </div>
            )}
          </div>
          <div className={styles.categorieDataStyles}>
            {individualProjectData?.category_tags?.sort().map((item, index) => {
              return (
                <ButtonTab
                  key={index}
                  titleText={item}
                  buttonTabStyles={styles.categoryTabStyles}
                  btnTextStyles={styles.categoryTextStyles}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.assignedSectionStyles}>
          <div className={styles.assignedGapStyles}>
            <p className={styles.assignedTitleStyles}>
              {userType === user_Types.admin
                ? 'Assigned to:'
                : 'Also assigned to:'}
            </p>
            {userType === user_Types.admin && (
              <div
                className={styles.descpImageStyles}
                onClick={() => {
                  setShowDescModal(true);
                  setShowModalType(modalOpenData.assigned);
                }}
              >
                <img src={descpimg} alt="" className={styles.imageWidth} />
              </div>
            )}
          </div>
          <div className={styles.assignedDataStyles}>
            {individualProjectData?.assigned_to?.map((item, index) => {
              const startDate = moment(item.start_date).format('DD/MM/YY');
              const endDate = moment(item.end_date).format('DD/MM/YY');
              return (
                <div
                  className={
                    item.task_status === 'Acknowledge'
                      ? styles.inActiveAssignDataStyles
                      : styles.assignDataStyles
                  }
                  key={index}
                >
                  <div className={styles.assignedLeftStyles}>
                    <div className={styles.employeeImgStyles}>
                      <img
                        src={
                          item?.employee_id?.image
                            ? item?.employee_id?.image
                            : avatar1
                        }
                        alt=""
                        className={styles.assignedToImgStyles}
                      />
                    </div>
                    <div className={styles.employeeUserAndRoleStyles}>
                      <p className={styles.employeeNameStyles}>
                        {item.employee_id.full_name}
                      </p>
                      <p className={styles.employeeRoleStyles}>
                        {item?.employee_id?.roles
                          ?.map(
                            (i) =>
                              `${i.experience > 24 ? 'Senior' : 'Junior'} ${
                                i.name
                              }`
                          )
                          .join(', ')}
                      </p>
                    </div>
                    {item.task_status === 'Acknowledge' && (
                      <div className={styles.taskCompletedTickImgStyles}>
                        <img
                          src={doubleTickIcon}
                          alt="taskCompletedTickImg"
                          className={styles.imageStyles}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.assignTimeStyles}>
                    <p className={styles.assignedTimeTextStyles}>
                      {startDate} - {endDate}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  //employee details section

  const renderEmployeeStatusSection = () => {
    const isUserTaskCompleted = individualProjectData?.assigned_to.some(
      (assignment) =>
        assignment.employee_id._id === user._id &&
        assignment.task_status === 'Completed'
    );

    const isUserTaskAcknowledged = individualProjectData?.assigned_to.some(
      (assignment) =>
        assignment.employee_id._id === user._id &&
        assignment.task_status === 'Acknowledge'
    );

    const matchedAssignment = individualProjectData?.assigned_to?.find(
      (assignment) => assignment?.employee_id?._id === user?._id
    );

    const startDate = moment(matchedAssignment?.start_date);
    const endDate = moment(matchedAssignment?.end_date);
    const completedAt = moment(matchedAssignment?.completed_at);

    const toDay = moment();
    const timeLeft = isUserTaskAcknowledged
      ? completedAt?.diff(startDate, 'days')
      : endDate?.diff(toDay, 'days');
    const weeksLeft = Math.floor(timeLeft / 7);
    const daysLeft = timeLeft % 7;

    return (
      <div className={styles.employeeStatusStyles}>
        <div className={styles.employeeTopStyles}>
          <div className={styles.employeeImgStyles}>
            <img
              src={user?.image ? user?.image : avatar1}
              alt=""
              className={styles.assignedToImgStyles}
            />
          </div>
          <div className={styles.employeeNameAndTimeStyles}>
            <p className={styles.employeeNameStyles}>{user?.full_name}</p>
            <p className={styles.employeeAssignedTimeStyles}>
              Assigned from {startDate.format('DD/MM/YYYY')} to&nbsp;
              {endDate.format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
        <div className={styles.employeeMiddleStyles}>
          {isUserTaskCompleted ? (
            ''
          ) : (
            <p className={styles.progressTextStyles}>
              <span className={styles.taskStatusTextStyles}>Task status:</span>
              &nbsp;
              {isUserTaskAcknowledged ? 'Completed' : 'In-progress'}
            </p>
          )}

          {isUserTaskCompleted ? (
            ''
          ) : (
            <p className={styles.daysTextStyles}>
              <span className={styles.timeLeftTextStyles}>
                {isUserTaskAcknowledged ? 'Completed:' : 'Time left:'}
              </span>
              &nbsp;
              {weeksLeft} weeks {daysLeft} days
            </p>
          )}
        </div>
        <div
          className={styles.employeeBottomStyles}
          onClick={() => setShowDescModal(true)}
        >
          {isUserTaskCompleted ? (
            <p className={styles.employeeTaskStatusTextStyles}>
              <span className={styles.taskStatusHeadingStyles}>
                &nbsp; Task status:
              </span>
              Waiting for acknowledgement
            </p>
          ) : isUserTaskAcknowledged ? (
            ''
          ) : (
            <React.Fragment>
              <div className={styles.doubleTickImgStyles}>
                <img src={doubletickimg} alt="" className={styles.imageWidth} />
              </div>
              <p className={styles.markTasksTextStyles}>
                Mark task as complete
              </p>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  //Modal section for both admin and employee

  const renderDescModalSection = () => {
    return (
      <Popup
        show={showDescModal}
        // onClose,
        // children,
        showOverlay={true}
        customPopUpStyles={styles.descpModalStyles}
        popupRef={popupRef}
        // handleClickOutside={}
      >
        {userType === user_Types.admin ? (
          <div className={styles.edidDescModalStyles}>
            <div className={styles.editDescTopStyles}>
              <p className={styles.editDescModalTextStyles}>
                {showModalType === 'description' && 'Edit description'}
                {showModalType === 'category' && 'Edit category'}
                {showModalType === 'assigned' && 'Assigned to'}
              </p>
              <div
                className={styles.editDescpCloseStyles}
                onClick={() => setShowDescModal(false)}
              >
                <img src={crossimg} alt="" className={styles.imageWidth} />
              </div>
            </div>
            <div className={styles.editDescpMiddleStyles}>
              {showModalType === 'description' && (
                <TextArea
                  label={'Describe the project*'}
                  placeholder={'description'}
                  name={'describe the project'}
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                  customTextAreaStyles={styles.describeProjectStyles}
                  customErrorTextStyle={styles.editDescpErrorStyles}
                  customTextAreaContainerStyles={
                    styles.descriptionTextAreaStyles
                  }
                />
              )}
              {showModalType === 'category' && (
                <div className={styles.categoryModalStyles}>
                  <p className={styles.modalCategoryTitleStyles}>
                    Select category tags*
                  </p>
                  <div className={styles.modalCategoryList}>
                    {individualProjectData.category_tags
                      .sort()
                      .map((item, index) => {
                        const isSelected = editCategoryTags.includes(item);
                        return (
                          <p
                            key={index}
                            className={
                              isSelected
                                ? styles.selectedCategoryStyles
                                : styles.categoriesListStyles
                            }
                            onClick={() => handleEditCategory(item)}
                          >
                            {`# ${item}`}
                          </p>
                        );
                      })}
                  </div>
                </div>
              )}

              {showModalType === 'assigned' && (
                <div className={styles.assignedModalStyles}>
                  <InputWithDropDown
                    labelText="Assign to*"
                    placeholder="Select employee"
                    rightIcon={dropdownarrow}
                    onClick={() =>
                      setShowSelectEmployeePopup(!showSelectEmployeePopup)
                    }
                    popupOpen={showSelectEmployeePopup}
                    onClose={() => setShowSelectEmployeePopup(false)}
                    customSubWrapperStyles={styles.selectEmployeeInputStyles}
                    customPopUpContainerStyles={
                      styles.selectNoOfWeekPopUpStyles
                    }
                  >
                    <SearchInput
                      placeholder="Search people..."
                      onCloseImgClick={() =>
                        setShowSelectEmployeePopup(!showSelectEmployeePopup)
                      }
                      onSearchInputChange={handleSearchInputChange}
                    />
                    <div className={styles.selectedEmployeeContainerStyles}>
                      {filteredEmployees.length > 0 ? (
                        filteredEmployees
                          .filter(
                            (item) =>
                              !selectEmployee.find(
                                (employee) =>
                                  employee.employee &&
                                  employee.employee._id === item._id
                              )
                          )
                          .map((item, index) => (
                            <EmployeeCard
                              key={index}
                              image={item.image ? item.image : avatar1}
                              name={item.full_name}
                              designation={
                                item.roles &&
                                item.roles.map((item) => item.name)
                              }
                              count={item?.projects_assigned
                                ?.toString()
                                ?.padStart(2, '0')}
                              onInputClick={() => {
                                handleSelectEmployeeClick(item);
                              }}
                              customCardContainerStyles=""
                            />
                          ))
                      ) : (
                        <p className={styles.noDataFoundTextStyles}>
                          No data found
                        </p>
                      )}
                    </div>
                  </InputWithDropDown>

                  <div className={styles.selectEmployeeContainerStyles}>
                    {selectEmployee &&
                      selectEmployee.map((item, index) => {
                        const employee = item.employee_id;
                        // console.log('111', selectEmployee);
                        return (
                          <SelectedEmployeeCard
                            key={index}
                            crossImg={closeBackgroundImg}
                            employeeImg={
                              employee.image ? employee.image : avatar1
                            }
                            name={employee.full_name}
                            designation={
                              employee.roles &&
                              employee.roles.map((role) => role.name)
                            }
                            onClickCloseIcon={() => {
                              deleteSelectedEmployee(index);
                            }}
                            start_date={
                              item.start_date &&
                              moment(item.start_date).format('YYYY-MM-DD')
                            }
                            end_date={
                              item.end_date &&
                              moment(item.end_date).format('YYYY-MM-DD')
                            }
                            onSelectDateChange={(start, end) => {
                              handleDateChange(employee, start, end);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.editDescpBottomStyles}>
              <Button
                title={'Save changes'}
                btnStyle={handleEditAssignProjectDetails()}
                onClick={() => handleEditProjectData('editProjectData')}
                disabled={
                  handleEditAssignProjectDetails() === styles.disableBtnStyles
                }
              />
            </div>
          </div>
        ) : (
          <div className={styles.employeeTaskModalStyles}>
            <div className={styles.taskCompletedTopStyles}>
              <h3 className={styles.taskCompletedTitleStyles}>
                Mark the task as complete
              </h3>
              <p className={styles.taskCompletedDescpStyles}>
                We recommend you to proceed only if the task is completed. Once
                you mark the task as complete, admin will review your task and
                acknowledge the status.
              </p>
            </div>
            <div className={styles.taskCompletedBottomStyles}>
              <div className={styles.taskButtonStyles}>
                <Button
                  onClick={() => setShowDescModal(false)}
                  title={'Cancel'}
                  color="light"
                />
                <Button
                  title={'Proceed'}
                  onClick={() => handleEmployeeMarkAsTaskComplete()}
                />
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  };

  const renderChangesSavedBtnPopupSection = () => {
    return (
      showChangesSavedBtn && (
        <Popup
          show={showChangesSavedBtn}
          showOverlay={true}
          onClose={() => setShowChangesSavedBtn(false)}
          popupRef={popupRef}
          customPopUpStyles={styles.changesSavedPopupStyles}
          customOverLayStyles={styles.changesSavedOverLayStyles}
        >
          <Button
            title="Changes saved"
            btnStyle={styles.changesSavedBtnStyles}
          />
        </Popup>
      )
    );
  };

  const renderTimeLineSection = () => {
    const currentDate = moment();
    const currentMonth = currentDate.month();
    const totalDates = [];

    // Generate dates for the current month
    const currentMonthStart = moment().startOf('month');
    for (
      let date = currentMonthStart;
      date.month() === currentMonth;
      date.add(1, 'day')
    ) {
      totalDates.push(date.clone());
    }

    // Generate dates for the next month
    const nextMonthStart = moment().add(1, 'month').startOf('month');
    for (
      let date = nextMonthStart;
      date.month() !== currentMonth;
      date.add(1, 'day')
    ) {
      totalDates.push(date.clone());
    }

    let isFirstDayOfMonth = false;
    if (currentDate.date() === 1) {
      isFirstDayOfMonth = true;
    }

    return (
      <div className={styles.timeLineStyles}>
        <div className={styles.timeLineTopStyles}>
          {isFirstDayOfMonth && (
            <p className={styles.MonthTextStyles}>
              {currentDate.format('MMM')}
            </p>
          )}
        </div>

        <div className={styles.totalMonthStyles}>
          {totalDates.map((item, index) => {
            if (item.date() === 1) {
              isFirstDayOfMonth = true;
            } else {
              isFirstDayOfMonth = false;
            }
            return (
              <div key={index} className={styles.heightStyles}>
                <p className={styles.MonthNameTextStyles}>
                  {isFirstDayOfMonth && item.format('MMM')}
                </p>
                <div className={styles.dateAndDayStyles}>
                  <p className={styles.dayTextStyles}>{item.format('dd')}</p>
                  <p className={styles.dateTextStyles}>{item.format('DD')}</p>
                </div>
              </div>
            );
          })}
          {renderEmployeeWorkStatusSection()}
        </div>
      </div>
    );
  };

  const renderEmployeeWorkStatusSection = () => {
    const specificColors = [
      '#A3FFA080',
      '#FFCDA080',
      '#FDA0FF80',
      '#A0D2FF80',
      '#FF8E9180',
    ];
    let colorIndex = 0;

    return (
      <div>
        {individualProjectData.assigned_to.map((assignment, index) => {
          const color = specificColors[colorIndex % specificColors.length];
          colorIndex++;

          return (
            <div
              key={index}
              className={styles.workStatusScrollStyles}
              style={{
                backgroundColor: color,
                position: 'absolute',
                top: `${index + 1 * 10 + (index + 1) * 82}px`,
                width: `${
                  (moment(assignment.end_date).diff(
                    assignment.start_date,
                    'days'
                  ) +
                    1) *
                  52.8
                }px`,
                left: `${
                  (moment(assignment.start_date).get('date') - 1) * 52.8
                }px`,
              }}
            >
              <div className={styles.workStatusLeftStyles}>
                <div className={styles.employeeworkStatusImgStyles}>
                  <img
                    src={assignment.employee_id.image || avatar1}
                    alt=""
                    className={styles.assignedToImgStyles}
                  />
                </div>
                <div className={styles.employeeUserAndRoleStyles}>
                  <div className={styles.employeeUserAndRoleSubBlockStyles}>
                    <p className={styles.employeeNameStyles}>
                      {assignment.employee_id.full_name}{' '}
                    </p>
                    <p className={styles.employeeRolesTextStyles}>
                      {assignment.employee_id.roles.map((role, roleIndex) => (
                        <span
                          key={roleIndex}
                          className={styles.roleNameTextStyles}
                        >
                          ({role.experience > 18 ? 'Senior' : 'Junior'}{' '}
                          {role.name})
                        </span>
                      ))}
                    </p>
                  </div>
                  <p className={styles.employeeWorkDateStyles}>
                    Assigned from {formatTimeLineDate(assignment.start_date)} to
                    {formatTimeLineDate(assignment.end_date)}
                  </p>
                </div>
              </div>

              <div
                className={styles.rightStyles}
                onClick={() => toggleTimeLineMoreInfo(index)}
              >
                <img
                  src={menuthreedottedimg}
                  alt="menuthreedottedimg"
                  className={styles.imageWidth}
                />
              </div>
              {showTimeLineMoreInfo[index] && (
                <div className={styles.timeLineMoreInfoStyles} key={index}>
                  <p className={styles.timeLineDescTextStyles}>
                    Status:&nbsp;
                    <span className={styles.timeLineSubDescTextStyles}>
                      {assignment?.task_status}
                    </span>
                  </p>
                  <p className={styles.timeLineDescTextStyles}>
                    Assigned:&nbsp;
                    <span className={styles.timeLineSubDescTextStyles}>
                      {moment(assignment?.start_date).format('DD/MM/YYYY')} to
                      &nbsp;
                      {moment(assignment?.end_date).format('DD/MM/YYYY')}
                    </span>
                  </p>
                  <p className={styles.timeLineDescTextStyles}>
                    Completed:&nbsp;
                    <span className={styles.timeLineSubDescTextStyles}>
                      {moment(assignment?.start_date).format('DD/MM/YYYY') ===
                      moment(assignment?.end_date).format('DD/MM/YYYY')
                        ? 'Completed'
                        : 'Still Working'}
                    </span>
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTimeSheetSection = () => {
    return (
      <div className={styles.timeSheetsStyles}>
        {renderTimeSheetsTopSection()}
        {renderTimeSheetsMiddleSection()}
      </div>
    );
  };

  const renderTimeSheetsTopSection = () => {
    return (
      <div className={styles.timeSheetsTopStyles}>
        <div className={styles.timeSheetTabStyles}>
          <InputWithDropDown
            popupOpen={showMonthPopUp}
            onClick={() => setShowMonthPopUP(true)}
            rightIcon={dropDownThickArrow}
            customSubWrapperStyles={styles.monthFilterBtnStyles}
            customPopUpContainerStyles={styles.monthFilterPopUpStyles}
            customPlaceHolderStyles={styles.timeFilterTextStyles}
            showOverlay={true}
            selectedItem={filterTimeType}
          >
            <>
              <div className={styles.popupTopStyles}>
                <p className={styles.popupTopTextStyles}>Filter by time:</p>
                <div
                  onClick={() => setShowMonthPopUP(false)}
                  className={styles.crossIconStyles}
                >
                  <img src={crossimg} alt="cross icon" />
                </div>
              </div>
              <div className={styles.filterTypesContainerStyles}>
                {timeFilterOptions &&
                  timeFilterOptions?.map((item, index) => (
                    <p
                      className={styles.filterTypeTextStyles}
                      style={
                        item === filterTimeType ? { background: '#ECEEF4' } : {}
                      }
                      key={index}
                      onClick={() => {
                        setFilterTimeType(item);
                        setShowMonthPopUP(false);
                        setSelectDate({});
                      }}
                    >
                      {item}
                    </p>
                  ))}
              </div>
              <div className={styles.dateRangeButtonWrapperStyles}>
                <InputWithDropDown
                  placeholder={'From date'}
                  popupOpen={showDatePopup.fromDate}
                  onClick={() =>
                    setShowDatePopup({ ...showDatePopup, fromDate: true })
                  }
                  onClose={() =>
                    setShowDatePopup({ ...showDatePopup, fromDate: false })
                  }
                  rightIcon={dropdownarrow}
                  selectedItem={
                    selectDate.fromDate
                      ? moment(selectDate?.fromDate)
                          .format('DD/MM/YYYY')
                          .toString()
                      : 'From date'
                  }
                  customPopUpContainerStyles={styles.fromDatePopUpStyles}
                  customPlaceHolderStyles={
                    selectDate.fromDate && styles.fromDateTextStyles
                  }
                >
                  <Calender
                    onSelectDate={(date) =>
                      handleToSelectFromAndToDate('fromDate', date)
                    }
                    enablePreviousMonth={true}
                  />
                </InputWithDropDown>
                <InputWithDropDown
                  placeholder={'To date'}
                  popupOpen={showDatePopup.toDate}
                  onClick={() =>
                    setShowDatePopup({ ...showDatePopup, toDate: true })
                  }
                  onClose={() =>
                    setShowDatePopup({ ...showDatePopup, toDate: false })
                  }
                  rightIcon={dropdownarrow}
                  customPopUpContainerStyles={styles.toDatePopUpStyles}
                  selectedItem={
                    selectDate.toDate
                      ? moment(selectDate.toDate)
                          .format('DD/MM/YYYY')
                          .toString()
                      : 'To date'
                  }
                  customPlaceHolderStyles={
                    selectDate.toDate && styles.toDateTextStyles
                  }
                >
                  <Calender
                    onSelectDate={(date) =>
                      handleToSelectFromAndToDate('toDate', date)
                    }
                    enablePreviousMonth={true}
                  />
                </InputWithDropDown>
              </div>
            </>
          </InputWithDropDown>

          {isAdmin && (
            <InputWithDropDown
              popupOpen={employeePopup}
              onClick={() => setEmployeePopUp(true)}
              rightIcon={dropDownThickArrow}
              customSubWrapperStyles={styles.employeeFilterBtnStyles}
              customPopUpContainerStyles={styles.employeeFilterPopUpStyles}
              customPlaceHolderStyles={styles.employeeFilterTextStyles}
              showOverlay={true}
              selectedItem={
                selectedEmployee ? selectedEmployee?.full_name : 'All employees'
              }
            >
              <div className={styles.topFilterEmployeeHeaderStyles}>
                <p className={styles.filterPeopleTextStyles}>
                  Filter by people:
                </p>
                <div
                  className={styles.closeImgBlockStyles}
                  onClick={() => setEmployeePopUp(false)}
                >
                  <img
                    src={closeIconWitOutBg}
                    alt="closeIconWitOutBg"
                    className={styles.imageStyles}
                  />
                </div>
              </div>

              <div className={styles.middleSearchAndEmployeeDataStyles}>
                <SearchInput
                  placeholder="Search people..."
                  onSearchInputChange={handleSearchEmployeeDataChange}
                  searchContent={searchEmployee}
                  customContainerStyles={styles.searchInputStyles}
                  customCloseImgStyles={styles.closeImgStyles}
                />
                <div className={styles.employeeDataViewStyles}>
                  {filteredEmployeesData?.map((item, index) => {
                    return (
                      <EmployeeCard
                        key={index}
                        image={avatar1}
                        name={item?.full_name}
                        designation={item?.roles?.map(
                          (role) =>
                            ` ${role.experience > 18 ? 'Senior' : 'Junior'} ${
                              role.name
                            }`
                        )}
                        onInputClick={() => handleEmployeeSelection(item)}
                        customCardContainerStyles={
                          selectedEmployee._id === item._id
                            ? styles.activeEmployeeDataStyles
                            : styles.employeeDataStyles
                        }
                      />
                    );
                  })}
                </div>
              </div>

              <div className={styles.allEmployeeBtnBlockStyles}>
                <Button
                  title="All employees"
                  btnStyle={styles.addEmployeeBtnStyles}
                />
              </div>
            </InputWithDropDown>
          )}
        </div>
        <div className={styles.calenderDateAndDayStyles}>
          {/* {[...dates].reverse().map((item, index) => {
            return (
              <div key={index} className={styles.calenderRowStyles}>
                <p className={styles.dayStyles}>{moment(item).format('dd')}</p>
                <p className={styles.dateStyles}>{moment(item).format('DD')}</p>
              </div>
            );
          })} */}

          <DatesSection
            datesData={filteredDates}
            activeDay={activeDay}
            setActiveDay={setActiveDay}
          />
        </div>
      </div>
    );
  };

  const renderTimeSheetsMiddleSection = () => {
    // based on employee selection display time sheets
    const filteredSubmittedTimeSheets = selectedEmployee
      ? submittedTimeSheets.filter(
          (item) => item.employee._id === selectedEmployee._id
        )
      : submittedTimeSheets;

    // handle pagination
    const pageCount = Math.ceil(
      filteredSubmittedTimeSheets?.length / timeSheetsPerPage
    );

    const formattedPageCount = pageCount.toString().padStart(2, '0');

    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const startIndex = pageNumber * timeSheetsPerPage;
    const endIndex = Math.min(
      startIndex + timeSheetsPerPage,
      filteredSubmittedTimeSheets?.length
    );
    const displayedTimeSheets = filteredSubmittedTimeSheets?.slice(
      startIndex,
      endIndex
    );

    return (
      <div className={styles.submittedTimeSheetAndTableStyles}>
        <div className={styles.submittedAndExportStyles}>
          <p className={styles.submittedTextStyles}>Submitted timesheets</p>
          <div
            className={styles.exportTextAndImgStyles}
            onClick={() => handleExportToExcel(activeDay)}
          >
            <div className={styles.excelImgStyles}>
              <img src={xlimg} alt="xlimg" className={styles.imageStyles} />
            </div>
            <p className={styles.exportTextStyles}>Export to excel</p>
          </div>
        </div>
        <div className={styles.timesheetsTableBlockStyles}>
          <div className={styles.timeSheetTableSubBlockStyles}>
            <div className={styles.dateBlockStyles}>
              <p className={styles.dateButtonStyles}>{formatDate(activeDay)}</p>
              <div className={styles.dateDividerStyles}></div>
            </div>
            <div className={styles.timesheetsTableHeadingBlockStyles}>
              <p className={styles.projectTitleStyles}>Project</p>
              <p className={styles.taskTextStyles}>Task title</p>
              <p className={styles.projectTitleStyles}>Task description</p>
              <p className={styles.timeSpentTextStyles}>Time spent</p>
            </div>
          </div>

          {filteredSubmittedTimeSheets?.length > 0 ? (
            <div className={styles.timesheetsTableRowStyle}>
              {displayedTimeSheets?.map((item, index) => (
                <div className={styles.timesheetsTableSubRowStyle} key={index}>
                  <div className={styles.projectDetailsBlockStyles}>
                    <div className={styles.employeeAvatarStyles}>
                      <img
                        src={avatar1}
                        alt="avatar of an employee"
                        className={styles.imageWidthStyles}
                      />
                    </div>
                    <p className={styles.projectTitleTextStyles}>
                      {item?.projectTitle}
                    </p>
                  </div>
                  <p className={styles.projectTaskTextStyles}>{item.task}</p>
                  <p className={styles.projectDescTextStyles}>
                    {item.description}
                  </p>
                  <p className={styles.timeTextStyles}>{item.time}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.noTimeSheetDataFoundTextStyles}>
              No time sheets data right now
            </p>
          )}

          <ReactPaginate
            previousLabel={
              filteredSubmittedTimeSheets?.length > 0 && (
                <img
                  src={leftArrow1}
                  alt="Previous"
                  className={styles.arrowImgStyles}
                />
              )
            }
            nextLabel={
              filteredSubmittedTimeSheets?.length > 0 && (
                <img
                  src={rightArrow1}
                  alt="Next"
                  className={styles.arrowImgStyles}
                />
              )
            }
            pageCount={formattedPageCount}
            onPageChange={changePage}
            marginPagesDisplayed={2}
            containerClassName={styles.paginationContainer}
            previousLinkClassName={styles.paginationPrevious}
            nextLinkClassName={styles.paginationNext}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            pageRangeDisplayed={5}
            breakLabel={'...'}
            breakClassName={styles.paginationBreak}
            pageLinkClassName={styles.paginationPageLink}
            previousClassName={styles.paginationArrow}
            nextClassName={styles.paginationArrow}
          />
        </div>
      </div>
    );
  };

  const renderLinksSection = () => {
    return (
      <div className={styles.linksContainerStyles}>
        {renderAddNewProjectLinkCardSection()}
        <ButtonTab
          titleText="🔗 Add new link"
          btnTextStyles={styles.addNeLinkTextStyles}
          buttonTabStyles={styles.addNewLinkTabStyles}
          onClick={() => setLinks({ ...links, showAddNewProjectLink: true })}
        />
        {individualProjectData?.links <= 0 && (
          <p className={styles.noLinksFoundTextStyles}>
            There are no added links for this project at the moment.
          </p>
        )}
        {renderAddNewProjectLinkSection()}
      </div>
    );
  };

  const renderAddNewProjectLinkSection = () => {
    return (
      <Popup
        show={links.showAddNewProjectLink}
        showOverlay={true}
        customPopUpStyles={styles.addNewProjectModalStyles}
        popupRef={popupRef}
      >
        <div className={styles.addNewProjectModalContentStyles}>
          <h5 className={styles.addNewProjectHeadingStyles}>
            {selectedLink ? 'Edit added link' : 'Add new project link'}
          </h5>
          <div className={styles.middleInputBlockStyles}>
            <Input
              type="text"
              name="enterLabel"
              value={selectedLink?.label || addNewProjectLink.figmaLink}
              onChange={(e) => {
                if (selectedLink) {
                  setSelectedLink({
                    ...selectedLink,
                    label: e.target.value,
                  });
                } else {
                  setAddNewProjectLink({
                    ...addNewProjectLink,
                    figmaLink: e.target.value,
                  });
                }
              }}
              placeholder="Ex: Figma link"
              //  hasError=""
              lableText="Enter label*"
              lableTextStyle={styles.enterLabelTextStyles}
              customInputStyle={styles.enterFigmaLinkInputStyles}
            />
            <TextArea
              label="Add URL*"
              placeholder="Copy and paste an URL here"
              name="copyAndPasteUrl"
              value={selectedLink?.url || addNewProjectLink.url}
              onChange={(e) => {
                if (selectedLink) {
                  setSelectedLink({
                    ...selectedLink,
                    url: e.target.value,
                  });
                } else {
                  setAddNewProjectLink({
                    ...addNewProjectLink,
                    url: e.target.value,
                  });
                }
              }}
              customTextAreaStyles={styles.addUrlTextAreaStyles}
            />
          </div>
          <div className={styles.cancelAndAddLinkBtnBlockStyles}>
            <Button
              title="Cancel"
              btnStyle={styles.cancelBtnStyles}
              onClick={() => {
                setLinks({ ...links, showAddNewProjectLink: false });
                setSelectedLink(null);
                setAddNewProjectLink({});
              }}
            />
            <Button
              title={selectedLink ? 'Save changes' : 'Add link'}
              btnStyle={
                (selectedLink?.label && selectedLink?.url) ||
                (addNewProjectLink.figmaLink && addNewProjectLink.url)
                  ? styles.activeAddLinkBtnStyles
                  : styles.addLinkBtnStyles
              }
              onClick={() => handleEditAssignProjectData()}
              disabled={
                !(
                  (selectedLink?.label && selectedLink?.url) ||
                  (addNewProjectLink.figmaLink && addNewProjectLink.url)
                )
              }
            />
          </div>
        </div>
      </Popup>
    );
  };

  const renderNewLinkAddedInfoSection = () => {
    return (
      <Popup
        show={
          (links.newProjectAddedMessage && links.newProjectAddedMessage) ||
          (links.editLinkMessage && links.editLinkMessage) ||
          (links.linkDeleteMessage && links.linkDeleteMessage)
        }
        showOverlay={true}
        onClose={() =>
          setLinks((prevLinks) => ({
            ...prevLinks,
            newProjectAddedMessage: false,
            editLinkMessage: false,
            linkDeleteMessage: false,
          }))
        }
        popupRef={popupRef}
        customPopUpStyles={styles.changesSavedPopupStyles}
        customOverLayStyles={styles.changesSavedOverLayStyles}
      >
        <Button
          title={
            links.newProjectAddedMessage
              ? 'New link added'
              : links.editLinkMessage
              ? 'Link edited'
              : 'Link deleted'
          }
          btnStyle={styles.changesSavedBtnStyles}
        />
      </Popup>
    );
  };

  const renderAddNewProjectLinkCardSection = () => {
    return (
      <React.Fragment>
        {individualProjectData?.links?.map((item, index) => {
          return (
            <div
              className={
                index === 0
                  ? styles.projectLinkCardContainerStyles
                  : styles.removeProjectLinkCardRadiusStyles
              }
            >
              <div className={styles.topLinkContentStyles}>
                <h5 className={styles.linkHeadingStyles}>{item?.label}:</h5>
                <p className={styles.linkDescStyles}>{item?.url}</p>
              </div>
              <div className={styles.bottomEditLinkBtnsStyles}>
                <ButtonTab
                  titleText="📝 Edit link"
                  onClick={() =>
                    setLinks(
                      { ...links, showAddNewProjectLink: true },
                      handleEditLink(item)
                    )
                  }
                  buttonTabStyles={styles.editLinkTabStyles}
                  btnTextStyles={styles.editLinkTextStyles}
                />
                <div
                  className={styles.closeImgBlockStyles}
                  onClick={() => {
                    setLinks({ ...links, deleteLink: true });
                    setDeleteLink(item);
                  }}
                >
                  <img
                    src={closeSquareBgImg}
                    alt="closeBackgroundImg"
                    className={styles.imageStyles}
                  />
                </div>
                {renderDeleteLinkModalSection(item)}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const renderDeleteLinkModalSection = (item) => {
    return (
      <Popup
        show={links.deleteLink}
        showOverlay={true}
        customPopUpStyles={styles.deleteLinkModalStyles}
        popupRef={popupRef}
      >
        <div className={styles.deleteLinkModalContentStyles}>
          <h5 className={styles.delteLinkHeadingStyles}>
            Are you sure you want to delete this link permanently?
          </h5>

          <div className={styles.deleteLinkContentViewStyles}>
            <h5 className={styles.linkHeadingStyles}>{deleteLink?.label}</h5>
            <p className={styles.linkDescStyles}>{deleteLink?.url}</p>
          </div>

          <div className={styles.bottomLinkDeleteBtnsStyles}>
            <Button
              title="Cancel"
              btnStyle={styles.cancelBtnStyles}
              onClick={() => setLinks({ ...links, deleteLink: false })}
            />
            <Button
              title="Yes, delete"
              btnStyle={styles.yesOrDeleteBtnStyles}
              onClick={() => {
                handleEditAssignProjectData(deleteLink);
              }}
            />
          </div>
        </div>
      </Popup>
    );
  };

  const projectOnGoingData = isAdmin
    ? allProjectsData.onGoing
    : isEmployee && allEmployeeProjects.onGoing;

  const projectCompletedData = isAdmin
    ? allProjectsData.completed
    : isEmployee && allEmployeeProjects.completed;

  return (
    <div className={styles.customSideBarStyles}>
      <CustomSideBar
        topTitle={'Ongoing'}
        bottomTitle={'Completed'}
        bottomSection={true}
        image={slashimg}
        activeTab={activeTabs.ongoing}
        setActiveTab={(value) =>
          setActiveTabs({ ...activeTabs, ongoing: value })
        }
        isActiveTabTwo={activeTabs.completed}
        setActiveTabTwo={(value) =>
          setActiveTabs({
            ...activeTabs,
            ongoing: '',
            completed: value,
          })
        }
        dataOne={projectOnGoingData}
        dataTwo={projectCompletedData}
      />

      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.headerAndEmployeeStyles}>
          <Header />
          <div className={styles.individalProjectSection}>
            {renderTopSection()}
            {activeTab === 'Summary' && (
              <>
                {renderOnGoingSection()}
                {userType === user_Types.employee &&
                  renderEmployeeStatusSection()}
                {individualProjectData?.assigned_to.some(
                  (item) => item.task_status === 'Completed'
                ) && renderAssignTaskAcknowledgeSection()}

                {renderProjectDeadLineSection()}
              </>
            )}
            {activeTab === 'Timeline' && <>{renderTimeLineSection()}</>}
            {activeTab === 'Timesheets' && <>{renderTimeSheetSection()}</>}
            {activeTab === 'Links' && <>{renderLinksSection()}</>}
            {renderDescModalSection()}
          </div>
        </div>
      )}

      {/* show renderChangesSavedBtnPopupSection */}
      {renderChangesSavedBtnPopupSection()}

      {/* show renderProjectMarkedAsCompletedSection */}
      {renderProjectMarkedAsCompletedSection()}

      {/* show renderEmployeeMarkedAsProjectCompletedSection */}
      {renderEmployeeMarkedAsProjectCompletedSection()}

      {/* renderNewLinkAddedInfoSection */}
      {renderNewLinkAddedInfoSection()}
    </div>
  );
};

export default AdminIndividualProject;
