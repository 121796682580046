import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import styles from './styles.module.css';

const Input = (props) => {
  const {
    type,
    name,
    value,
    onChange = () => {},
    placeholder,
    hasError,
    lableText,
    lableTextStyle,
    customInpuContainerStyles,
    customInputStyle,
    customInputViewStyle,
    customRightIconViewStyle,
    customRightTextViewStyle,
    onRightIconClick,
    onClickCustomRightText,
    customRightText,
    rightIcon,
    rightBtnTitle,
    onClickRightBtn = () => {},
    leftIcon,
    customLeftIconViewStyle,
    customErrorTextStyle,
    disabled,
    autoComplete,
    ...rest
  } = props;

  //password visibility

  const handleCustomRightTextClick = () => {
    if (onClickCustomRightText) {
      onClickCustomRightText();
    }
  };

  // popup visibility
  const handleCustomRightIconClick = () => {
    if (onRightIconClick) {
      onRightIconClick();
    }
  };

  return (
    <div
      className={[styles.inputContainerStyle, customInpuContainerStyles].join(
        ' '
      )}
    >
      {lableText && (
        <p className={[styles.lableTextStyle, lableTextStyle].join(' ')}>
          {lableText}
        </p>
      )}
      <div
        className={[styles.inputSubContainerStyle, customInputViewStyle].join(
          ' '
        )}
      >
        {leftIcon && (
          <div
            className={[styles.leftIconViewStyle, customLeftIconViewStyle].join(
              ' '
            )}
          >
            <img src={leftIcon} className={styles.leftIconStyle} />
          </div>
        )}
        <input
          type={type || 'text'}
          name={name}
          disabled={disabled}
          className={[styles.inputStyle, customInputStyle].join(' ')}
          value={value}
          onChange={onChange}
          placeholder={placeholder || ''}
          autoComplete={autoComplete}
          {...rest}
        />
        {rightIcon ? (
          <div
            className={[
              styles.rightIconViewStyle,
              customRightIconViewStyle,
            ].join(' ')}
            onClick={handleCustomRightIconClick}
          >
            <img src={rightIcon} className={styles.rightIconStyle} />
          </div>
        ) : (
          rightBtnTitle && (
            <Button
              title={rightBtnTitle}
              btnStyle={styles.rightBtnStyles}
              onClick={onClickRightBtn}
            />
          )
        )}
        {customRightText && (
          <div
            className={[styles.rightTextStyle, customRightTextViewStyle].join(
              ' '
            )}
            onClick={handleCustomRightTextClick}
          >
            {customRightText}
          </div>
        )}
      </div>
      {hasError && (
        <p className={[styles.errorTextStyle, customErrorTextStyle].join(' ')}>
          {hasError}
        </p>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  lableText: PropTypes.string,
  lableTextStyle: PropTypes.string,
  customErrorTextStyle: PropTypes.string,
  handleCustomRightTextClick: PropTypes.func,
  onRightIconClick: PropTypes.func,
};
export default Input;
