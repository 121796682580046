import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AdminRegister from 'pages/admin/adminregister';
import EmployeeLogin from 'pages/employee/employeelogin';
import AdminLogin from 'pages/admin/adminlogin';
import DashBoardLayout from 'layouts/dashboard';
import AdminDashBoard from 'pages/admin/admindashboard';
import AdminTimeSheet from 'pages/admin/admintimesheet';
import AdminProjects from 'pages/admin/adminprojects';
import AdminLeaves from 'pages/admin/adminleave';
// import AdminEmployees from 'pages/admin/employees';
import EmployeeTmeSheets from 'pages/employee/employeetimesheet';
import AdminEmployees from 'pages/admin/adminemployee';
import AdminIndividualProject from 'pages/admin/adminindividualproject';
import IndividualEmployee from 'pages/admin/adminindividualemployee';
import EmployeeLeave from 'pages/employee/employeeleave';
import AuthGuard from 'guards/authguard';
import { user_Types } from 'constants/commondata/commondata';
import GuestGuard from 'guards/guestguard';
import PastRecords from 'pages/admin/pastrecords';
import ForgotPassword from 'pages/admin/forgotpassword';
import CreatePassword from 'pages/admin/createpassword';
const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: '',
      children: [
        {
          path: '/login',
          element: (
            <GuestGuard userType={[user_Types.employee]}>
              <EmployeeLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'admin/login',
          element: (
            <GuestGuard userType={[user_Types.admin]}>
              <AdminLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'admin/signup',
          element: <AdminRegister />,
        },
        { path: '/forgetpassword', element: <ForgotPassword /> },
        { path: '/createpassword/:link', element: <CreatePassword /> },
        { path: '/resetpassword/:link', element: <CreatePassword /> },
      ],
    },
    {
      path: '',
      children: [
        {
          element: (
            <AuthGuard userType={[user_Types.admin, user_Types.employee]}>
              <DashBoardLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: <Navigate to={'/dashboard'} replace />,
              index: true,
            },
            { path: '/dashboard', element: <AdminDashBoard /> },
            { path: '/admin-timesheet', element: <AdminTimeSheet /> },
            { path: '/admin-projects', element: <AdminProjects /> },
            { path: '/admin-leaves', element: <AdminLeaves /> },
            { path: '/admin-employees', element: <AdminEmployees /> },
            {
              path: '/admin-individualProject',
              element: <AdminIndividualProject />,
            },
            {
              path: '/admin-individualemployee',
              element: <IndividualEmployee />,
            },
            {
              path: '/employee-leaves',
              element: <EmployeeLeave />,
            },
            {
              path: '/employee-timesheet',
              element: <EmployeeTmeSheets />,
            },
            {
              path: '/pastrecords',
              element: <PastRecords />,
            },
          ],
        },
      ],
    },
  ]);
  return routes;
};
// return useRoutes([
//   {
//     element: <Navigate to={'admin'} replace />,
//     index: true,
//   },
//   {
//     path: 'admin',
//     children: [
//       {
//         element: (
//           <AuthGuard userType="company">
//             <DashBoardLayout />
//           </AuthGuard>
//         ),
//         children: [
//           {
//             element: <Navigate to={'/admin/dashboard'} replace />,
//             index: true,
//           },
//           { path: '/admin/dashboard', element: <AdminDashBoard /> },
//           { path: '/admin/admin-timesheet', element: <AdminTimeSheet /> },
//           { path: '/admin/admin-projects', element: <AdminProjects /> },
//           { path: '/admin/admin-leaves', element: <AdminLeaves /> },
//           { path: '/admin/admin-employees', element: <AdminEmployees /> },
//           {
//             path: '/admin/admin-individualProject',
//             element: <AdminIndividualProject />,
//           },
//           {
//             path: '/admin/admin-individualemployee',
//             element: <IndividualEmployee />,
//           },
//           {
//             path: '/admin/employee/employee-leaves',
//             element: <EmployeeLeave />,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/',
//     element: <AdminRegister />,
//   },
//   {
//     path: '/adminlogin',
//     element: <AdminLogin />,
//   },
//   {
//     path: '/employee',
//     element: <EmployeeLogin />,
//   },
//   {
//     path: 'employee',
//     children: [
//       {
//         element: (
//           <AuthGuard userType="company">
//             <DashBoardLayout />
//           </AuthGuard>
//         ),
//         children: [
//           // {
//           //   element: <Navigate to={'/employee/dashboard'} replace />,
//           //   index: true,
//           // },
//           // { path: '/employee/dashboard', element: <AdminDashBoard /> },
//           {
//             path: '/employee/employee-timesheet',
//             element: <EmployeeTmeSheets />,
//           },
//           // { path: '/employee/employee-projects', element: <AdminProjects /> },
//           // { path: '/employee/employee-leaves', element: <AdminLeaves /> },
//           // { path: '/employee/employee-employees', element: <AdminEmployees /> },
//         ],
//       },
//     ],
//   },
// ]);
// };

export default AppRoutes;
