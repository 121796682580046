import React, { useState } from 'react';
import { eclocklogo, workingimg } from 'resources/images/images';
import Button from 'components/button';
import Input from 'components/input';
import { useFormik } from 'formik';
import {
  PassWordValidationSchema,
  emailValidationSchema,
} from 'validations/validations';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'hooks/useauthcontext';
import styles from './styles.module.css';

const AdminLogin = () => {
  const { adminLoginData, authError, setAuthError } = useAuthContext();

  // routing
  const navigate = useNavigate();

  // states
  const [loading, setLoading] = useState(false);
  const [passWordVisible, setPassWordVisible] = useState(false);
  const togglePassWordVisibility = () => {
    setPassWordVisible(!passWordVisible);
  };

  // handle reset form
  const handleResetForm = () => {
    formik.resetForm();
  };

  // handle login
  const handleLogin = (data) => {
    setLoading(true);
    adminLoginData(data);
    setLoading(false);
    // handleResetForm();
  };

  // formik validations
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: emailValidationSchema.concat(PassWordValidationSchema),
    onSubmit: handleLogin,
  });

  const renderHeaderTopSection = () => {
    return (
      <div className={styles.eclockImgBlockStyles}>
        <img src={eclocklogo} alt="eclocklogo" className={styles.imageWidth} />
      </div>
    );
  };
  const renderLoginSection = () => {
    return (
      <div className={styles.loginSectionContainer}>
        {renderAdminLeftImgSection()}
        {renderLoginFormFieldsSection()}
      </div>
    );
  };

  const renderAdminLeftImgSection = () => {
    return (
      <div className={styles.adminLeftImgBlockStyles}>
        <img src={workingimg} alt="workingimg" className={styles.imageWidth} />
      </div>
    );
  };

  const renderLoginFormFieldsSection = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className={styles.adminLoginFormContainerStyles}
      >
        {renderAdminLoginHeaderSection()}
        {renderPassWordAndForGotPassWordSection()}
        <div className={styles.proceedBottomSectionStyles}>
          {renderProceedButtonSection()}
          {renderNoAccountAndRegisterSection()}
        </div>
      </form>
    );
  };

  const renderAdminLoginHeaderSection = () => {
    return (
      <div className={styles.adminHeaderTopSectionStyles}>
        <h5 className={styles.adminLoginHeadTextStyles}>Admin login</h5>
      </div>
    );
  };

  const renderPassWordAndForGotPassWordSection = () => {
    return (
      <div className={styles.emainAndPasswordSectionStyles}>
        <Input
          name="email"
          lableText={'Email*'}
          lableTextStyle={styles.emailLabelStyles}
          placeholder={'Enter your email ID'}
          value={formik.values.email}
          onChange={formik.handleChange}
          onFocus={() => {
            formik.setFieldError('email', '');
            setAuthError('');
          }}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''
          }
          customInputStyle={styles.emailInputStyles}
        />
        <div className={styles.passWordAndForGotPassWordContainerStyles}>
          <Input
            name="password"
            type={passWordVisible ? 'text' : 'password'}
            lableText={'Password*'}
            lableTextStyle={styles.passWordLabelStyles}
            placeholder={'Enter Password'}
            value={formik.values.password}
            onClickCustomRightText={togglePassWordVisibility}
            onChange={formik.handleChange}
            onFocus={() => {
              formik.setFieldError('password', '');
              setAuthError('');
            }}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
            customRightText={passWordVisible ? 'Hide' : 'Show'}
            customInputStyle={styles.passWordInputStyles}
          />
          <p
            className={styles.forGotPassWordTextStyles}
            onClick={() => navigate('/forgetpassword')}
          >
            Forgot password
          </p>
          {authError && <p className={styles.errorMsgStyles}>{authError}</p>}
        </div>
      </div>
    );
  };

  const renderProceedButtonSection = () => {
    return (
      <Button
        type="submit"
        title={loading ? 'Loading' : 'Proceed'}
        btnStyle={
          formik.values.email !== '' && formik.values.password !== ''
            ? styles.activeButtonProceddBtnSyles
            : styles.proceedButtonStyles
        }
      />
    );
  };

  const renderNoAccountAndRegisterSection = () => {
    return (
      <p className={styles.noAccountAndRegisterTextStyles}>
        No account?{' '}
        <span
          className={styles.registerTextStyles}
          onClick={() => navigate('/admin/signup')}
        >
          Register
        </span>
      </p>
    );
  };

  return (
    <div className={styles.adminLoginContainerStyles}>
      {renderHeaderTopSection()}
      {renderLoginSection()}
    </div>
  );
};

export default AdminLogin;
