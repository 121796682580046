import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { LeftCurveArrow, RightCurveArrow } from 'resources/images/svgs';
import './index.css';

const CalenderInput = () => {
  const [value, onChange] = useState(new Date());
  const dateFormat = (input_D, format_D) => {
    // input date parsed
    const date = new Date(input_D);

    //extracting parts of date string
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //to replace month
    format_D = format_D.replace('MM', month.toString().padStart(2, '0'));

    //to replace year
    if (format_D.indexOf('yyyy') > -1) {
      format_D = format_D.replace('yyyy', year.toString());
    } else if (format_D.indexOf('yy') > -1) {
      format_D = format_D.replace('yy', year.toString().substr(2, 2));
    }

    //to replace day
    format_D = format_D.replace('dd', day.toString().padStart(2, '0'));
    return format_D;
  };
  return (
    <Calendar
      onChange={onChange}
      value={value}
      nextLabel={<RightCurveArrow />}
      next2Label={null}
      prev2Label={null}
      prevLabel={<LeftCurveArrow />}
    />
  );
};

export default CalenderInput;
