import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import SearchInput from 'components/searchinput';
import { getCountriesDataApi } from 'networking/api/countries';
import styles from './styles.module.css';
import Input from 'components/input';
const PhoneNumberInput = (props) => {
  // props
  const {
    customNumberInputContainerStyles,
    customNumberInputStyles,
    customLabelTextStyles,
    labeText,
    phoneNumber,
    setPhoneNumber,
    onFocus,
    onBlur,
    hasError,
  } = props;
  // states
  const [countries, setCountries] = useState([]);
  const [filterCountriesData, setFilterCountriesData] = useState([]);
  const [showCountryData, setShowCountryData] = useState(false);
  // fetch the countries data
  useEffect(() => {
    const fetchConutriesData = async () => {
      try {
        const response = await getCountriesDataApi();
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchConutriesData();
  }, []);
  // function to toggle the country's data dropdown
  const handleDialCodeClick = () => {
    setShowCountryData(!showCountryData);
    setFilterCountriesData(countries);
  };
  // function to handleCountry selection
  const handleCountryClick = (callingCode) => {
    setPhoneNumber({
      ...phoneNumber,
      country_code: callingCode,
    });
    setShowCountryData(false);
  };
  // function to handle phone number input
  const handleInputChange = (event) => {
    const numericValue = event.target.value.replace(/\D/g, '');
    setPhoneNumber({
      ...phoneNumber,
      value: numericValue,
    });
  };
  // search the project data
  const handleSearchInputChange = (searchContent) => {
    const lowerCaseSearchContent = searchContent.toLowerCase();
    const filteredCountries = countries.filter(
      (country) =>
        country.name.toLowerCase().includes(lowerCaseSearchContent) ||
        country.callingCodes[0].includes(lowerCaseSearchContent)
    );
    setFilterCountriesData(filteredCountries);
  };
  useEffect(() => {
    setFilterCountriesData(countries);
  }, [countries]);
  return (
    <div className={styles.numberInputContainerStyles}>
      <p className={classNames(styles.labelTextStyles, customLabelTextStyles)}>
        {labeText}
      </p>
      <div
        className={classNames(
          styles.inputContainerStyles,
          customNumberInputContainerStyles
        )}
      >
        <div
          className={styles.dialCodeBlockStyles}
          onClick={handleDialCodeClick}
        >
          {'+' + phoneNumber?.country_code}
        </div>
        <div className={styles.rightBorderStyles}></div>
        <Input
          type="tel"
          placeholder="Enter your number"
          customInputStyle={classNames(
            styles.inputStyles,
            customNumberInputStyles
          )}
          value={phoneNumber?.value}
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {hasError && <p className={styles.errorMessageStyles}>{hasError}</p>}
      {showCountryData && (
        <div className={styles.countryContainerStyles}>
          <SearchInput
            placeholder="Search country code here..."
            onCloseImgClick={() => handleDialCodeClick()}
            onSearchInputChange={handleSearchInputChange}
            onCloseImg={true}
          />
          <div className={styles.countryDetailsBlockStyles}>
            {filterCountriesData.length > 0 ? (
              filterCountriesData.map((country) => (
                <div
                  key={country.alpha2Code}
                  className={`${styles.countryItem} ${
                    country.callingCodes[0] === phoneNumber.country_code
                      ? styles.selectedCountry
                      : ''
                  }`}
                  onClick={() => handleCountryClick(country.callingCodes[0])}
                >
                  <div className={styles.countryFlagImgStyles}>
                    <img
                      src={country.flags.svg}
                      alt=""
                      className={styles.imageStyles}
                    />
                  </div>
                  <p className={styles.countryCallingCodeTextStyles}>
                    {country.callingCodes[0]}
                  </p>
                  <p className={styles.countryNameTextStyles}>{country.name}</p>
                  <p className={styles.countryAlphaCodeTextStyles}>
                    {`[ ${country.alpha2Code} ]`}
                  </p>
                </div>
              ))
            ) : (
              <p className={styles.noOptionsTextStyles}>No options available</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default PhoneNumberInput;
