import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { rightarrow } from 'resources/images/images';

const OnGoingProjectCard = (props) => {
  const { title, technologies, icon, onClick = () => {} } = props;
  return (
    <div className={styles.onGoingProjectContainerStyles} onClick={onClick}>
      <div className={styles.onGoingProjectTopHeadingAndImgStyles}>
        <h5 className={styles.onGoingProhectHeadingStyles}>{title}</h5>
        <div className={styles.rightArrowImgStyles}>
          <img
            src={rightarrow}
            alt="rightarrow"
            className={styles.imageWidth}
          />
        </div>
      </div>
      <div className={styles.technologyUsedListStyles}>
        {technologies &&
          technologies.map((technology, index) => {
            return (
              <p key={index} className={styles.technologyStyles}>
                {icon}
                {technology}
              </p>
            );
          })}
      </div>
    </div>
  );
};

OnGoingProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  technologies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OnGoingProjectCard;
