// admin ednpoints:
export const register = '/user/verify-registration';
export const registerOtp = '/user/send-registration-otp';
export const adminLogin = '/user/admin/login';
export const adminProfileEdit = '/user/admin/update';
export const getUserDetails = '/user/get/{id}';
export const deleteEmployee = '/user/delete/{id}';
export const adminStastics = '/user/get/statistics/{id}';
export const createPassword = '/user/createpassword';
export const forgetPassword = '/user/forgetpassword';

//get all employees endpoint
export const gellAllEmployees = '/user/all';

//employee  endpoints
export const employeeLogin = '/user/employee/login';
export const employeeCreation = '/user/employee/create';
export const getIndividualEmployeeData = '/user/get/{id}';
export const employeeProfileEdit = '/user/employee/update';

// techdata
export const rolesAndTechnology = '/techdata/get/{id}';
export const addRolesAndTechnology = '/techdata/add';

// admin project endpoints
export const createProject = 'projects/create';
export const getAdminProjectList = 'projects/all/admin/{id}';
export const getIndividualProjectDetails = 'projects/get/{id}';
export const updateProjectDetails = 'projects/update';
export const adminMarkAsProjectComplete = 'projects/complete/{id}';
export const employeeMarkAsTaskComplete = 'projects/employee/task/{id}';

// get coutries data endpoint
export const countriesData = 'https://restcountries.com/v2/all';

// time line endpoints
export const individualEmployeeTimeSheet = '/timeline/get/{id}';

//employee leave request  endpoint
export const requestLeave = '/leaves/request';
export const getPendingLeaveRequest = '/leaves/employee/pending/{id}';
export const getAllLeaveRequests = '/leaves/all';

//admin leave request  endpoint
export const getAllPendingAdminLeaveRequest = '/leaves/admin/pending/{id}';
export const getAllEmployeesLeaveTable = '/leaves/users';

// leaves endpoints
export const todayLeaves = '/leaves/admin/today/{id}';
export const leaveApproval = '/leaves/action';
export const getEmployeeProjectList = '/projects/all/employee/{id}';

//employee time-sheets endpoints
export const employeeTimeSheet = '/timesheet/add';
export const getEmployeeTimeSheet = '/timesheet/get/employee/{id}';
export const getTodayEmployeeTimeSheet = 'timesheet/get/employee/today/{id}';
export const updateTimeSheet = '/timesheet/update';
export const getAllTimeSheets = '/timesheet/get/admin/{id}';

//Check-In & Out endpoint
export const checkInOut = '/timeline/checkinout';
export const statusCheckInOut = '/timeline/get/today/{id}';

// upload image endpoints

export const uploadImage = '/upload/image';
