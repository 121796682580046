import React from 'react';
import { crossimg, searchimg } from 'resources/images/images';
import Input from 'components/input';
import classNames from 'classnames';
import styles from './styles.module.css';

const SearchInput = (props) => {
  const {
    placeholder,
    onCloseImgClick,
    onCloseImg,
    onSearchInputChange,
    searchContent,
    customContainerStyles,
    customInputStyles,
    customCloseImgStyles,
  } = props;

  const handleSearchContent = (e) => {
    onSearchInputChange(e.target.value);
  };
  return (
    <div
      className={classNames(
        styles.serachProjectContainerStyles,
        customContainerStyles
      )}
    >
      <div className={styles.serachImgBlock}>
        <img src={searchimg} alt="searchimg" className={styles.imageWidth} />
      </div>
      <Input
        placeholder={placeholder}
        customInputStyle={classNames(
          styles.serachInputStyles,
          customInputStyles
        )}
        onChange={handleSearchContent}
        value={searchContent}
      />
      {onCloseImg && (
        <div
          className={classNames(styles.crossImgStyles, customCloseImgStyles)}
          onClick={onCloseImgClick}
        >
          <img src={crossimg} alt="crossimg" className={styles.imageWidth} />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
