import { useContext } from 'react';
import { TimeSheetContext } from 'provider/timesheetsprovider';

export const useTimeSheetContext = () => {
  const context = useContext(TimeSheetContext);

  if (!context) {
    throw new Error(
      'useTimeSheetContext context must be use inside TimeSheetProvider'
    );
  }
  return context;
};
