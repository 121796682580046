import React, { useEffect, useState } from 'react';
import { editImage, tickimg } from 'resources/images/images';
import TextArea from 'components/textarea';
import { useTimeSheetContext } from 'hooks/usetimesheetcontext';
import styles from './styles.module.css';

const TimeSheet = (props) => {
  //props:-
  const { serialNo, onClick, editValue, setEditValue, isEdited, item } = props;

  //context:-
  const { showToster, setShowToster } = useTimeSheetContext();

  useEffect(() => {
    setTimeout(() => {
      setShowToster(false);
    }, 4000);
  }, [showToster]);

  // const handleBlur = () => {
  //   setIsEditing(false);
  //   setInputHeight('auto');
  // };

  // const handleFocus = () => {
  //   setInputHeight('auto');
  // };
  const renderToasterSection = () => {
    return (
      <>
        {showToster && (
          <div className={styles.toasterContainerStyles}>
            <p className={styles.toasterTextStyles}>Please Check-in first</p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.timeSheetContainerStyles}>
      <p className={styles.leftSerialNoTextStyles}>{serialNo}</p>

      <div className={styles.rightBlockStyles}>
        <p className={styles.selectProjectNameTextStyles}>
          {item.project.title}
        </p>
        {editValue?._id === item._id ? (
          <TextArea
            type="text"
            value={editValue.task}
            onChange={(e) =>
              setEditValue({ ...editValue, task: e.target.value })
            }
            // onBlur={handleBlur}
            // onFocus={handleFocus}
            // style={{ height: inputHeight }}
            customTextAreaStyles={styles.editTaskTitleStyles}
            customTextAreaContainerStyles={styles.taskContainerStyles}
          />
        ) : (
          <p className={styles.taskTitleTextStyles}>{item.task}</p>
        )}
        {editValue?._id === item._id ? (
          <TextArea
            type="text"
            value={editValue.description}
            onChange={(e) =>
              setEditValue({ ...editValue, description: e.target.value })
            }
            // onBlur={handleBlur}
            // onFocus={handleFocus}
            // style={{ height: inputHeight }}
            customTextAreaStyles={styles.editDescribeStyles}
            customTextAreaContainerStyles={styles.descpContainerStyles}
          />
        ) : (
          <p className={styles.describeTaskTextStyles}>{item.description}</p>
        )}
        {editValue?._id === item._id ? (
          <TextArea
            type="text"
            value={editValue.time}
            onChange={(e) =>
              setEditValue({ ...editValue, time: e.target.value })
            }
            // onBlur={handleBlur}
            // onFocus={handleFocus}
            // style={{ height: inputHeight }}
            customTextAreaStyles={styles.editTimeStyles}
            customTextAreaContainerStyles={styles.TimeContainerStyles}
          />
        ) : (
          <p className={styles.timeTextStyles}>{item.time}</p>
        )}

        <div
          className={
            editValue?._id === item._id
              ? styles.tickImgBgStyles
              : styles.editImgBgStyles
          }
          onClick={
            // editValue
            //   ? handleTimeSheetClick
            //   : (editableTaskTitle || editDescription || editTime) &&
            //     handleTimeSheetUpdate

            item.task !== editValue?.task ||
            item.description !== editValue?.description ||
            item.time !== editValue?.time
              ? onClick
              : () => setEditValue(null)
          }
        >
          <div
            className={
              editValue?._id === item._id
                ? styles.tickImgBlockStyles
                : styles.editImgBlockStyles
            }
          >
            <img
              src={editValue?._id === item._id ? tickimg : editImage}
              alt="edit"
              className={styles.imageStyles}
            />
          </div>
        </div>
      </div>
      {renderToasterSection()}
    </div>
  );
};

export default TimeSheet;
