import React, { useEffect, useState } from 'react';
import TodayLeaveCard from 'components/cards/todayleavstatuscard';
import {
  avatar1,
  avatar2,
  closeIconWitOutBg,
  crossimg,
  dropDownThickArrow,
  dropdownarrow,
  pastrecordimg,
} from 'resources/images/images';
import PendingRequestCard from 'components/cards/pendingrequestcard';
import { handleLeaveColor, handleLeaveText } from 'hooks/usecolorhook';
import { useAuthContext } from 'hooks/useauthcontext';
import { useLocation, useNavigate } from 'react-router-dom';
import { monthsData, user_Types } from 'constants/commondata/commondata';
import { useAppDataContext } from 'hooks/useappdatacontext';
import {
  getAllPendingAdminLeaveRequestApi,
  getTodayLeavesApi,
  leaveApprovalApi,
} from 'networking/api/adminregister';
import moment from 'moment';
import LoadingSpinner from 'components/loadingspinner';
import styles from './styles.module.css';
import useDateRange from 'hooks/usecalenderhook';
import ButtonTab from 'components/buttontab';
import InputWithDropDown from 'components/inputwithdropdown';
import EmployeeCard from 'components/cards/employeecard';
import Button from 'components/button';
import SearchInput from 'components/searchinput';
import Calender from 'components/calender';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import { getAllEmployeesLeaveTableApi } from 'networking/api/employee';

const AdminLeaves = () => {
  //context
  const { user, userType } = useAuthContext();
  const { setRoutName, setSideBar, sideBar, loader, setLoader } =
    useAppDataContext();
  const { dates, totalDates } = useDateRange();
  const { employee } = useEmployeeDataContext();

  //naviagate
  const navigate = useNavigate();
  const location = useLocation();

  //state
  const [approveRequest, setApproveRequest] = useState();
  const [todayLeavesData, setTodayLeavesData] = useState(null);
  const [employeePopup, setEmployeePopUp] = useState(false);
  const [showMonthPopUp, setShowMonthPopUP] = useState(false);
  const [filterTimeType, setFilterTimeType] = useState('This month');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [allEmployeeLeaves, setAllEmployeeLeaves] = useState(null);
  const [searchEmployee, setSearchEmployee] = useState('');
  const [selectDate, setSelectDate] = useState({
    fromDate: '',
    toDate: '',
  });
  const [showDatePopup, setShowDatePopup] = useState({
    fromDate: false,
    toDate: false,
  });

  //to hide the left sidebar when we click on past records
  useEffect(() => {
    if (location.pathname === '/admin-leaves') {
      setSideBar(true);
    }
  }, [sideBar]);

  //fetch all employees table data on load the page
  useEffect(() => {
    getAllEmployeesLeaveTable();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (user?._id) {
        await getAllAdminLeaves(user?._id);
        await getTodayLeaves(user?._id);
      }
      setLoader(false);
    };
    fetchData();
  }, [user]);

  //get leave request api for admin approval
  const getAllAdminLeaves = async () => {
    try {
      let response = await getAllPendingAdminLeaveRequestApi(user?._id);
      if (response.data.type === 'success') {
        setLoader(false);
        setApproveRequest(response.data.data);
      } else {
        setLoader(false);
        setApproveRequest(null);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // function to handle approve leave request
  const handleApproveLeaveRequest = async (item, status) => {
    try {
      setLoader(true);
      const data = {
        id: item._id,
        status: status,
      };
      const response = await leaveApprovalApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        getAllAdminLeaves();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // function to get today leaves of employee
  const getTodayLeaves = async (id) => {
    try {
      setLoader(true);
      const response = await getTodayLeavesApi(id);
      if (response.data.type === 'success') {
        setLoader(false);
        setTodayLeavesData(response.data.data);
      } else {
        setLoader(false);
        setTodayLeavesData(null);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const getAllEmployeesLeaveTable = async () => {
    try {
      setLoader(true);
      const response = await getAllEmployeesLeaveTableApi();
      if (response.data.type === 'success') {
        setLoader(false);
        setAllEmployeeLeaves(response.data.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // changing the path of the header data
  const tapOnPastLeavesSection = (data) => {
    if (userType === user_Types.admin) {
      let dataa = ['Leaves', data];
      setRoutName(dataa);
      navigate('/pastrecords');
    }
    setSideBar(false);
  };

  const timeFilterOptions = [
    'This month',
    'This week',
    monthsData[moment().subtract(1, 'months').month()],
    monthsData[moment().subtract(2, 'months').month()],
  ];

  // function to handle select from and to date
  const handleToSelectFromAndToDate = (dateType, date) => {
    setSelectDate((prevState) => ({
      ...prevState,
      [dateType]: date,
    }));

    setShowDatePopup((prevState) => ({
      ...prevState,
      [dateType]: false,
    }));

    if (dateType === 'toDate' && selectDate?.fromDate) {
      setShowMonthPopUP(false);
    }
  };

  // handle search employee profile
  const handleSearchInputChange = (query) => {
    setSearchEmployee(query);
  };

  // based on search filter employees
  const filteredEmployees = employee?.filter((item) =>
    item.full_name.toLowerCase().includes(searchEmployee.toLowerCase())
  );

  // function to select employee profile
  const handleEmployeeSelection = (item) => {
    setSelectedEmployee(item);
    setEmployeePopUp(false);
  };

  let datesToDisplay = [];

  // Logic to determine which dates to display based on the selected filter
  if (filterTimeType === 'This month') {
    datesToDisplay = totalDates;
  } else if (filterTimeType === 'This week') {
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');

    // Create an array to store the dates for the current week
    const weekDates = [];
    let currentDate = moment(startOfWeek);

    // Iterate through each day of the week and add it to the array
    while (currentDate <= endOfWeek) {
      weekDates.push(currentDate.toDate());
      currentDate = currentDate.clone().add(1, 'day');
    }

    // Set the weekDates array as datesToDisplay
    datesToDisplay = weekDates;
  } else if (
    filterTimeType === monthsData[moment().subtract(1, 'months').month()]
  ) {
    // Logic to get dates for the last month
    const startOfMonth = moment().subtract(1, 'months').startOf('month');
    const endOfMonth = moment().subtract(1, 'months').endOf('month');

    // Create an array to store the dates for the last month
    const lastMonthDates = [];
    let currentDate = moment(startOfMonth);

    // Iterate through each day of the last month and add it to the array
    while (currentDate <= endOfMonth) {
      lastMonthDates.push(currentDate.toDate());
      currentDate = currentDate.clone().add(1, 'day');
    }

    // Set the lastMonthDates array as datesToDisplay
    datesToDisplay = lastMonthDates;
  } else if (
    filterTimeType === monthsData[moment().subtract(2, 'months').month()]
  ) {
    // Logic to get dates for the month before the last month
    const startOfLast2Months = moment().subtract(2, 'months').startOf('month');
    const endOfLast2Months = moment().subtract(2, 'months').endOf('month');

    // Create an array to store the dates for the month before the last month
    const last2MonthsDates = [];
    let currentDate = moment(startOfLast2Months);

    // Iterate through each day of the month before the last month and add it to the array
    while (currentDate <= endOfLast2Months) {
      last2MonthsDates.push(currentDate.toDate());
      currentDate = currentDate.clone().add(1, 'day');
    }

    // Set the last2MonthsDates array as datesToDisplay
    datesToDisplay = last2MonthsDates;
  }

  const renderTopSection = () => {
    return (
      <React.Fragment>
        {todayLeavesData?.length > 0 && (
          <div className={styles.topStyles}>
            <p className={styles.topTitleStyles}>
              Today, {moment(new Date()).format('D MMMM')}
            </p>
            <div className={styles.toadyLeaveCards}>
              <React.Fragment>
                {todayLeavesData?.map((item, index) => {
                  return (
                    <TodayLeaveCard
                      userProfile={avatar1}
                      userName={item?.employee?.full_name}
                      userRole={item?.employee?.roles?.map((roleItem) =>
                        roleItem.experience > 18
                          ? `Senior ${roleItem.name}`
                          : `Junior ${roleItem.name}`
                      )}
                      leaveType={handleLeaveText(item.leave_type)}
                    />
                  );
                })}
              </React.Fragment>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderMiddleSection = () => {
    return (
      <div className={styles.middleStyles}>
        <div className={styles.pendingLeaveHeaderStyles}>
          <p className={styles.pendingTitleStyles}>
            Pending requests{' '}
            {`(${approveRequest?.length})`.toString().padStart(2, '0')}
          </p>
          <div className={styles.pastRecordSectionStyles}>
            <div className={styles.pastRecordImgStyles}>
              <img src={pastrecordimg} alt="" className={styles.imageStyles} />
            </div>
            <p
              onClick={() => tapOnPastLeavesSection('Past records')}
              className={styles.pastRecoredTextStyles}
            >
              Past records
            </p>
          </div>
        </div>
        {approveRequest?.length > 0 ? (
          approveRequest.map((item, index) => {
            return (
              <PendingRequestCard
                key={index}
                backgroundColor={handleLeaveColor(item.leave_type)}
                borderTop={handleLeaveColor(item.leave_type)}
                border={handleLeaveColor(item.leave_type)}
                name={item?.employee?.full_name}
                role={user?.roles && user?.roles.map((role) => role.name)}
                startDate={moment(item?.start_date).format('DD/MM/YYYY')}
                endDate={moment(item?.end_date).format('DD/MM/YYYY')}
                totalDays={`${
                  Math.ceil(
                    (moment(item.end_date) - moment(item.start_date)) /
                      (1000 * 60 * 60 * 24)
                  ) + 1
                } ${
                  Math.ceil(
                    (moment(item.end_date) - moment(item.start_date)) /
                      (1000 * 60 * 60 * 24)
                  ) +
                    1 ===
                  1
                    ? 'day'
                    : 'days'
                }`}
                leaveType={handleLeaveText(item.leave_type)}
                desc={item?.reason}
                showActionBtns={true}
                onClickApprove={() =>
                  handleApproveLeaveRequest(item, 'Approved')
                }
                onClickDeny={() => handleApproveLeaveRequest(item, 'Rejected')}
              />
            );
          })
        ) : (
          <p className={styles.noDataFoundTextStyles}>
            No Pending Leaves Request Found
          </p>
        )}
      </div>
    );
  };

  const renderCalenderSection = () => {
    return (
      <div className={styles.calenderViewStyles}>
        <div className={styles.titleStyles}>
          <p className={styles.calenderViewTitleStyles}>Calendar view</p>
          <div className={styles.lastDayStyles}>
            <InputWithDropDown
              popupOpen={showMonthPopUp}
              onClick={() => setShowMonthPopUP(true)}
              rightIcon={dropDownThickArrow}
              customSubWrapperStyles={styles.monthFilterBtnStyles}
              customPopUpContainerStyles={styles.monthFilterPopUpStyles}
              customPlaceHolderStyles={styles.timeFilterTextStyles}
              showOverlay={true}
              selectedItem={filterTimeType}
            >
              <>
                <div className={styles.popupTopStyles}>
                  <p className={styles.popupTopTextStyles}>Filter by time:</p>
                  <div
                    onClick={() => setShowMonthPopUP(false)}
                    className={styles.crossIconStyles}
                  >
                    <img src={crossimg} alt="cross icon" />
                  </div>
                </div>
                <div className={styles.filterTypesContainerStyles}>
                  {timeFilterOptions &&
                    timeFilterOptions?.map((item, index) => (
                      <p
                        className={styles.filterTypeTextStyles}
                        style={
                          item === filterTimeType
                            ? { background: '#ECEEF4' }
                            : {}
                        }
                        key={index}
                        onClick={() => {
                          setFilterTimeType(item);
                          setShowMonthPopUP(false);
                          setSelectDate({});
                        }}
                      >
                        {item}
                      </p>
                    ))}
                </div>
                <div className={styles.dateRangeButtonWrapperStyles}>
                  <InputWithDropDown
                    placeholder={'From date'}
                    popupOpen={showDatePopup.fromDate}
                    onClick={() =>
                      setShowDatePopup({ ...showDatePopup, fromDate: true })
                    }
                    onClose={() =>
                      setShowDatePopup({ ...showDatePopup, fromDate: false })
                    }
                    rightIcon={dropdownarrow}
                    selectedItem={
                      selectDate.fromDate
                        ? moment(selectDate?.fromDate)
                            .format('DD/MM/YYYY')
                            .toString()
                        : 'From date'
                    }
                    customPopUpContainerStyles={styles.fromDatePopUpStyles}
                    customPlaceHolderStyles={
                      selectDate.fromDate && styles.fromDateTextStyles
                    }
                  >
                    <Calender
                      onSelectDate={(date) =>
                        handleToSelectFromAndToDate('fromDate', date)
                      }
                    />
                  </InputWithDropDown>

                  <InputWithDropDown
                    placeholder={'To date'}
                    popupOpen={showDatePopup.toDate}
                    onClick={() =>
                      setShowDatePopup({ ...showDatePopup, toDate: true })
                    }
                    onClose={() =>
                      setShowDatePopup({ ...showDatePopup, toDate: false })
                    }
                    rightIcon={dropdownarrow}
                    customPopUpContainerStyles={styles.toDatePopUpStyles}
                    selectedItem={
                      selectDate.toDate
                        ? moment(selectDate.toDate)
                            .format('DD/MM/YYYY')
                            .toString()
                        : 'To date'
                    }
                    customPlaceHolderStyles={
                      selectDate.toDate && styles.toDateTextStyles
                    }
                  >
                    <Calender
                      onSelectDate={(date) =>
                        handleToSelectFromAndToDate('toDate', date)
                      }
                    />
                  </InputWithDropDown>
                </div>
              </>
            </InputWithDropDown>

            <InputWithDropDown
              popupOpen={employeePopup}
              onClick={() => setEmployeePopUp(true)}
              rightIcon={dropDownThickArrow}
              customSubWrapperStyles={styles.employeeFilterBtnStyles}
              customPopUpContainerStyles={styles.employeeFilterPopUpStyles}
              customPlaceHolderStyles={styles.employeeFilterTextStyles}
              showOverlay={true}
              selectedItem={
                selectedEmployee ? selectedEmployee?.full_name : 'All employees'
              }
            >
              <div className={styles.topFilterEmployeeHeaderStyles}>
                <p className={styles.filterPeopleTextStyles}>
                  Filter by people:
                </p>
                <div
                  className={styles.closeImgBlockStyles}
                  onClick={() => setEmployeePopUp(false)}
                >
                  <img
                    src={closeIconWitOutBg}
                    alt="closeIconWitOutBg"
                    className={styles.imageStyles}
                  />
                </div>
              </div>

              <div className={styles.middleSearchAndEmployeeDataStyles}>
                <SearchInput
                  placeholder="Search people..."
                  onSearchInputChange={handleSearchInputChange}
                  searchContent={searchEmployee}
                  customContainerStyles={styles.searchInputStyles}
                  customCloseImgStyles={styles.closeImgStyles}
                />
                <div className={styles.employeeDataViewStyles}>
                  {filteredEmployees?.map((item, index) => {
                    return (
                      <EmployeeCard
                        key={index}
                        image={avatar1}
                        name={item?.full_name}
                        designation={item?.roles?.map(
                          (role) =>
                            ` ${role.experience > 18 ? 'Senior' : 'Junior'} ${
                              role.name
                            }`
                        )}
                        onInputClick={() => handleEmployeeSelection(item)}
                        customCardContainerStyles={
                          selectedEmployee._id === item._id
                            ? styles.activeEmployeeDataStyles
                            : styles.employeeDataStyles
                        }
                      />
                    );
                  })}
                </div>
              </div>

              <div className={styles.allEmployeeBtnBlockStyles}>
                <Button
                  title="All employees"
                  btnStyle={styles.addEmployeeBtnStyles}
                />
              </div>
            </InputWithDropDown>
          </div>
        </div>

        <div className={styles.tableAndUserDetailsStyles}>
          <div className={styles.tableStyles}>
            {/* <div className={styles.dayAndDateStyles}> */}
            <div className={styles.calenderLeftStyles}>
              <p className={styles.calenderSubDescTitleStyles}>
                Employees & leaves count for the opted time-frame
              </p>
            </div>
          </div>
          <div className={styles.userLeaveAndNameStyles}>
            {allEmployeeLeaves?.map((item, index) => {
              // Filter leaves within displayed dates and calculate leave count
              const leaveCount = item?.leaves?.reduce((count, leave) => {
                const startDate = moment(leave.start_date);
                const endDate = moment(leave.end_date);
                const leaveDuration = endDate.diff(startDate, 'days') + 1;

                // Check if leave period intersects with any of the displayed dates
                const overlappingDays = datesToDisplay.reduce(
                  (overlap, date) => {
                    const currentDate = moment(date);
                    const isOverlap = currentDate.isBetween(
                      startDate,
                      endDate,
                      null,
                      '[]'
                    );
                    return overlap + (isOverlap ? 1 : 0);
                  },
                  0
                );

                return count + overlappingDays;
              }, 0);

              const filteredDates = datesToDisplay.filter((date) =>
                moment(date).isBetween(
                  selectDate.fromDate,
                  selectDate.toDate,
                  null,
                  '[]'
                )
              );

              return (
                <div style={{ display: 'flex', flexShrink: 0 }}>
                  <div key={index} className={styles.userTableLeftStyles}>
                    <div className={styles.userImgStyles}>
                      <img
                        src={item?.image ? item?.image : avatar1}
                        alt=""
                        className={styles.userImageStyles}
                      />
                    </div>
                    <div className={styles.nameAndLeaveStyles}>
                      <p className={styles.nameTitleStyles}>{item.full_name}</p>
                      <p className={styles.leaveTitleStyles}>
                        Leave count: {leaveCount}
                      </p>
                    </div>
                  </div>
                  <div className={styles.dateDayContainerStyles}>
                    {datesToDisplay?.map((date, dateIndex) => {
                      const leaveRequest = item?.leaves?.find((leave) => {
                        return (
                          moment(date).isSameOrAfter(leave.start_date) &&
                          moment(date).isSameOrBefore(leave.end_date)
                        );
                      });

                      let blockStyle = {};
                      let emoji = '';
                      if (leaveRequest) {
                        switch (leaveRequest.leave_type) {
                          case 'Sick':
                            blockStyle.backgroundColor = '#A3FFA080';
                            blockStyle.borderRadius = '12px';
                            emoji = '🤒';
                            break;
                          case 'Unpaid':
                            blockStyle.backgroundColor = '#FDA0FF80';
                            blockStyle.borderRadius = '12px';
                            emoji = '😎';
                            break;
                          case 'Paid':
                            blockStyle.backgroundColor = '#A0D2FF80';
                            blockStyle.borderRadius = '12px';
                            emoji = '🤑';
                            break;
                          default:
                            break;
                        }
                      }

                      return (
                        <div
                          key={dateIndex}
                          className={`${styles.dayAndDateStyles} ${
                            leaveRequest ? styles.onLeave : ''
                          }`}
                          style={blockStyle}
                        >
                          <p className={styles.dayStyles}>
                            {moment(date).format('dd')}
                          </p>
                          <p className={styles.dateStyles}>
                            {moment(date).format('DD')}
                          </p>
                          {emoji && (
                            <p className={styles.emojiStyles}>{emoji}</p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.containerStyles}>
          <>
            {renderTopSection()}
            {renderMiddleSection()}
            {renderCalenderSection()}
          </>
        </div>
      )}
    </React.Fragment>
  );
};
export default AdminLeaves;
