import React, { useEffect, useRef, useState } from 'react';
import {
  avatar1,
  closeBackgroundImg,
  dropdownarrow,
  eclocklogo,
  hashIcon,
  plusIcon,
} from 'resources/images/images';
import Popup from 'components/popup';
import {
  generalOptionsData,
  menuData,
  navigationOptionsData,
} from 'constants/commondata/commondata';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from 'components/input';
import TextArea from 'components/textarea';
import Button from 'components/button';
import ProjectCategoryCard from 'components/cards/projectcategorycard';
import ProgressBar from 'components/progressbar';
import EmployeeCard from 'components/cards/employeecard';
import SearchInput from 'components/searchinput';
import SelectedEmployeeCard from 'components/cards/selectedmployeecard';
import InputWithDropDown from 'components/inputwithdropdown';
import SelectRoleCard from 'components/cards/selectRoleCard';
import MultiSelectInput from 'components/multiselectinput';
import { useAuthContext } from 'hooks/useauthcontext';
import {
  addRolesAndTechnologiesApi,
  employeeCreationApi,
} from 'networking/api/employee';
import {
  mailValidationSchema,
  nameValidationSchema,
} from 'validations/validations';
import PhoneNumberInput from 'components/phonenumberinput';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import { createProjectApi } from 'networking/api/adminregister';
import useUserViewsHook from 'hooks/userViewshook';
import { useAppDataContext } from 'hooks/useappdatacontext';
import Calender from 'components/calender';
import moment from 'moment';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import LoadingSpinner from 'components/loadingspinner';
import styles from './styles.module.css';

const LeftSideBarMenu = () => {
  // contexts
  const { logout, user, authError, setAuthError, userType } = useAuthContext();
  const { rolesData, skillsData, employee, getAllEmployeesData, getTechData } =
    useEmployeeDataContext();
  const { getAdminProjects } = useProjectsDataContext();
  const { isAdmin, isEmployee } = useUserViewsHook();
  const { addNewEmployeeModal, setAddNewEmployeeModal, loader, setLoader } =
    useAppDataContext();

  const [showLoader, setShowLoader] = useState(false);

  // routing
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //refs
  const popupRef = useRef(null);

  // states:
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedItemGeneral, setSelectedItemGeneral] = useState(null);
  const [selectCreateNewOption, setSelectCreateNewOption] = useState(null);

  // popup's and modal states
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [iscreateProjectModalOpen, setIscreateProjectModalOpen] =
    useState(false);
  const [showSelectEmployeePopup, setShowSelectEmployeePopup] = useState(false);
  const [newProjectCreatedModalOpen, setNewProjectCreatedModalOpen] =
    useState(false);
  // const [addNewEmployeeModal, setAddNewEmployeeModal] = useState(false);
  const [showEmployeeRolePopup, setShowEmployeeRolePopup] = useState(false);
  const [showTechnologyPopup, setShowTechnologyPopup] = useState(false);

  // create new project data states
  const [projectTitle, setProjectTitle] = useState('');
  const [addCategory, setAddCategory] = useState('');
  const [describeProject, setDescribeProject] = useState('');
  const [selectProjectCategory, setSelectProjectCategory] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [selectNoOfWeeks, setSelectNoOfWeeks] = useState('');
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  const [showCreateProjectPopups, setShowCreateProjectPopups] = useState({
    startDate: false,
    overAllDeadLine: false,
  });

  // create an new employee states
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState({
    country_code: '46',
    value: '',
  });
  const [selectedEmployeeRole, setSelectedEmployeeRole] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [enterEmployeeRole, setEnterEmployeeRole] = useState('');
  const [enterTechnology, setEnterTechnology] = useState('');
  const [selectedTechStack, setSelectedTechStack] = useState([]);
  const [showInvitationSentBtn, setShowInvitationSentBtn] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    fullName: '',
    email: '',
    phonenumber: '',
    employeeRole: '',
    techStack: '',
    addCategory: '',
  });

  const [filteredRolesData, setFilteredRolesData] = useState([]);
  const [filteredSkillsData, setFilteredSkillsData] = useState([]);

  // useEffects:

  useEffect(() => {
    setTimeout(() => {
      setNewProjectCreatedModalOpen(false);
    }, 3000);
  }, [newProjectCreatedModalOpen]);

  // filtered roles data
  useEffect(() => {
    if (addCategory) {
      if (Array.isArray(rolesData)) {
        const filteredData = rolesData.filter((role) =>
          role.toLowerCase().includes(addCategory.toLowerCase())
        );
        setFilteredRolesData(filteredData);
      }
    } else {
      setFilteredRolesData([]);
    }
  }, [addCategory, rolesData]);

  useEffect(() => {
    if (enterEmployeeRole?.trim() !== '') {
      const filtered = rolesData?.filter((role) =>
        role?.toLowerCase()?.includes(enterEmployeeRole?.toLowerCase())
      );
      setFilteredRolesData(filtered);
    } else {
      setFilteredRolesData(rolesData);
    }
  }, [enterEmployeeRole, rolesData]);

  // filtered skills data
  useEffect(() => {
    if (enterTechnology.trim() !== '') {
      const filtered = skillsData.filter((skill) =>
        skill.toLowerCase().includes(enterTechnology.toLowerCase())
      );
      setFilteredSkillsData(filtered);
    } else {
      setFilteredSkillsData(skillsData);
    }
  }, [enterTechnology, skillsData]);

  // onload diplay the filtered employee in create new project
  useEffect(() => {
    setFilteredEmployees(employee);
  }, [employee]);

  // after sometime remove the invitation sent btn create new project
  useEffect(() => {
    setTimeout(() => {
      setShowInvitationSentBtn(false);
    }, 3000);
  }, [showInvitationSentBtn]);

  // close calender after selecting the date
  useEffect(() => {
    if (startDate) {
      setShowCreateProjectPopups({
        ...showCreateProjectPopups,
        startDate: false,
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (selectNoOfWeeks) {
      setShowCreateProjectPopups({
        ...showCreateProjectPopups,
        overAllDeadLine: false,
      });
    }
  }, [selectNoOfWeeks]);

  // function to open a createnew project modal
  const toggleCreateNewProjectModal = () => {
    setIscreateProjectModalOpen(!iscreateProjectModalOpen);
  };

  // function to open a createnew employee modal
  const toggleCreateNewEmployeeModal = () => {
    setAddNewEmployeeModal(!addNewEmployeeModal);
  };

  // function to open a popup
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  // function to handle create new project and employee
  const handleMenuItemClick = (index) => {
    if (index === 0) {
      toggleCreateNewProjectModal();
      setPopupOpen(false);
    } else if (index === 1) {
      toggleCreateNewEmployeeModal();
      setPopupOpen(false);
    } else {
      setSelectCreateNewOption(index);
    }
  };

  // function to handle navigation menu
  const handleNavigationItemClick = (index, item) => {
    if (item.navigationHeading === 'Leaves') {
      if (isAdmin) {
        navigate(item.route);
      } else {
        navigate('/employee-leaves');
      }
    } else {
      navigate(item.route);
    }
    setSelectedItem(index);
  };

  // // function to handle general menu
  const handleGeneralItemClick = (index) => {
    // if (index === generalOptionsData.length - 1) {
    //   logout();
    // }
    setSelectedItemGeneral(index);
  };

  // function to close the popup onclick the outside
  const handleClickOutside = (event) => {
    if (
      isPopupOpen &&
      popupRef.current &&
      !popupRef.current.contains(event.target)
    ) {
      togglePopup();
    }
  };

  // function to project handle category selection
  const handleCategoryClick = (category) => {
    if (selectProjectCategory.includes(category)) {
      setSelectProjectCategory(
        selectProjectCategory.filter((c) => c !== category)
      );
    } else {
      setSelectProjectCategory([...selectProjectCategory, category]);
    }
  };

  // search the employees from select employee drop down

  const handleSearchInputChange = (value) => {
    const filtered = employee.filter((item) => {
      // place employee
      return item.full_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredEmployees(filtered);
  };

  // function to handle select employee
  const handleSelectEmployeeClick = (item, start_date, end_date) => {
    const isEmployeeSelected = selectEmployee.some(
      (employee) => employee.employee._id === item._id
    );

    if (isEmployeeSelected) {
      // If employee is already selected, update the dates
      const updatedSelectEmployee = selectEmployee.map((employee) =>
        employee.employee._id === item._id
          ? { ...employee, start_date, end_date }
          : employee
      );
      setSelectEmployee(updatedSelectEmployee);
    } else {
      // If employee is not selected, add new entry with dates
      setSelectEmployee([
        ...selectEmployee,
        { employee: item, start_date, end_date },
      ]);
    }

    setShowSelectEmployeePopup(false);
  };

  // function to handle date change
  const handleDateChange = (item, start_date, end_date) => {
    if (item && item._id) {
      const updatedSelectEmployee = selectEmployee.map((employee) =>
        employee.employee._id === item._id
          ? { ...employee, start_date, end_date }
          : employee
      );
      setSelectEmployee(updatedSelectEmployee);
    } else {
      console.error('Invalid item or missing _id property:', item);
    }
  };

  // function to handle delete an employee from employee dropdown option
  const deleteSelectedEmployee = (index) => {
    setSelectEmployee(selectEmployee.filter((item, i) => i !== index));
  };

  // function to handleNextClick in the create project
  const handleNextClickOfCreateNewProject = () => {
    if (currentStep === 1) {
      if (projectTitle && describeProject && selectProjectCategory.length > 0) {
        setCurrentStep(currentStep + 1);
      }
    } else if (
      currentStep === 2 &&
      startDate &&
      selectNoOfWeeks &&
      selectEmployee
    ) {
      handleCreateNewProject();
    }
  };

  // function to save project assigned_to data
  const projectAssignedToData = () => {
    const data = selectEmployee.map((item) => {
      return {
        employee_id: item.employee && item.employee._id,
        start_date: item.start_date,
        end_date: item.end_date,
      };
    });
    return data;
  };

  // function to handle send create new project data to api
  const handleCreateNewProject = async () => {
    try {
      setLoader(true);
      const employeeData = projectAssignedToData();
      const data = {
        admin_id: user._id,
        title: projectTitle,
        description: describeProject,
        category_tags: selectProjectCategory,
        start_date: startDate,
        end_date: selectNoOfWeeks,
        assigned_to: employeeData,
      };
      let response = await createProjectApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        setNewProjectCreatedModalOpen(true);
        resetCreateNewProjectData();
        getAdminProjects();
      } else {
        setLoader(false);
        setIscreateProjectModalOpen(true);
        setNewProjectCreatedModalOpen(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // reset the create new project data
  const resetCreateNewProjectData = () => {
    setProjectTitle('');
    setDescribeProject('');
    setSelectProjectCategory([]);
    setStartDate('');
    setSelectNoOfWeeks('');
    setSelectEmployee([]);
    setCurrentStep(1);
    setIscreateProjectModalOpen(false);
    setAddCategory('');
  };

  // function to handle SelectEmployeeRole
  const handleEmployeeRole = (item) => {
    const roleAlreadySelected = selectedEmployeeRole.some(
      (i) => i.name === item
    );
    if (roleAlreadySelected) {
      // If the role is already selected, remove it
      setSelectedEmployeeRole((prevSelectedEmployeeRole) =>
        prevSelectedEmployeeRole.filter((i) => i.name !== item)
      );
    } else {
      // If the role is not selected, add it
      setSelectedEmployeeRole((prevSelectedEmployeeRole) => [
        ...prevSelectedEmployeeRole,
        { name: item, experience: '' },
      ]);
    }
    setErrorMessages({ ...errorMessages, employeeRole: '' });
  };

  // function to handle technology
  const handleTechnology = (item) => {
    const technologyAlreadySelected = selectedTechnology.some(
      (i) => i.name === item
    );
    if (technologyAlreadySelected) {
      // If the technology is already selected, remove it
      setSelectedTechnology((prevTechnology) =>
        prevTechnology.filter((i) => i.name !== item)
      );
    } else {
      // If the technology is not selected, add it
      setSelectedTechnology((prevTechnology) => [
        ...prevTechnology,
        { name: item, experience: '' },
      ]);
    }
    setErrorMessages({ ...errorMessages, employeeRole: '' });
  };

  // function to handle manual entry of employee role
  const handleManulEmployeeRoleSubmit = async () => {
    try {
      setLoader(true);
      const data = {
        admin: user?._id,
        roles: [
          addCategory ? addCategory : enterEmployeeRole && enterEmployeeRole,
        ],
        skills: [],
      };
      const response = await addRolesAndTechnologiesApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        getTechData();
        if (enterEmployeeRole.trim() !== '') {
          setSelectedEmployeeRole((prevSelectedEmployeeRole) => [
            ...prevSelectedEmployeeRole,
            { name: enterEmployeeRole, experience: '' },
          ]);
          setEnterEmployeeRole('');
          setAddCategory('');
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // functio to handle manual entry of technology

  const handleManulTechnologySubmit = async () => {
    try {
      setLoader(true);
      const data = {
        admin: user?._id,
        roles: [],
        skills: [enterTechnology],
      };

      const response = await addRolesAndTechnologiesApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        getTechData();
        if (enterTechnology.trim() !== '') {
          setSelectedTechnology((prevSelectedTechnology) => [
            ...prevSelectedTechnology,
            { name: enterTechnology, experience: '' },
          ]);
          setEnterTechnology('');
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // function to handle remove the selectedEmployees role
  const handleRemoveSelectedEmployeeRole = (item) => {
    setSelectedEmployeeRole((prevRoles) => prevRoles.filter((i) => i !== item));
  };

  // function to handle remove technology
  const handleRemoveSelectedTechnology = (item) => {
    setSelectedTechnology((prevTechnologies) =>
      prevTechnologies.filter((i) => i !== item)
    );
  };

  // function to create new employee api
  const handleAddNewEmployee = async () => {
    // const selectedTechStackData = selectedTechStack.map((item) => item.value);
    try {
      setShowLoader(true);
      setLoader(false);
      const data = {
        admin: user?._id,
        account_type: 'Employee',
        full_name: fullName,
        email: email,
        phone_number: {
          country_code: phonenumber.country_code,
          value: phonenumber.value,
        },
        roles: selectedEmployeeRole,
        skills: selectedTechnology,
      };
      const response = await employeeCreationApi(data);
      if (response.data.type === 'success') {
        setShowInvitationSentBtn(true);
        setAddNewEmployeeModal(false);
        resetAddNewEmployeeData();
        getAllEmployeesData();
        setShowLoader(false);
        setLoader(false);
      } else {
        setAuthError(response.data.message);
        setShowInvitationSentBtn(false);
        setAddNewEmployeeModal(true);
        setShowLoader(false);
        setLoader(false);
      }
    } catch (error) {
      console.log('error', error);
      setAuthError(error);
      setAddNewEmployeeModal(true);
      setShowLoader(false);
      setLoader(false);
    }
  };

  // function to reset the addnew employee data
  const resetAddNewEmployeeData = () => {
    setFullName('');
    setEmail('');
    setSelectedEmployeeRole([]);
    setEnterEmployeeRole();
    setSelectedTechStack([]);
    setSelectedTechnology([]);
    setPhoneNumber({
      country_code: '46',
      value: '',
    });
    setShowEmployeeRolePopup(false);
  };

  // function to handle experiance
  const handleExperiance = (i, index) => {
    setSelectedEmployeeRole((prevRoles) => {
      return prevRoles.map((role, ind) => {
        if (ind === index) {
          return { ...role, experience: i };
        } else {
          return role;
        }
      });
    });
  };

  // function to handle employee experinace in technology
  const handleEmployeeTechnologyExperiance = (i, index) => {
    setSelectedTechnology((prevRoles) => {
      return prevRoles.map((role, ind) => {
        if (ind === index) {
          return { ...role, experience: i };
        } else {
          return role;
        }
      });
    });
  };

  // create new employee validation
  const createNewEmployeeValidation = () => {
    const errors = {
      fullName: fullName
        ? nameValidationSchema.test(fullName)
          ? ''
          : 'Full name accepts only alphabets'
        : 'Full name is required',
      email: email
        ? mailValidationSchema.test(email)
          ? ''
          : 'Valid email is required'
        : 'Email is required',
      phonenumber: phonenumber.value ? '' : 'Phone number is required',
      employeeRole:
        selectedEmployeeRole.length > 0 || enterEmployeeRole
          ? ''
          : 'Select at least one role',
      techStack:
        selectedTechnology.length > 0 ? '' : 'Select at least one technology',
    };
    setErrorMessages(errors);
    return Object.values(errors).every((error) => !error);
  };

  const renderHeaderSection = () => {
    return (
      <div className={styles.eclockImgBlockStyles}>
        <img src={eclocklogo} alt="eclocklogo" className={styles.imageStyles} />
      </div>
    );
  };

  const renderCreateNewButtonSection = () => {
    return (
      <div>
        <div className={styles.createNewButtonStyles} onClick={togglePopup}>
          <div className={styles.plusIconImgStyles}>
            <img src={plusIcon} alt="" className={styles.imageStyles} />
          </div>
          <p className={styles.createNewText}>Create new</p>
        </div>
        {renderCreateNewPopupSection()}
        {renderCreateNewProjectModalSection()}
        {renderAddNewEmployeeModalSection()}
      </div>
    );
  };

  const renderCreateNewPopupSection = () => {
    return (
      <Popup
        show={isPopupOpen}
        onClose={togglePopup}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.createPopStyles}
        handleClickOutside={handleClickOutside}
      >
        {menuData.map((item, index) => (
          <div
            key={index}
            className={`${styles.createNewItemStyles} ${
              selectCreateNewOption === index
                ? styles.createNewItemHighlighted
                : ''
            } ${
              index === 0
                ? styles.createNeWFirstItemStyles
                : index === menuData.length - 1
                ? styles.createNewLastItemStyles
                : ''
            }`}
            onClick={() => handleMenuItemClick(index)}
          >
            <div
              className={
                index === 0
                  ? styles.createNewItemIconStyles
                  : styles.createNewIcon2Styles
              }
            >
              <img src={item.icon} alt="" className={styles.imageStyles} />
            </div>
            <div className={styles.createNewItemTextStyles}>
              <h5 className={styles.createNewTextStyles}>{item.text}</h5>
              <p className={styles.createNewDescStyles}>{item.description}</p>
            </div>
          </div>
        ))}
      </Popup>
    );
  };

  const renderCreateNewProjectModalSection = () => {
    return (
      <Popup
        show={iscreateProjectModalOpen}
        showOverlay={true}
        onClose={toggleCreateNewProjectModal}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={
          selectEmployee.length > 4
            ? styles.createNewProject2PopStyles
            : styles.createNewProjectPopStyles
        }
      >
        <div className={styles.fillProjectDetailsContainerStyles}>
          {renderProjectDetailsHeadingSection()}
          <div>
            {currentStep === 1 && renderProjectDetailsInputsSection()}
            {currentStep === 2 && renderAssignProjectInputsSection()}
            {renderCancelAndContinueBtnSection()}
          </div>
        </div>
      </Popup>
    );
  };

  const renderProjectDetailsHeadingSection = () => {
    const progressPercentage = (currentStep - 0) * 50;
    return (
      <div className={styles.topHeadingBlockStyles}>
        <h5 className={styles.fillProjectHeadingStyles}>
          {currentStep === 1
            ? 'Fill-in the project details'
            : 'Assign to the employees'}
        </h5>
        <ProgressBar progressPercentage={progressPercentage} />
      </div>
    );
  };

  const renderProjectDetailsInputsSection = () => {
    return (
      <div className={styles.middleInputsBlockStyles}>
        <Input
          name="projectTitle"
          lableText={'Project title*'}
          lableTextStyle={styles.projectTitleLabelStyles}
          placeholder={'Enter title here'}
          value={projectTitle}
          onChange={(e) => setProjectTitle(e.target.value)}
          customInputStyle={styles.projectTitleInputStyles}
        />
        <TextArea
          name="projectDescription"
          label={'Describe the project*'}
          placeholder={'Description here'}
          value={describeProject}
          onChange={(e) => setDescribeProject(e.target.value)}
          customTextAreaStyles={styles.describeProjectTextAreaStyles}
          customTextAreaContainerStyles={
            styles.projectDescriptionTextAreaStyles
          }
        />
        <Input
          name="addcategorytag"
          lableText={'Add Category*'}
          lableTextStyle={styles.projectTitleLabelStyles}
          placeholder={'Enter category here'}
          value={addCategory}
          onFocus={() =>
            setErrorMessages({ ...errorMessages, addCategory: '' })
          }
          onChange={(e) => setAddCategory(e.target.value)}
          onClickRightBtn={() => {
            if (addCategory === '') {
              setErrorMessages({
                ...errorMessages,
                addCategory: 'Please enter category ',
              });
            } else {
              handleManulEmployeeRoleSubmit();
            }
          }}
          hasError={errorMessages.addCategory}
          rightBtnTitle="Submit"
          customInputStyle={styles.addCtegoryInputStyles}
        />

        {filteredRolesData?.length > 0 && (
          <div className={styles.selectCategoriesBlockStyles}>
            <p className={styles.selectCategoryHeadingStyles}>
              Select category tags*
            </p>

            <ProjectCategoryCard
              categories={filteredRolesData}
              icon="#"
              selectedCategories={selectProjectCategory}
              onCategoryClick={handleCategoryClick}
              customSelectCategoryCardStyles={
                styles.rolesDataCardCategoryStyles
              }
            />
          </div>
        )}
      </div>
    );
  };

  const renderAssignProjectInputsSection = () => {
    return (
      <div className={styles.assignEmployeeContainerStyles}>
        <InputWithDropDown
          labelText="Assign to*"
          placeholder="Select employee"
          rightIcon={dropdownarrow}
          onClick={() => setShowSelectEmployeePopup(!showSelectEmployeePopup)}
          popupOpen={showSelectEmployeePopup}
          onClose={() => setShowSelectEmployeePopup(false)}
          customSubWrapperStyles={styles.selectNoOfWeeksInputStyles}
          customPopUpContainerStyles={styles.selectNoOfWeekPopUpStyles}
        >
          <SearchInput
            placeholder="Search people..."
            onCloseImgClick={() =>
              setShowSelectEmployeePopup(!showSelectEmployeePopup)
            }
            onSearchInputChange={handleSearchInputChange}
            onCloseImg={true}
          />
          <div className={styles.selectedEmployeeContainerStyles}>
            {filteredEmployees &&
              filteredEmployees.map((item, index) => {
                const selectedEmployee = selectEmployee.find(
                  (employee) =>
                    employee.employee && employee.employee._id === item._id
                );

                return (
                  <EmployeeCard
                    key={index}
                    image={item.image ? item.image : avatar1}
                    name={item.full_name}
                    designation={
                      item.roles && item.roles.map((item) => item.name)
                    }
                    count={item.projects_assigned}
                    onInputClick={() => {
                      handleSelectEmployeeClick(item);
                    }}
                    customCardContainerStyles={
                      selectedEmployee ? styles.selectedEmployeeStyles : ''
                    }
                  />
                );
              })}
          </div>
        </InputWithDropDown>
        {selectEmployee.length > 0 && (
          <div className={styles.selectEmployeeContainerStyles}>
            {selectEmployee &&
              selectEmployee.map((item, index) => {
                const employee = item.employee;
                return (
                  <SelectedEmployeeCard
                    key={index}
                    crossImg={closeBackgroundImg}
                    employeeImg={employee.image ? employee.image : avatar1}
                    name={employee.full_name}
                    designation={
                      employee.roles && employee.roles.map((role) => role.name)
                    }
                    onClickCloseIcon={() => {
                      deleteSelectedEmployee(index);
                    }}
                    start_date={item.start_date}
                    end_date={item.end_date}
                    onSelectDateChange={(start, end) => {
                      handleDateChange(employee, start, end);
                    }}
                  />
                );
              })}
          </div>
        )}
        <InputWithDropDown
          labelText="Start date*"
          placeholder="DD/MM/YY"
          popupOpen={showCreateProjectPopups.startDate}
          selectedItem={startDate && moment(startDate).format('DD/MM/YYYY')}
          onClickInput={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              startDate: true,
            })
          }
          onClose={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              startDate: false,
            })
          }
          customWrapperStyles={styles.startDateInputStyles}
          customPopUpContainerStyles={styles.startDatePopUpStyles}
          customPlaceHolderStyles={
            startDate && styles.startDatePlaceHolderStyles
          }
        >
          <Calender onSelectDate={setStartDate} />
        </InputWithDropDown>

        <InputWithDropDown
          labelText="overall DeadLine Of The Project *"
          placeholder="Select no. of week(s)"
          popupOpen={showCreateProjectPopups.overAllDeadLine}
          rightIcon={dropdownarrow}
          selectedItem={
            selectNoOfWeeks && moment(selectNoOfWeeks).format('DD/MM/YYYY')
          }
          onClickInput={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              overAllDeadLine: true,
            })
          }
          onClose={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              overAllDeadLine: false,
            })
          }
          customWrapperStyles={styles.overAllDeadLineInputStyles}
          customPopUpContainerStyles={styles.startDatePopUpStyles}
          customPlaceHolderStyles={
            selectNoOfWeeks && styles.startDatePlaceHolderStyles
          }
        >
          <Calender onSelectDate={setSelectNoOfWeeks} />
        </InputWithDropDown>
      </div>
    );
  };

  const renderCancelAndContinueBtnSection = () => {
    return (
      <div className={styles.bottomCancelAndContinueBtnBlockStyles}>
        <Button
          title="Cancel"
          onClick={() => {
            toggleCreateNewProjectModal();
            resetCreateNewProjectData();
          }}
          btnStyle={styles.cancelBtnStyles}
        />

        <Button
          title={currentStep === 1 ? 'Continue' : 'Create project'}
          type="submit"
          btnStyle={
            currentStep === 1 &&
            projectTitle &&
            describeProject &&
            selectProjectCategory.length > 0
              ? styles.activeContinueBtnStyles
              : currentStep === 2 &&
                startDate &&
                selectNoOfWeeks &&
                selectEmployee.length > 0 &&
                selectEmployee.every(
                  (item) =>
                    item.start_date !== undefined && item.end_date !== undefined
                )
              ? styles.activeContinueBtnStyles
              : styles.continueBtnStyles
          }
          onClick={() => {
            handleNextClickOfCreateNewProject();
          }}
        />
      </div>
    );
  };

  const renderNewProjectIsCreatedModalSection = () => {
    return (
      <Popup
        show={newProjectCreatedModalOpen}
        showOverlay={true}
        onClose={() => setNewProjectCreatedModalOpen(false)}
        popupRef={popupRef}
        customPopUpStyles={styles.newProjectCreatedPopupStyles}
      >
        <div className={styles.newProjectCreatedContentStyles}>
          <p className={styles.newProjectCreatedTextStyles}>
            New project is created
          </p>
          <div
            className={styles.closeImgStyles}
            onClick={() => setNewProjectCreatedModalOpen(false)}
          >
            <img
              src={closeBackgroundImg}
              alt="closeBackgroundImg"
              className={styles.imageStyles}
            />
          </div>
        </div>
      </Popup>
    );
  };

  const renderAddNewEmployeeModalSection = () => {
    return (
      <Popup
        show={addNewEmployeeModal}
        onClose={toggleCreateNewEmployeeModal}
        showOverlay={true}
        popupRef={popupRef}
        customPopUpStyles={styles.addNewEmployeeModalStyles}
      >
        {renderAddNewEmployeeTopHeaderSection()}
        {renderAddNewEmployeeMiddleInputsSection()}
        {renderAddNewEmployeeBottomBtnsSection()}
      </Popup>
    );
  };

  const renderAddNewEmployeeTopHeaderSection = () => {
    return (
      <h3 className={styles.enterEmployeeHeadingStyles}>
        Enter employee details
      </h3>
    );
  };

  const renderAddNewEmployeeMiddleInputsSection = () => {
    return (
      <div className={styles.addNewEmployeeInputBlockStyles}>
        <Input
          type="text"
          name="fullName"
          lableText="Full name*"
          placeholder="Employee name"
          value={fullName}
          onChange={(e) => {
            setFullName(e.target.value);
          }}
          onFocus={() => {
            setAuthError('');
            setErrorMessages({ ...errorMessages, fullName: '' });
          }}
          lableTextStyle={styles.fullNameLabelTextStyles}
          customInputStyle={styles.fullNameInputStyles}
          hasError={errorMessages.fullName}
        />
        <Input
          type="email"
          name="email"
          lableText="Email*"
          placeholder="Employee email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onFocus={() => {
            setAuthError('');
            setErrorMessages({ ...errorMessages, email: '' });
          }}
          lableTextStyle={styles.emailLabelTextStyles}
          customInputStyle={styles.emailInputStyles}
          hasError={errorMessages.email}
        />
        <PhoneNumberInput
          labeText="Phone no*"
          phoneNumber={phonenumber}
          setPhoneNumber={setPhoneNumber}
          customNumberInputContainerStyles={styles.phoneNumberInputStyles}
          onFocus={() => {
            setErrorMessages({ ...errorMessages, phonenumber: '' });
          }}
          hasError={errorMessages.phonenumber}
        />
        <div className={styles.employeeRoleDropDownBlockStyles}>
          <InputWithDropDown
            labelText="Role*"
            placeholder="Select employee role"
            rightIcon={dropdownarrow}
            popupOpen={showEmployeeRolePopup}
            onClick={() => {
              setShowEmployeeRolePopup(!showEmployeeRolePopup);
              setAuthError('');
            }}
            onClose={() => {
              setShowEmployeeRolePopup(false);
              setEnterEmployeeRole('');
            }}
            // customPlaceHolderStyles={
            //   enterEmployeeRole ? styles.selectedEmployeePlaceHolderStyles : ''
            // }
            customLabelTextStyle={styles.selectEmployeeRoleLabelStyles}
            customSubWrapperStyles={styles.selectEmployeeRoleInputStyle}
            customPopUpContainerStyles={styles.selectEmployeeRolePopUpStyles}
            // selectedItem={enterEmployeeRole}
            hasError={errorMessages.employeeRole}
          >
            <div className={styles.employeeRolesBlockStyles}>
              {filteredRolesData?.map((item, index) => {
                return (
                  <p
                    key={index}
                    className={
                      selectedEmployeeRole.find((i) => i.role === item)
                        ? styles.activeEmployeeRoleTextStyles
                        : styles.employeeRoleTextStyles
                    }
                    onClick={() => handleEmployeeRole(item)}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
            <div className={styles.employeeRoleAndCheckBtnStyles}>
              <Input
                type="text"
                name="enterEmployeeRole"
                value={enterEmployeeRole}
                onChange={(e) => setEnterEmployeeRole(e.target.value)}
                onFocus={() => setAuthError('')}
                placeholder="or Enter role here"
                customInputStyle={styles.enterEmployeeRoleInputStyles}
              />
              <Button
                title="submit"
                btnStyle={styles.roleSubmitBtnStyles}
                onClick={() => {
                  handleManulEmployeeRoleSubmit();
                }}
              />
            </div>
          </InputWithDropDown>

          <div className={styles.selectEmployeeRoleBlockStyles}>
            {selectedEmployeeRole?.map((item, index) => {
              return (
                <SelectRoleCard
                  key={index}
                  role={item.name}
                  onClickCloseImgClick={() =>
                    handleRemoveSelectedEmployeeRole(item)
                  }
                  experience={item.experience}
                  setExperiance={(i) => handleExperiance(i, index)}
                  manualEntryOfDuration={item.experiance}
                />
              );
            })}
          </div>
        </div>

        <div className={styles.employeeRoleDropDownBlockStyles}>
          <InputWithDropDown
            labelText="Tech Stack*"
            placeholder="Select tech stack"
            rightIcon={dropdownarrow}
            popupOpen={showTechnologyPopup}
            onClick={() => {
              setShowTechnologyPopup(!showTechnologyPopup);
              setAuthError('');
            }}
            onClose={() => {
              setShowTechnologyPopup(false);
              setEnterTechnology('');
            }}
            // customPlaceHolderStyles={
            //   enterTechnology ? styles.selectedEmployeePlaceHolderStyles : ''
            // }
            customLabelTextStyle={styles.selectEmployeeRoleLabelStyles}
            customSubWrapperStyles={styles.selectEmployeeRoleInputStyle}
            customPopUpContainerStyles={styles.selectEmployeeRolePopUpStyles}
            // selectedItem={enterTechnology}
            hasError={errorMessages.techStack}
          >
            <div className={styles.employeeSkillsBlockStyles}>
              {filteredSkillsData?.map((item, index) => {
                return (
                  <p
                    key={index}
                    className={
                      selectedTechnology.find((i) => i.name === item)
                        ? styles.activeEmployeeRoleTextStyles
                        : styles.employeeSkillTextStyles
                    }
                    onClick={() => handleTechnology(item)}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
            <div className={styles.employeeRoleAndCheckBtnStyles}>
              <Input
                type="text"
                name="enterEmployeeRole"
                value={enterTechnology}
                onChange={(e) => setEnterTechnology(e.target.value)}
                onFocus={() => setAuthError('')}
                placeholder="or Enter technology here"
                customInputStyle={styles.enterEmployeeRoleInputStyles}
              />
              <Button
                title="submit"
                btnStyle={styles.roleSubmitBtnStyles}
                onClick={() => {
                  handleManulTechnologySubmit();
                }}
              />
            </div>
          </InputWithDropDown>

          <div className={styles.selectEmployeeRoleBlockStyles}>
            {selectedTechnology?.map((item, index) => {
              return (
                <SelectRoleCard
                  key={index}
                  role={item.name}
                  onClickCloseImgClick={() =>
                    handleRemoveSelectedTechnology(item)
                  }
                  experience={item.experience}
                  setExperiance={(i) =>
                    handleEmployeeTechnologyExperiance(i, index)
                  }
                  manualEntryOfDuration={item.experiance}
                />
              );
            })}
          </div>
        </div>
        {/* <MultiSelectInput
          labelText="Tech stack*"
          options={skillsData?.map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          placeholder="Tool name, comma separated"
          selectedOptions={selectedTechStack}
          setSelectedOptions={setSelectedTechStack}
          setErrorMessages={setErrorMessages}
          errorMessages={errorMessages}
          hasError={errorMessages.techStack}
        /> */}
        {authError && <p className={styles.errorMessageStyles}>{authError}</p>}
      </div>
    );
  };

  const renderAddNewEmployeeBottomBtnsSection = () => {
    return (
      <div className={styles.addNewEmployeeBottomBtnBlockStyles}>
        <Button
          title="Cancel"
          onClick={() => {
            resetAddNewEmployeeData();
            setAddNewEmployeeModal(false);
            setErrorMessages({});
          }}
          btnStyle={styles.cancelBtnStyles}
        />
        <Button
          title={!showLoader ? 'Add employee' : 'Loading...'}
          onClick={() => {
            const isValid = createNewEmployeeValidation();
            if (isValid) {
              handleAddNewEmployee();
            }
          }}
          btnStyle={
            fullName &&
            email &&
            phonenumber.value &&
            selectedEmployeeRole.length > 0 &&
            selectedEmployeeRole &&
            selectedEmployeeRole?.every((item) => item?.experience !== '') &&
            selectedTechnology.length > 0 &&
            selectedTechnology &&
            selectedTechnology.every((item) => item?.experiance !== '')
              ? styles.activeEmployeeBtnStyles
              : styles.addEmployeeBtnStyles
          }
        />
      </div>
    );
  };

  const renderInvitationBtnSection = () => {
    return (
      showInvitationSentBtn && (
        <Popup
          show={showInvitationSentBtn}
          showOverlay={true}
          onClose={() => setShowInvitationSentBtn(false)}
          popupRef={popupRef}
          customPopUpStyles={styles.newEmployeeCreatedPopupStyles}
          customOverLayStyles={styles.showInvitationOverLayStyles}
        >
          <Button
            title="Invitation sent"
            onClick={() => {}}
            btnStyle={styles.invitationBtnStyles}
          />
        </Popup>
      )
    );
  };

  const renderNavigationSection = () => {
    return (
      <div className={styles.navigationContainerStyles}>
        <p className={styles.navigationTextStyles}>Navigation</p>
        <div className={styles.navigationOptionsContainer}>
          {navigationOptionsData.map((item, index) => {
            return (
              <div
                className={`${styles.navigationOptionsSubBlockStyles} ${
                  pathname === item.route ||
                  (pathname === '/employee-leaves' &&
                    item?.navigationHeading === 'Leaves' &&
                    !isAdmin)
                    ? styles.navigationOptionsSelected
                    : ''
                }`}
                key={index}
                onClick={() => handleNavigationItemClick(index, item)}
              >
                <div className={styles.hashImgBlock}>
                  <img
                    src={hashIcon}
                    alt="hash"
                    className={styles.imageStyles}
                  />
                </div>
                <p className={styles.navigationHeadingStyles}>
                  {item.navigationHeading}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderGeneralSection = () => {
    return (
      <div className={styles.generalContainerStyles}>
        <p className={styles.generalTextStyles}>General</p>
        <div className={styles.generalOptionsContainer}>
          {generalOptionsData.map((item, index) => {
            return (
              <div
                className={`${styles.generalOptionsSubBlockStyles} ${
                  selectedItemGeneral === index
                    ? styles.generalOptionsSelected
                    : ''
                }`}
                key={index}
                onClick={() => handleGeneralItemClick(index)}
              >
                <div className={styles.hashImgBlock}>
                  <img
                    src={hashIcon}
                    alt="hash"
                    className={styles.imageStyles}
                  />
                </div>
                <p className={styles.generalHeadingStyles}>
                  {item.generalHeading}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderLogoutSection = () => {
    return (
      <div className={styles.logoutSectionStyles}>
        <Button
          onClick={() => logout()}
          title="Logout"
          btnStyle={styles.logoutStyles}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.headerAndMenuOptionsContainerStyles}>
          <div className={styles.headerContainerStyles}>
            {renderHeaderSection()}
          </div>
          <div className={styles.leftSideMenuBarConatinerStyles}>
            {isAdmin && renderCreateNewButtonSection()}
            {renderNavigationSection()}
            {renderGeneralSection()}
            {/* create project modal section */}
            {renderNewProjectIsCreatedModalSection()}
            {/* create new employee invitation sent section */}
            {renderInvitationBtnSection()}
            {renderLogoutSection()}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LeftSideBarMenu;
