import React from 'react';
import styles from './styles.module.css';
import LeftSideBarMenu from 'components/leftsidebarmenu';
import Header from 'components/header';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useappdatacontext';
const DashBoardLayout = () => {
  const { sideBar, setSideBar } = useAppDataContext();
  const loacation = useLocation();
  return (
    <div className={styles.dashboardContainerStyle}>
      {sideBar && <LeftSideBarMenu />}
      <div className={styles.headerWrapperStyle}>
        {sideBar && <Header />}
        <div
          className={
            loacation.pathname === '/admin-individualemployee' ||
            loacation.pathname === '/admin-individualProject' ||
            loacation.pathname === '/pastrecords'
              ? styles.contenWrappertStyle2
              : styles.contenWrappertStyle
          }
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashBoardLayout;
