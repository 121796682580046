import React, { useEffect, useState } from 'react';
import CheckInCard from 'components/cards/checkincard';
import OnGoingProjectCard from 'components/cards/ongoingprojectcard';
import StaticsCard from 'components/cards/staticscard';
import TimeSheetTable from 'components/timesheettable';
import { rightarrow, rightarrow3 } from 'resources/images/images';
import { useAppDataContext } from 'hooks/useappdatacontext';
import TimeSheet from 'components/timesheet';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import useUserViewsHook from 'hooks/userViewshook';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTimeSheetContext } from 'hooks/usetimesheetcontext';
import { updateTimeSheetApi } from 'networking/api/timesheets';
import { useAuthContext } from 'hooks/useauthcontext';
import { getAdminStaticsDataApi } from 'networking/api/adminregister';
import LoadingSpinner from 'components/loadingspinner';
import styles from './styles.module.css';
import useTapOnProject from 'hooks/useTapOnProject';

const AdminDashBoard = () => {
  // routing:
  const navigate = useNavigate();
  const location = useLocation();

  // contexts:

  // contexts:
  const { setRoutName, setSideBar, sideBar, loader, setLoader } =
    useAppDataContext();
  const { allProjectsData, allEmployeeProjects } = useProjectsDataContext();
  const { isAdmin, isEmployee } = useUserViewsHook();
  const {
    todayTimeSheet,
    setTodayTimeSheet,
    editValue,
    setEditValue,
    updatedTimeSheet,
    setUpdatedTimeSheet,
    handleTimeSheetUpdate,
    handleEditClick,
  } = useTimeSheetContext();
  const { user } = useAuthContext();

  //states
  const [statisticsData, setStatiSticsData] = useState(null);

  //   static card data
  const staticCardData = [
    {
      count: statisticsData?.today_leave
        ? String(statisticsData?.today_leave).padStart(2, '0')
        : 'N/A',
      text: 'Employees on leave today',
    },
    {
      count: statisticsData?.Ongoing
        ? String(statisticsData?.Ongoing).padStart(2, '0')
        : 'N/A',
      text: 'Ongoing projects',
    },
    {
      count: statisticsData?.Completed
        ? String(statisticsData?.Completed).padStart(2, '0')
        : 'N/A',
      text: 'Completed projects',
    },
    {
      count: statisticsData?.Assigned
        ? String(statisticsData?.Assigned).padStart(2, '0')
        : 'N/A',
      text: 'People with assigned projects',
    },
    {
      count: statisticsData?.NotAssigned
        ? String(statisticsData?.NotAssigned).padStart(2, '0')
        : 'N/A',
      text: 'People without assigned projects',
    },
  ];

  // useEffect(() => {
  //   setUpdatedTimeSheet(todayTimeSheet);
  // }, [todayTimeSheet]);

  // useEffect
  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setSideBar(true);
    }
  }, [sideBar]);

  // const handleEditClick = (item) => {
  //   setEditValue(item);
  // };

  useEffect(() => {
    if (user?._id) {
      getStasticsData(user?._id);
    }
  }, [user]);

  // get statsticsData
  const getStasticsData = async (id) => {
    try {
      setLoader(true);
      const response = await getAdminStaticsDataApi(id);
      if (response.data.type === 'success') {
        setLoader(false);
        setStatiSticsData(response.data.data);
      } else {
        setLoader(false);
        setStatiSticsData(null);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // changing the path of the header data
  const tapOnProject = useTapOnProject(
    isAdmin,
    isEmployee,
    setRoutName,
    navigate,
    setSideBar
  );

  // function to handle statistic card navigation
  const tapOnStatisticsCard = (item) => {
    if (item.text === 'Employees on leave today') {
      navigate('/admin-leaves');
    } else if (item.text === 'Ongoing projects') {
      navigate('/admin-projects');
    } else if (item.text === 'Completed projects') {
      navigate('/admin-projects');
    } else if (item.text === 'People with assigned projects') {
      navigate('/admin-employees');
    } else if (item.text === 'People without assigned projects') {
      navigate('/admin-employees');
    }
  };

  const renderCheckInCardSection = () => {
    return (
      <div className={styles.headerAndCheckInCardStyles}>
        {isEmployee && <CheckInCard />}
      </div>
    );
  };

  const renderStasticCardSection = () => {
    return (
      <div className={styles.stasticCardMainContainerStyles}>
        <h5 className={styles.statasticTextStyles}>Statistics</h5>
        <div className={styles.stasticCardContainerStyles}>
          {staticCardData.map((item, index) => {
            return (
              <div className={styles.stasticCardStyles} key={index}>
                <StaticsCard
                  count={item.count}
                  text={item.text}
                  image={rightarrow}
                  onClick={() => tapOnStatisticsCard(item)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderonGoingProjectCardSection = () => {
    const projectData = isAdmin
      ? allProjectsData.onGoing
      : isEmployee && allEmployeeProjects.onGoing;
    return (
      <div className={styles.onGoingProjectMainContainerStyles}>
        <div className={styles.onGoingHeadingViewTextStyles}>
          <h5 className={styles.onGoingProjectTextStyles}>Ongoing projects</h5>
          <div className={styles.viewAllContainerStyles}>
            <p
              className={styles.viewAllTextStyles}
              onClick={() => navigate('/admin-projects')}
            >
              View all
            </p>
            <div className={styles.rightArrowImgStyles}>
              <img
                src={rightarrow3}
                alt="rightarrowimg"
                className={styles.imageStyles}
              />
            </div>
          </div>
        </div>
        {projectData.length > 0 ? (
          <div className={styles.onGoingProjectCardStyles}>
            {projectData &&
              projectData?.map((item, index) => (
                <OnGoingProjectCard
                  key={index}
                  title={item.title}
                  icon="#"
                  technologies={item.category_tags}
                  onClick={() => tapOnProject(item.title, item._id)}
                />
              ))}
          </div>
        ) : (
          <p className={styles.noDataFoundTextStyles}>
            No on going projects right now
          </p>
        )}
      </div>
    );
  };

  const renderTimeSheetSection = () => {
    return (
      <div className={styles.timeSheetContainerStyles}>
        <div className={styles.timeSheetHeadingViewTextStyles}>
          <h5 className={styles.timeSheetTextStyles}>
            Fill-in today’s timesheet(s)
          </h5>
          <div className={styles.viewAllContainerStyles}>
            <p
              onClick={() => navigate('/admin-timesheet')}
              className={styles.viewAllTextStyles}
            >
              View all
            </p>
            <div className={styles.rightArrowImgStyles}>
              <img
                src={rightarrow3}
                alt="right arrow img"
                className={styles.imageStyles}
              />
            </div>
          </div>
        </div>
        <div className={styles.timeSheetBlockStyles}>
          <TimeSheetTable />
          {updatedTimeSheet?.length > 0 ? (
            updatedTimeSheet.map((item, index) => {
              return (
                <TimeSheet
                  key={index}
                  item={item}
                  serialNo={
                    (updatedTimeSheet?.length - index)
                      .toString()
                      .padStart(2, '0') + '.'
                  }
                  // handleTimeSheetClick={() => handleEditClick(item)}
                  // handleTimeSheetUpdate={handleTimeSheetUpdate}
                  onClick={
                    editValue
                      ? editValue._id === item._id
                        ? handleTimeSheetUpdate
                        : () => handleEditClick(item)
                      : () => handleEditClick(item)
                  }
                  editValue={editValue}
                  setEditValue={setEditValue}
                />
              );
            })
          ) : (
            <p className={styles.timeSheetLabelInfoTextStyles}>
              Click on labels above to start filling your timesheets
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.adminDashBoardContainerStyles}>
          <div className={styles.rightContentContainerStyles}>
            {renderCheckInCardSection()}
            <div className={styles.statsticAndTimeSheetContainerStyles}>
              {isAdmin && renderStasticCardSection()}
              {renderonGoingProjectCardSection()}
              {isEmployee && renderTimeSheetSection()}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminDashBoard;
