import React, { useEffect } from 'react';
import ButtonTab from 'components/buttontab';
import {
  createnewemployeeimg,
  emptyProfile,
  jamesprofile,
  menuthreedottedimg,
} from 'resources/images/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleColor } from 'hooks/usecolorhook';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useAuthContext } from 'hooks/useauthcontext';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import useUserViewsHook from 'hooks/userViewshook';
import Employee from 'pages/employee/employeepage';
import useTapOnEmployee from 'hooks/useTapOnEmployee';
import styles from './styles.module.css';

const AdminEmployees = () => {
  // contexts:
  const { setRoutName, sideBar, setSideBar, setAddNewEmployeeModal } =
    useAppDataContext();
  const { userType, user } = useAuthContext();
  const { employee } = useEmployeeDataContext();

  // routing:
  const navigate = useNavigate();
  const location = useLocation();

  // hooks:
  const { isAdmin } = useUserViewsHook();
  const tapOnEmployee = useTapOnEmployee(
    isAdmin,
    setRoutName,
    navigate,
    setSideBar
  );

  useEffect(() => {
    if (location.pathname === '/admin-employees') {
      setSideBar(true);
    }
  }, [sideBar]);

  const renderAddEmployeeSection = () => {
    return (
      <div className={styles.addEmployeeStyles}>
        <ButtonTab
          titleText={'Add new employee'}
          icon={createnewemployeeimg}
          btnTextStyles={styles.addEmployeeTextStyles}
          buttonTabStyles={styles.addEmployeeTabStyles}
          onClick={() => setAddNewEmployeeModal(true)}
        />
      </div>
    );
  };

  const renderAllEmployeeSection = () => {
    return (
      <div className={styles.allEmployeeStyles}>
        <p className={styles.allEmployeeTextStyles}>All employees</p>
        <div>
          {employee?.length > 0 ? (
            employee?.map((item, index) => {
              return (
                <div key={index} className={styles.userDetailsStyles}>
                  <div
                    onClick={() =>
                      tapOnEmployee(
                        item.full_name,
                        item?.projects_assigned > 0
                          ? 'Assingned'
                          : 'UnAssigned',
                        item._id
                      )
                    }
                    className={styles.userLeftStyles}
                  >
                    <div className={styles.userGapStyles}>
                      <div className={styles.userImgStyles}>
                        <img
                          src={item.image ? item.image : emptyProfile}
                          alt=""
                          className={styles.imageWidth}
                        />
                      </div>
                      <div className={styles.UserAndRoleStyles}>
                        <p className={styles.userNameStyles}>
                          {item.full_name}
                        </p>
                        <div className={styles.userRolesStyles}>
                          {item?.roles?.map((i, index) => {
                            return (
                              <p key={index} className={styles.userRoleStyles}>
                                {i?.name}.
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.assignedTaskStyles}
                      style={{
                        backgroundColor: handleColor(
                          item.projects_assigned.toString().padStart(2, '0')
                        ),
                      }}
                    >
                      <p className={styles.assignedTextStyles}>
                        Assigned tasks:
                        <span className={styles.assiginedCountStyles}>
                          {item.projects_assigned.toString().padStart(2, '0')}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.userRightStyles}>
                    <img
                      src={menuthreedottedimg}
                      alt=""
                      className={styles.imageWidth}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p className={styles.noDataFoundTextStyles}>No Data Found</p>
          )}
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      {isAdmin ? (
        <>
          {employee?.length > 0 ? (
            <div className={styles.containerStyles}>
              {renderAddEmployeeSection()}
              {renderAllEmployeeSection()}
            </div>
          ) : (
            <p className={styles.noDataFoundStyles}>No Data Found</p>
          )}
        </>
      ) : (
        <Employee />
      )}
    </React.Fragment>
  );
};
export default AdminEmployees;
