import React, { useState } from 'react';
import InputWithDropDown from 'components/inputwithdropdown';
import Calender from 'components/calender';
import { dropdownarrow } from 'resources/images/images';
import moment from 'moment';
import styles from './styles.module.css';

const SelectedEmployeeCard = (props) => {
  const {
    crossImg,
    employeeImg,
    name,
    designation,
    onClickCloseIcon,
    onSelectDateChange,
    start_date,
    end_date,
  } = props;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showCreateProjectPopups, setShowCreateProjectPopups] = useState({
    start_date: false,
    end_date: false,
  });

  return (
    <div className={styles.selectEmployeeCardContainerStyles}>
      <div className={styles.selectEmployeeLeftBlockStyles}>
        <div className={styles.crossImgBlockStyles} onClick={onClickCloseIcon}>
          <img src={crossImg} alt="crossImg" className={styles.imageWidth} />
        </div>
        <div className={styles.employeeInfoBlockStyles}>
          <div className={styles.employeeImgStyles}>
            <img
              src={employeeImg}
              alt="employeeImg"
              className={styles.imageWidth}
            />
          </div>
          <div className={styles.employeeDetailsStyles}>
            <p className={styles.employeeNameStyles}>{name}</p>
            <p className={styles.employeeDesignationStyles}>{designation}</p>
          </div>
        </div>
      </div>
      <div className={styles.selectEmployeeRightBlockStyles}>
        <InputWithDropDown
          placeholder="From"
          rightIcon={dropdownarrow}
          popupOpen={showCreateProjectPopups.start_date}
          selectedItem={startDate && moment(startDate).format('DD/MM/YYYY')}
          onClickInput={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              start_date: true,
            })
          }
          onClose={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              start_date: false,
            })
          }
          customWrapperStyles={styles.startDateInputStyles}
          customSubWrapperStyles={styles.inputSubContainerStyles}
          customPopUpContainerStyles={styles.startDatePopUpStyles}
          customPlaceHolderStyles={
            startDate && styles.startDatePlaceHolderStyles
          }
        >
          <Calender
            onSelectDate={(date) => {
              const isoDate = moment(date).format('YYYY-MM-DD');
              setStartDate(isoDate);
              onSelectDateChange(isoDate, endDate);
            }}
          />
        </InputWithDropDown>

        <InputWithDropDown
          placeholder="To"
          rightIcon={dropdownarrow}
          popupOpen={showCreateProjectPopups.end_date}
          selectedItem={endDate && moment(endDate).format('DD/MM/YYYY')}
          onClickInput={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              end_date: true,
            })
          }
          onClose={() =>
            setShowCreateProjectPopups({
              ...showCreateProjectPopups,
              end_date: false,
            })
          }
          customWrapperStyles={styles.startDateInputStyles}
          customSubWrapperStyles={styles.inputSubContainerStyles}
          customPopUpContainerStyles={styles.startDatePopUpStyles}
          customPlaceHolderStyles={endDate && styles.startDatePlaceHolderStyles}
        >
          <Calender
            onSelectDate={(date) => {
              const isoDate = moment(date).format('YYYY-MM-DD');
              setEndDate(isoDate);
              onSelectDateChange(startDate, isoDate);
            }}
            minDate={start_date}
          />
        </InputWithDropDown>
      </div>
    </div>
  );
};

export default SelectedEmployeeCard;
