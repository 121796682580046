import { user_Types } from 'constants/commondata/commondata';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useAuthContext } from 'hooks/useauthcontext';
import {
  checkInAndOutApi,
  statusCheckInOutApi,
} from 'networking/api/check-in-out';
import {
  getAllEmployeesApi,
  getRolesAndTechnologiesApi,
} from 'networking/api/employee';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export const EmployeeDataContext = createContext();

const EmployeeDataProvider = (props) => {
  const { user, userType } = useAuthContext();
  const { loader, setLoader } = useAppDataContext();
  const [employee, setEmployee] = useState();
  const [rolesData, setRolesData] = useState();
  const [skillsData, setSkillsData] = useState();
  const [checkInOutData, setCheckInOutData] = useState(null);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);

  useEffect(() => {
    if (userType === user_Types?.admin) {
      getAllEmployeesData();
    }
  }, [userType]);

  //get employee data
  const getAllEmployeesData = async () => {
    try {
      setLoader(true);
      let respone = await getAllEmployeesApi();
      const { type, data } = respone.data;
      if (type === 'success') {
        setLoader(false);
        setEmployee(data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error, 'error');
    }
  };

  //function to get their name
  const getName = () => {
    if (userType === user_Types.admin || user_Types.employee) {
      return user?.full_name;
    }
  };

  // function to get roles and techmologies
  const getTechData = async () => {
    try {
      const response = await getRolesAndTechnologiesApi(user?._id);
      if (response.data.type === 'success') {
        setRolesData(response.data.data.roles);
        setSkillsData(response.data.data.skills);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (user?._id) {
      getTechData(user?._id);
    }
  }, [user]);

  useEffect(() => {
    if (user?._id) {
      getStatusCheckInOut();
    }
  }, [user]);

  //getCheckInOut api data status if the employee checkin or not
  const getStatusCheckInOut = async () => {
    try {
      const respone = await statusCheckInOutApi(user?._id);
      if (respone.data.type === 'success') {
        setCheckInOutData(respone.data.message);
        if (respone.data.message === 'Checkedin') {
          setShowCheckOutModal(false);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  //handle check in & out :-
  const handleCheckInAndOut = async () => {
    try {
      setLoader(true);
      let data = { employee: user?._id };
      const response = await checkInAndOutApi(data);

      if (response.data.type === 'success') {
        setLoader(false);
        if (response.data.message === 'Checkedin') {
          setLoader(false);
          setCheckInOutData(response.data.message);
          getStatusCheckInOut();
        } else {
          setLoader(false);
          setCheckInOutData(null);
        }
      } else {
        setLoader(false);
        setCheckInOutData(null);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      employee,
      rolesData,
      skillsData,
      checkInOutData,
      showCheckOutModal,

      setEmployee,
      getName,
      setRolesData,
      setSkillsData,
      getAllEmployeesData,
      setCheckInOutData,
      handleCheckInAndOut,
      setShowCheckOutModal,
      getTechData,
    }),
    [
      //values
      employee,
      rolesData,
      skillsData,
      checkInOutData,
      showCheckOutModal,

      //functions
      setEmployee,
      getName,
      setRolesData,
      setSkillsData,
      getAllEmployeesData,
      setCheckInOutData,
      handleCheckInAndOut,
      getTechData,
    ]
  );

  return (
    <EmployeeDataContext.Provider value={memoizedValue}>
      {props.children}
    </EmployeeDataContext.Provider>
  );
};

export default EmployeeDataProvider;
