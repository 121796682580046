import axios from 'utils/axios';
import * as endpoints from 'networking/endpoints';

export const employeeTimeSheetApi = async (data) =>
  Promise.resolve(axios.post(endpoints.employeeTimeSheet, data));

export const getEmployeeTimeSheetsApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getEmployeeTimeSheet.replace('{id}', id))
  );

export const getTodayEmployeeTimeSheetsApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getTodayEmployeeTimeSheet.replace('{id}', id))
  );

export const updateTimeSheetApi = async (data) =>
  Promise.resolve(axios.post(endpoints.updateTimeSheet, data));

export const getAllTimeSheetsApi = async (id) =>
  Promise.resolve(axios.get(endpoints.getAllTimeSheets.replace('{id}', id)));
