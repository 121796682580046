import React from 'react';

const useTapOnEmployee = (isAdmin, setRoutName, navigate, setSideBar) => {
  const tapOnEmployeeSection = (data, projectsAssignedStatus, employeeId) => {
    if (isAdmin) {
      let dataa = ['Employees', projectsAssignedStatus, data];
      setRoutName(dataa);
      navigate('/admin-individualemployee', {
        state: {
          employeeName: data,
          employeeId: employeeId,
        },
      });
      setSideBar(false);
    }
  };

  return tapOnEmployeeSection;
};

export default useTapOnEmployee;
