import React, { useContext } from 'react';
import { ProjectsDataContext } from 'provider/projectsdataprovider';

export const useProjectsDataContext = () => {
  const context = useContext(ProjectsDataContext);
  if (!context) {
    throw new Error(
      'useProjectsContext context must be use inside ProjectDataProvider'
    );
  }
  return context;
};
