import React from 'react';
import { leftcurvearrow, rightcurvearrow } from 'resources/images/images';
import classNames from 'classnames';
import styles from './styles.module.css';

const SelectProjectCard = (props) => {
  const {
    selectedProject,
    onClickSelectProject,
    rightArrowImg,
    leftArrowImg,
    projectName,
    projectWorkedOn,
  } = props;
  return (
    <div
      className={
        selectedProject
          ? styles.activeSelectedProjectStyles
          : styles.selectProjectCardContainerStyles
      }
      onClick={() => onClickSelectProject()}
    >
      {rightArrowImg && (
        <div className={styles.leftArrowImgStyles}>
          <img
            src={rightcurvearrow}
            alt="rightcurvearrow"
            className={styles.imageWidth}
          />
        </div>
      )}
      {leftArrowImg && (
        <div className={styles.leftArrowImgStyles}>
          <img
            src={leftcurvearrow}
            alt="rightcurvearrow"
            className={styles.imageWidth}
          />
        </div>
      )}
      <div className={styles.rightProjectDetailsStyles}>
        <h5 className={styles.projectNameStyles}>{projectName}</h5>
        <p className={styles.projectNameTextStyles}>{projectWorkedOn}</p>
      </div>
    </div>
  );
};

export default SelectProjectCard;
