import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getAdminProjectListApi } from 'networking/api/adminregister';
import { useAuthContext } from 'hooks/useauthcontext';
import useUserViewsHook from 'hooks/userViewshook';
import { getEmployeeProjectListApi } from 'networking/api/employee';

export const ProjectsDataContext = createContext();

const ProjectsDataProvider = (props) => {
  // contexts
  const { user } = useAuthContext();
  const { isAdmin, isEmployee } = useUserViewsHook();

  //   states
  const [projectsData, setProjectsData] = useState();
  const [employeeProjectsData, setEmployeeProjectsData] = useState();
  const [allEmployeeProjects, setAllEmployeeProjects] = useState({
    onGoing: '',
    completed: '',
  });
  const [allProjectsData, setAllProjectsData] = useState({
    onGoing: '',
    completed: '',
  });

  // onload get ongoing and completed project data
  useEffect(() => {
    if (projectsData) {
      setAllProjectsData({
        ...allProjectsData,
        onGoing: projectsData.filter((item) => item.status === 'Ongoing'),
        completed: projectsData.filter((item) => item.status === 'Completed'),
      });
    }
  }, [projectsData]);

  // onload get ongoing and completed project data
  useEffect(() => {
    if (employeeProjectsData) {
      setAllEmployeeProjects({
        ...allEmployeeProjects,
        onGoing: employeeProjectsData.filter(
          (item) => item.status === 'Ongoing'
        ),
        completed: employeeProjectsData.filter(
          (item) => item.status === 'Completed'
        ),
      });
    }
  }, [employeeProjectsData]);

  //   call the get admin projects api call
  useEffect(() => {
    if (isAdmin) {
      getAdminProjects();
    }
  }, [isAdmin]);
  //   call the get employee projects api call
  useEffect(() => {
    if (isEmployee) {
      getEmployeeProjects();
    }
  }, [isEmployee]);

  // get admin  projects api
  const getAdminProjects = async () => {
    try {
      let response = await getAdminProjectListApi(user._id);
      if (response.data.type === 'success') {
        setProjectsData(response.data.data);
      } else {
        console.log('error', response.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getEmployeeProjects = async () => {
    try {
      let response = await getEmployeeProjectListApi(user._id);
      if (response.data.type === 'success') {
        setEmployeeProjectsData(response.data.data);
      } else {
        console.log('error', response.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      // values:
      projectsData,
      allProjectsData,
      employeeProjectsData,
      allEmployeeProjects,

      // functions:
      setProjectsData,
      setAllProjectsData,
      setEmployeeProjectsData,
      setAllEmployeeProjects,
      getAdminProjects,
    }),
    [
      // values:
      projectsData,
      allProjectsData,
      employeeProjectsData,
      allEmployeeProjects,

      // functions:
      setProjectsData,
      setAllProjectsData,
      setEmployeeProjectsData,
      setAllEmployeeProjects,
      getAdminProjects,
    ]
  );

  return (
    <ProjectsDataContext.Provider value={memoizedValue}>
      {props.children}
    </ProjectsDataContext.Provider>
  );
};

export default ProjectsDataProvider;
