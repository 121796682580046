import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useAuthContext } from 'hooks/useauthcontext';
import {
  getAllTimeSheetsApi,
  getEmployeeTimeSheetsApi,
  getTodayEmployeeTimeSheetsApi,
  updateTimeSheetApi,
} from 'networking/api/timesheets';

export const TimeSheetContext = createContext();

const TimeSheetsProvider = (props) => {
  // Context values
  const { user } = useAuthContext();
  const { setLoader } = useAppDataContext();

  // States
  const [employeeTimeSheet, setEmployeeTimeSheet] = useState();
  const [todayTimeSheet, setTodayTimeSheet] = useState();
  const [allTimesSheetsData, setAllTimeSheetsData] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [showToster, setShowToster] = useState(false);
  const [updatedTimeSheet, setUpdatedTimeSheet] = useState(todayTimeSheet);

  useEffect(() => {
    if (user?._id) {
      getAllTimeSheets(user?._id);
    }
  }, [user]);

  // useEffect
  useEffect(() => {
    if (user?._id) {
      getEmployeeTimeSheets();
      getTodayTimeSheetsData();
    }
  }, [user]);

  // API to get the employee timesheets
  const getEmployeeTimeSheets = async () => {
    setLoader(true);
    try {
      const response = await getEmployeeTimeSheetsApi(user._id);
      if (response.data.type === 'success') {
        setEmployeeTimeSheet(response.data.data.reverse());
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  //api to get today timesheets only
  const getTodayTimeSheetsData = async () => {
    setLoader(true);
    try {
      const response = await getTodayEmployeeTimeSheetsApi(user._id);
      if (response.data.type === 'success') {
        setTodayTimeSheet(response.data.data.reverse());
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  //get all time-sheets for admin
  const getAllTimeSheets = async (id) => {
    try {
      setLoader(true);
      const response = await getAllTimeSheetsApi(id);
      if (response.data.type === 'success') {
        setLoader(false);
        setAllTimeSheetsData(response.data.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // update timesheets api

  const handleEditClick = (item) => {
    setEditValue(item);
  };

  useEffect(() => {
    setUpdatedTimeSheet(todayTimeSheet);
  }, [todayTimeSheet]);

  const handleTimeSheetUpdate = async () => {
    try {
      setLoader(true);
      let timeSheetsData = {
        id: editValue?._id,
        admin: user?.admin,
        employee: user?._id,
        project: editValue?.project?._id,
        task: editValue?.task,
        description: editValue?.description,
        time: editValue?.time,
      };
      const response = await updateTimeSheetApi(timeSheetsData);
      if (response.data.type === 'success') {
        setLoader(false);
        let prevData = [...updatedTimeSheet];
        let index = prevData.findIndex(
          (item) => item._id === response.data.data._id
        );
        if (index !== -1) {
          prevData[index] = {
            ...prevData[index],
            task: response.data.data.task,
            description: response.data.data.description,
            time: response.data.data.time,
          };
        }
        setTodayTimeSheet(prevData);
        setEditValue(null);
      } else {
        setLoader(false);
        setShowToster(true);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      //values
      allTimesSheetsData,
      employeeTimeSheet,
      todayTimeSheet,
      editValue,
      updatedTimeSheet,
      showToster,

      //functions
      setEmployeeTimeSheet,
      getEmployeeTimeSheets,
      setTodayTimeSheet,
      getTodayTimeSheetsData,
      setAllTimeSheetsData,
      getAllTimeSheets,
      setEditValue,
      handleTimeSheetUpdate,
      setEditValue,
      setUpdatedTimeSheet,
      handleTimeSheetUpdate,
      handleEditClick,
      setShowToster,
    }),
    [
      employeeTimeSheet,
      todayTimeSheet,
      allTimesSheetsData,
      editValue,
      updatedTimeSheet,
      showToster,
      setEditValue,
      setUpdatedTimeSheet,
      handleTimeSheetUpdate,
      handleEditClick,
      setShowToster,
    ]
  );

  return (
    <TimeSheetContext.Provider value={memoizedValue}>
      {props.children}
    </TimeSheetContext.Provider>
  );
};

export default TimeSheetsProvider;
