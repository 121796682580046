import * as Yup from 'yup';

const fullNameValidationSchema = Yup.object().shape({
  full_name: Yup.string().required('Full Name is required'),
});

const companyNameValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
});

const phoneNumberValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Phone number must contain only digits'),
});

const otpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required')
    .matches(/^[0-9]+$/, 'OTP must contain only digits'),
});

const PassWordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .transform((value) => (value ? value.trim() : value)) // Trim leading and trailing spaces
    .required('Password required'),
});

const nameValidationSchema = /^[a-zA-Z ]+$/;
const mailValidationSchema = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export {
  fullNameValidationSchema,
  companyNameValidationSchema,
  emailValidationSchema,
  phoneNumberValidationSchema,
  otpValidationSchema,
  PassWordValidationSchema,
  nameValidationSchema,
  mailValidationSchema,
};
