import React, { useEffect, useRef, useState } from 'react';
import {
  avatar1,
  crossimg,
  deleteimg,
  editProfileIcon,
  markReadTickIcon,
  noNotificationIcon,
  notificationCrossIcon,
  notificationIcon,
  notificationimg,
  profileIcon,
  profilearrow,
  projectIcon,
  searchimg,
  serachIcon,
  userprofileimg,
  warningCircleIcon,
} from 'resources/images/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useappdatacontext';
import Popup from 'components/popup';
import NotificationCard from 'components/cards/notificationscard';
import { notificationData } from 'constants/commondata/commondata';
import SearchInput from 'components/searchinput';
import ProjectAndEmployeeCard from 'components/cards/projectsandemployeecard';
import Button from 'components/button';
import Input from 'components/input';
import { useAuthContext } from 'hooks/useauthcontext';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import PhoneNumberInput from 'components/phonenumberinput';
import LoadingSpinner from 'components/loadingspinner';
import useUserViewsHook from 'hooks/userViewshook';
import {
  adminProfileEditApi,
  uploadImageApi,
} from 'networking/api/adminregister';
import { employeeProfileEditApi } from 'networking/api/employee';
import styles from './styles.module.css';
import useTapOnProject from 'hooks/useTapOnProject';
import useTapOnEmployee from 'hooks/useTapOnEmployee';

const Header = () => {
  // routing:
  const navigate = useNavigate();
  const location = useLocation();

  //contexts
  const { routeName, loader, setLoader, activeTabs, setRoutName, setSideBar } =
    useAppDataContext();
  const { employee, getName } = useEmployeeDataContext();
  const {
    projectsData,
    allProjectsData,
    allEmployeeProjects,
    employeeProjectsData,
  } = useProjectsDataContext();
  const { user, setUser, logout } = useAuthContext();

  // hooks:
  const { isAdmin, isEmployee } = useUserViewsHook();
  const tapOnProject = useTapOnProject(
    isAdmin,
    isEmployee,
    setRoutName,
    navigate,
    setSideBar
  );
  const tapOnEmployee = useTapOnEmployee(
    isAdmin,
    setRoutName,
    navigate,
    setSideBar
  );

  // states
  const [notificationsData, setNotificationsData] = useState(notificationData);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showZeroNotificationPopup, setShowZeroNotificationPopup] =
    useState(false);
  const [showSearchDetailsModal, setShowSearchDetailsModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showProfileChangesPopup, setShowProfileChangesPopup] = useState(false);

  const [filteredProjectsData, setFilteredProjectsData] =
    useState(projectsData);
  const [filteredEmployeesData, setFilteredEmployeesData] = useState(employee);
  const [filteredEmployeeProjects, setFilteredEmployeeProjects] =
    useState(allEmployeeProjects);

  // edit profile state
  const [editProfileData, setEditProfileData] = useState({
    fullName: user?.full_name || '',
    email: user?.email || '',
    companyName: user?.company_name || '',
    phoneNumber: {
      country_code:
        (user?.phone_number?.country_code &&
          user?.phone_number?.country_code?.substring(1)) ||
        '',
      value: user?.phone_number?.value || '',
    },
    upLoadImage: user?.image || null,
  });

  const [uploadedImage, setUploadedImage] = useState();

  // refs:
  const popupRef = useRef();
  const zeroNotificationPopupRef = useRef();
  const searchPopupRef = useRef();

  // ref for fileUpload
  const fileInputRef = useRef();

  // useEffects:

  // after some time remove the showProfileChangesPopup
  useEffect(() => {
    setTimeout(() => {
      setShowProfileChangesPopup(false);
    }, 3000);
  }, [showProfileChangesPopup]);

  useEffect(() => {
    setFilteredProjectsData(projectsData);
  }, [projectsData]);

  useEffect(() => {
    setFilteredEmployeesData(employee);
  }, [employee]);

  useEffect(() => {
    if (allEmployeeProjects) {
      setFilteredEmployeeProjects([
        ...allEmployeeProjects.onGoing,
        ...allEmployeeProjects.completed,
      ]);
    }
  }, [allEmployeeProjects]);

  // function to close the Zero notification popup onclick the outside
  const clickOutsideToCloseZeroNotificationPopup = (event) => {
    if (
      showZeroNotificationPopup &&
      zeroNotificationPopupRef.current &&
      !zeroNotificationPopupRef.current.contains(event.target)
    ) {
      setShowZeroNotificationPopup(false);
    }
  };

  // function to handle search input change
  const handleSearchInputChange = (searchContent) => {
    // Filter projects data
    const filteredProjects = projectsData?.filter((project) =>
      JSON.stringify(project)
        .toLowerCase()
        .includes(searchContent.toLowerCase())
    );
    setFilteredProjectsData(filteredProjects);

    // Filter employees data
    const filteredEmployees = employee?.filter((employee) =>
      JSON.stringify(employee)
        .toLowerCase()
        .includes(searchContent.toLowerCase())
    );
    setFilteredEmployeesData(filteredEmployees);

    // Filter employee projects data
    const fileterEmployeeProjects = employeeProjectsData?.filter(
      (employeeProject) =>
        JSON.stringify(employeeProject)
          .toLowerCase()
          .includes(searchContent.toLowerCase())
    );

    setFilteredEmployeeProjects(fileterEmployeeProjects);
  };
  // function to handle to fileupload when upload an image text click

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // function to handle upload image
  const handleImageUploadSelection = async (e) => {
    const file = e.target.files[0];
    setEditProfileData((prevData) => ({
      ...prevData,
      upLoadImage: URL?.createObjectURL(file),
    }));
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append('image', file);
      const response = await uploadImageApi(formData);
      if (response.data.type === 'success') {
        setLoader(false);
        setUploadedImage(response.data.data);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  // function to handle  edit admin profile api
  const handleEditAdminProfile = async () => {
    try {
      setLoader(true);
      const data = {
        _id: user._id,
        account_type: user.account_type,
        full_name: editProfileData.fullName,
        email: user?.email,
        image: uploadedImage,
        phone_number: {
          country_code: editProfileData.phoneNumber.country_code,
          value: editProfileData.phoneNumber.value,
        },
        company_name: editProfileData.companyName,
      };

      const response = await adminProfileEditApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        setShowEditProfileModal(false);
        setShowProfileChangesPopup(true);
        setUser(response.data.data);
      } else {
        setLoader(false);
        setShowEditProfileModal(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  // function to handle  edit employee profile api
  const handleEditEmployeeProfile = async () => {
    try {
      setLoader(true);
      const data = {
        _id: user._id,
        account_type: user.account_type,
        full_name: editProfileData.fullName,
        email: user?.email,
        // upLoadImage: editProfileData.upLoadImage,
        image: uploadedImage,
        phone_number: {
          country_code: editProfileData.phoneNumber.country_code,
          value: editProfileData.phoneNumber.value,
        },
      };

      const response = await employeeProfileEditApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        setUser(response.data.data);
        setShowEditProfileModal(false);
        setShowProfileChangesPopup(true);
        setUser(response.data.data);
      } else {
        setLoader(false);
        setShowEditProfileModal(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  // function to delete the upload image
  const handleDeleteUpLoadImage = async () => {
    try {
      setEditProfileData((prevData) => ({
        ...prevData,
        upLoadImage: '',
      }));
    } catch (error) {
      console.log('error', error);
    }
  };

  const renderHeaderLeftSection = () => {
    return (
      <div
        className={styles.userProfileContainerStyles}
        onClick={() => setShowProfileModal(!showProfileModal)}
      >
        <div className={styles.userImgBlockStyles}>
          <img
            src={user?.image ? user?.image : avatar1}
            alt="userProfileImg"
            className={styles.userProfileImgStyles}
          />
        </div>
        <div className={styles.userNameAndArrowStyles}>
          <p className={styles.userNameTextStyles}>{getName()}</p>
          <div className={styles.profileArrowBlockStyles}>
            <img
              src={profilearrow}
              alt="profilearrow"
              className={styles.imageWidth}
            />
          </div>
        </div>
        {renderProfileSection()}
      </div>
    );
  };

  const renderProfileSection = () => {
    return (
      <Popup
        show={showProfileModal}
        showOverlay={true}
        popupRef={popupRef}
        customPopUpStyles={styles.profilePopupStyles}
      >
        {renderProfileDetailsSection()}
        {renderBottomProfileActionBtnsSection()}
      </Popup>
    );
  };

  const renderProfileDetailsSection = () => {
    const onGoingProjectData = isAdmin
      ? allProjectsData.onGoing
      : isEmployee && allEmployeeProjects.onGoing;

    const completedProjectData = isAdmin
      ? allProjectsData.completed
      : isEmployee && allEmployeeProjects.completed;

    const employeeRole = user?.roles?.map((item) => item?.name).join(',');
    const employeeExperiance = user?.roles?.map((item) => item?.experience);
    const employeeExperiancedIn = employeeExperiance > 18 ? 'Senior' : 'Junior';

    return (
      <div className={styles.topProfileDetailsBlockStyles}>
        <div className={styles.profileDetailsBlockStyles}>
          <div className={styles.leftProfileDetailsStyles}>
            <div className={styles.profileImgBlockStyles}>
              <img
                src={avatar1}
                alt="profileImg"
                className={styles.imageStyles}
              />
            </div>
            <div className={styles.profileNameAndDesignationStyles}>
              <h5 className={styles.profileNameTextStyles}>{getName()}</h5>
              <p className={styles.profileDesignationStyles}>
                {isAdmin
                  ? 'Admin'
                  : isEmployee && `${employeeExperiancedIn} ${employeeRole}`}
              </p>
            </div>
          </div>
          <div
            className={styles.rightCloseImgBlockStyles}
            onClick={() => setShowProfileModal(false)}
          >
            <img src={crossimg} alt="crossImg" className={styles.imageStyles} />
          </div>
        </div>
        <div className={styles.profileSubDetailsBlockStyles}>
          <p className={styles.profleSubDetailsHeadingStyles}>
            <span className={styles.profileSubDetailDescStyles}>
              Account type: &nbsp;
            </span>
            {user?.account_type}
          </p>
          <p className={styles.profleSubDetailsHeadingStyles}>
            <span className={styles.profileSubDetailDescStyles}>
              Assigned: &nbsp;
            </span>
            {onGoingProjectData?.length &&
              (onGoingProjectData?.length).toString().padStart(2, '0')}
          </p>
          <p className={styles.profleSubDetailsHeadingStyles}>
            <span className={styles.profileSubDetailDescStyles}>
              Completed: &nbsp;
            </span>
            {completedProjectData?.length &&
              (completedProjectData?.length).toString().padStart(2, '0')}
          </p>
        </div>
      </div>
    );
  };

  const renderBottomProfileActionBtnsSection = () => {
    return (
      <div className={styles.bottomProfileActionBtnsBlockStyles}>
        <Button
          title="Edit profile"
          btnStyle={styles.editProfileBtnStyles}
          onClick={() => {
            setShowProfileModal(false);
            setShowEditProfileModal(true);
          }}
        />
        <Button
          title="Logout"
          btnStyle={styles.logoutBtnStyles}
          onClick={() => logout()}
        />
      </div>
    );
  };

  const renderEditProfileSection = () => {
    return (
      <Popup
        show={showEditProfileModal}
        showOverlay={true}
        popupRef={popupRef}
        customPopUpStyles={styles.editProfilePopupStyles}
      >
        {renderTopEditProfileHeaderSection()}
        {renderMiddleEditProfileInputSection()}
        {renderBottomEditProfileBtnSection()}
      </Popup>
    );
  };

  const renderTopEditProfileHeaderSection = () => {
    return <h2 className={styles.editProfileHeadingStyles}>Edit profile</h2>;
  };

  const renderMiddleEditProfileInputSection = () => {
    return (
      <div className={styles.editProfileInputsBlockStyles}>
        <div className={styles.editProfileChooseImgBlockStyles}>
          {/* edit profile image section start */}
          <div className={styles.leftProfileImgStyles}>
            {editProfileData.upLoadImage ? (
              <img
                src={
                  editProfileData.upLoadImage
                    ? editProfileData.upLoadImage
                    : profileIcon
                }
                alt="profileImg"
                className={
                  editProfileData.upLoadImage
                    ? styles.upLoadImgStyles
                    : styles.imageStyles
                }
              />
            ) : (
              uploadedImage && (
                <img
                  src={uploadedImage}
                  alt="profileImg"
                  className={
                    uploadedImage ? styles.upLoadImgStyles : styles.imageStyles
                  }
                />
              )
            )}
          </div>
          {/* edit profile image section end */}

          <div className={styles.rightChooseImageOptionStyles}>
            <p className={styles.topImageUploadInfoTextStyles}>
              Minimum 256×256px .PNG or .JPEG
            </p>
            <div className={styles.fileUploadAndDeleteIconBlockStyles}>
              <Button
                title={'Choose image'}
                btnStyle={styles.uploadImgBtnStyles}
                onClick={() => handleUploadImageClick()}
              />
              <input
                type="file"
                accept="image/jpeg, image/jpg, image/png"
                placeholder="Choose image"
                ref={fileInputRef}
                onChange={handleImageUploadSelection}
                className={styles.uploadInputStyles}
              />
              {editProfileData.upLoadImage && (
                <div
                  className={styles.deleteImgBlockStyles}
                  onClick={() => handleDeleteUpLoadImage()}
                >
                  <div className={styles.deleteImgStyles}>
                    <img
                      src={deleteimg}
                      alt="deleteImg"
                      className={styles.imageStyles}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Input
          type="text"
          name="fullName"
          value={editProfileData.fullName}
          onChange={(e) => {
            setEditProfileData({
              ...editProfileData,
              fullName: e.target.value,
            });
          }}
          lableText="Full name"
          placeholder={'Enter your full name'}
          lableTextStyle={styles.editProfileLabelStyles}
          customInputStyle={styles.editProfileInputStyles}
        />
        {isAdmin && (
          <Input
            type="text"
            name="companyName"
            value={editProfileData.companyName}
            onChange={(e) => {
              setEditProfileData({
                ...editProfileData,
                companyName: e.target.value,
              });
            }}
            lableText="Company Name"
            placeholder={'Enter Company Name'}
            lableTextStyle={styles.editProfileLabelStyles}
            customInputStyle={styles.editProfileInputStyles}
          />
        )}
        <PhoneNumberInput
          labeText="Phone no*"
          phoneNumber={editProfileData.phoneNumber}
          setPhoneNumber={(newPhoneNumber) => {
            setEditProfileData({
              ...editProfileData,
              phoneNumber: newPhoneNumber,
            });
          }}
          customNumberInputContainerStyles={styles.phoneNumberInputStyles}
        />
        <div className={styles.editEmailInputBlockStyles}>
          <Input
            type="email"
            name="email"
            value={editProfileData.email}
            onChange={(e) => {
              setEditProfileData({ ...editProfileData, email: e.target.value });
            }}
            disabled={true}
            lableText="Email"
            placeholder={editProfileData?.email}
            lableTextStyle={styles.editProfileLabelStyles}
            customInputStyle={styles.editProfileInputStyles}
          />
          <div className={styles.emaiEditInfoStyles}>
            <div className={styles.warningImgBlockStyles}>
              <img
                src={warningCircleIcon}
                alt="warningCircleIcon"
                className={styles.imageStyles}
              />
            </div>
            <p className={styles.editEmailWarningTextStyles}>
              Employee accounts cannot edit the email ID
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderBottomEditProfileBtnSection = () => {
    return (
      <div className={styles.editProfileBottomBtnsBlockStyles}>
        <Button
          title="Cancel"
          btnStyle={styles.cancelBtnStyles}
          onClick={() => {
            setEditProfileData({
              fullName: user.full_name,
              email: user.email,
              companyName: user.company_name,
              phoneNumber: {
                country_code:
                  user.phone_number.country_code &&
                  user.phone_number.country_code.substring(1),
                value: user.phone_number.value,
              },
              upLoadImage: '',
            });
            setShowEditProfileModal(false);
          }}
        />
        <Button
          title="Save changes"
          disabled={
            editProfileData.fullName ||
            editProfileData.email ||
            editProfileData.upLoadImage
              ? false
              : true
          }
          btnStyle={
            editProfileData.fullName ||
            editProfileData.email ||
            editProfileData.upLoadImage
              ? styles.activeSaveChangeBtnStyles
              : styles.saveChangesBtnStyles
          }
          onClick={() => {
            isAdmin
              ? handleEditAdminProfile()
              : isEmployee && handleEditEmployeeProfile();
          }}
        />
      </div>
    );
  };

  const renderProfileChangesSavedPopupSection = () => {
    return (
      <Popup
        show={showProfileChangesPopup}
        showOverlay={true}
        popupRef={popupRef}
        customOverLayStyles={styles.profileOverLayStyles}
        customPopUpStyles={styles.profileChangesPopupStyles}
      >
        <p className={styles.profileChangesTextStyles}>Profile changes saved</p>
      </Popup>
    );
  };

  const renderSearchAndNotificationRightSection = () => {
    return (
      <div className={styles.searchAndNotficationStyles}>
        <div
          className={styles.searchImgBlockStyles}
          onClick={() => setShowSearchDetailsModal(true)}
        >
          <img src={searchimg} alt="serachIcon" className={styles.imageWidth} />
        </div>
        <div
          className={styles.notificationImgBlockStyles}
          onClick={() => {
            notificationsData.length > 0
              ? setShowNotificationModal(true)
              : setShowZeroNotificationPopup(true);
          }}
        >
          <img
            src={
              notificationsData.length > 0
                ? notificationimg
                : noNotificationIcon
            }
            alt="notificationIcon"
            className={styles.imageWidth}
          />
        </div>
        {renderNotificationSection()}
        {renderSearchAndPeoplesSection()}
      </div>
    );
  };

  const renderNotificationSection = () => {
    return (
      <div>
        {notificationsData.length > 0 ? (
          <Popup
            show={showNotificationModal}
            onClose={() => setShowNotificationModal(false)}
            showOverlay={true}
            popupRef={popupRef}
            customPopUpStyles={styles.notificationPopupStyles}
          >
            <div className={styles.topNotificationHeaderBlockStyles}>
              <h5 className={styles.notificationHeadingStyles}>
                Notifications
              </h5>
              <div
                className={styles.crossImageStyles}
                onClick={() => setShowNotificationModal(false)}
              >
                <img
                  src={notificationCrossIcon}
                  alt="notificationCrossIcon"
                  className={styles.imageStyles}
                />
              </div>
            </div>
            <div className={styles.middlenotificationsBlockStyles}>
              {notificationsData &&
                notificationsData.map((item, index) => {
                  return (
                    <NotificationCard
                      key={index}
                      image={item.image}
                      message={item.message}
                      time={item.time}
                      index={index}
                    />
                  );
                })}
            </div>
            <div className={styles.bottomMarkAsReadBlockStyles}>
              <div className={styles.markReadImgStyles}>
                <img
                  src={markReadTickIcon}
                  alt="markReadTickIcon"
                  className={styles.imageStyle}
                />
              </div>
              <p className={styles.markAllAsReadTextStyles}>Mark all as read</p>
            </div>
          </Popup>
        ) : (
          <Popup
            show={showZeroNotificationPopup}
            onClose={() => setShowZeroNotificationPopup(false)}
            popupRef={zeroNotificationPopupRef}
            handleClickOutside={clickOutsideToCloseZeroNotificationPopup}
            customPopUpStyles={styles.zeroNotificationPopupStyles}
          >
            <p className={styles.noNotificationsTextStyles}>
              No notifications at the moment.
            </p>
          </Popup>
        )}
      </div>
    );
  };

  const renderSearchAndPeoplesSection = () => {
    return (
      <Popup
        show={showSearchDetailsModal}
        showOverlay={true}
        popupRef={searchPopupRef}
        customPopUpStyles={styles.searchPopupStyles}
      >
        <SearchInput
          placeholder="Search for people, projects, and more..."
          onCloseImgClick={() => {
            setShowSearchDetailsModal(false);
            setFilteredProjectsData();
            setFilteredEmployeesData();
          }}
          onSearchInputChange={handleSearchInputChange}
          customContainerStyles={styles.searchInputContainerStyles}
          customInputStyles={styles.searchInputStyles}
          onCloseImg={true}
        />
        {filteredProjectsData && isAdmin && renderProjectDataSection()}
        {filteredEmployeesData && isAdmin && renderEmployeeDataSection()}
        {isEmployee && renderEmployeeProjectDataSection()}
      </Popup>
    );
  };

  const renderProjectDataSection = () => {
    return (
      <div className={styles.projectContainerStyles}>
        <div className={styles.projectsHeadingBlockStyles}>
          <h5 className={styles.projectsHeadingStyles}>Projects</h5>
          <p className={styles.projectNumbersTextStyles}>
            {filteredProjectsData.length.toString().padStart(2, '0')}
          </p>
        </div>
        <div className={styles.projectsDataBlockStyles}>
          {filteredProjectsData.length > 0 ? (
            filteredProjectsData.map((item, index) => {
              return (
                <ProjectAndEmployeeCard
                  key={index}
                  projectImage={projectIcon}
                  altText={item.title}
                  name={item.title}
                  description={
                    item.category_tags &&
                    item.category_tags.map((item) => item).join(', ')
                  }
                  status={item.status}
                  onClick={() => tapOnProject(item.title, item._id)}
                />
              );
            })
          ) : (
            <p className={styles.noProjectTextStyles}>No projects found</p>
          )}
        </div>
      </div>
    );
  };

  const renderEmployeeDataSection = () => {
    return (
      <div className={styles.employeeContainerStyles}>
        <div className={styles.employeeHeadingBlockStyles}>
          <h5 className={styles.employeeHeadingStyles}>Employees</h5>
          <p className={styles.employeeNumberTextStyle}>
            {filteredEmployeesData.length.toString().padStart(2, '0')}
          </p>
        </div>
        <div className={styles.employeeDataBlockStyles}>
          {filteredEmployeesData.length > 0 ? (
            filteredEmployeesData.map((item, index) => {
              return (
                <ProjectAndEmployeeCard
                  key={index}
                  employeeImage={item.image ? item.image : avatar1}
                  altText={item.full_name}
                  name={item.full_name}
                  description={
                    item.roles &&
                    item.roles
                      .map((role) => {
                        const roleText =
                          role.experience > 24 ? 'Senior' : 'Junior';
                        return `${roleText} ${role.name}`;
                      })
                      .join(', ')
                  }
                  assignedStatus={`Assigned :${String(
                    item.projects_assigned
                  ).padStart(2, '0')}`}
                  onClick={() => tapOnEmployee(item.full_name, item._id)}
                />
              );
            })
          ) : (
            <p className={styles.noEmployeeTextStyles}>No employees found</p>
          )}
        </div>
      </div>
    );
  };

  const renderEmployeeProjectDataSection = () => {
    return (
      <div className={styles.projectContainerStyles}>
        <div className={styles.projectsHeadingBlockStyles}>
          <h5 className={styles.projectsHeadingStyles}>Projects</h5>
          <p className={styles.projectNumbersTextStyles}>
            {filteredEmployeeProjects
              ? filteredEmployeeProjects?.length?.toString()?.padStart(2, '0')
              : '00'}
          </p>
        </div>
        <div className={styles.projectsDataBlockStyles}>
          {filteredEmployeeProjects?.length > 0 ? (
            filteredEmployeeProjects?.map((item, index) => {
              return (
                <ProjectAndEmployeeCard
                  key={index}
                  projectImage={projectIcon}
                  altText={item.title}
                  name={item.title}
                  description={
                    item.category_tags &&
                    item.category_tags.map((item) => item).join(', ')
                  }
                  status={item.status}
                  onClick={() => tapOnProject(item.title, item._id)}
                />
              );
            })
          ) : (
            <p className={styles.noProjectTextStyles}>No projects found</p>
          )}
        </div>
      </div>
    );
  };

  const renderIndividualProject = () => {
    return (
      <div className={styles.pathStyles}>
        {routeName &&
          routeName?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <p
                  className={
                    routeName.length - 1 === index
                      ? styles.pathActiveTitleStyles
                      : styles.pathTitleStyles
                  }
                  onClick={() => navigate(-1)}
                >
                  {index === 2
                    ? (activeTabs?.ongoing && activeTabs.ongoing?.title) ||
                      (activeTabs?.completed && activeTabs?.completed?.title) ||
                      (activeTabs?.assigned &&
                        activeTabs?.assigned?.full_name) ||
                      (activeTabs?.unAssigned &&
                        activeTabs?.unAssigned?.full_name) ||
                      item
                    : item}
                </p>
                <p
                  className={
                    routeName.length - 1 === index
                      ? styles.slashEmptyStyle
                      : styles.slashStyles
                  }
                >
                  /
                </p>
              </React.Fragment>
            );
          })}
      </div>
    );
  };

  return (
    <>
      {loader ? (
        <LoadingSpinner
          customContainerSpinnerStyles={styles.spinnerContainerStyles}
          customLoaderStyles={styles.loaderStyles}
        />
      ) : (
        <div className={styles.headerMainContainerStyles}>
          {['/admin-individualProject', '/admin-individualemployee']?.includes(
            location?.pathname
          ) ? (
            <>{renderIndividualProject()}</>
          ) : (
            <>
              {renderHeaderLeftSection()}
              {renderSearchAndNotificationRightSection()}
            </>
          )}

          {/* edit profile section */}
          {renderEditProfileSection()}

          {/* profile changes saved profile section */}
          {renderProfileChangesSavedPopupSection()}
        </div>
      )}
    </>
  );
};

export default Header;
