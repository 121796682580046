import {
  selectAssginedProjectData,
  selectUnAssignedProjectData,
  timeData,
} from 'constants/commondata/commondata';
import React, { createContext, useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AppDataContext = createContext({
  setRoutName: () => {
    return null;
  },
});

const AppDataProvider = (props) => {
  const location = useLocation();
  const [popUpOpened, setPopUpOpened] = useState(false);
  const [routeName, setRoutName] = useState([]);
  const [sideBar, setSideBar] = useState(true);
  const [loader, setLoader] = useState(false);

  // add new employee states:
  const [addNewEmployeeModal, setAddNewEmployeeModal] = useState(false);

  // time sheet states:
  const [taskTitle, setTaskTitle] = useState('');
  const [describeTask, setDescribeTask] = useState('');
  const [selectedProject, setSelectedProject] = useState({
    title: '',
  });
  const [selectTime, setSelectTime] = useState('');
  const [selectAssginedProjectsData, setSelectAssginedProjectsData] = useState(
    selectAssginedProjectData
  );
  const [filterAssignProjectsData, setFilterAssignProjectsData] = useState([]);
  const [selectUnAssignedProjectsData, setSelectUnAssignedProjectsData] =
    useState(selectUnAssignedProjectData);
  const [filterUnAssignedProjectsData, setFilterUnAssignedProjectData] =
    useState([]);
  const [timesData, setTimesData] = useState(timeData);
  const [filterTimesData, setFilterTimesData] = useState([]);
  const [submitedTimeSheetData, setSubmittedTimeSheetData] = useState([]);

  // active tab title
  const [activeTabs, setActiveTabs] = useState({
    ongoing: '',
    completed: '',
    assigned: '',
    unAssigned: '',
  });

  useEffect(() => {
    initialize();
  }, [location.pathname]);

  const initialize = async () => {
    let routename = localStorage.getItem('routeName');

    if (location.pathname === '/admin-individualProject') {
      if (routename) {
        setRoutName(JSON.parse(routename));
      }
    } else if (location.pathname === '/admin-individualemployee') {
      if (routename) {
        setRoutName(JSON.parse(routename));
      }
    } else if (location.pathname === '/pastrecords') {
      if (routename) {
        setRoutName(JSON.parse(routename));
      }
    } else if (
      location.pathname !== '/admin-individualemployee' ||
      location.pathname !== '/admin-individualProject' ||
      location.pathname !== '/pastrecords'
    ) {
      setRoutName();
      localStorage.removeItem('routeName');
    }
  };

  const memoizedValue = useMemo(
    () => ({
      popUpOpened,
      setPopUpOpened,
      routeName,

      setRoutName: (data) => {
        localStorage.setItem('routeName', JSON.stringify(data));
        setRoutName(data);
      },
      sideBar,
      setSideBar,
      loader,
      setLoader,

      // values
      taskTitle,
      describeTask,
      selectedProject,
      selectTime,
      selectAssginedProjectsData,
      selectUnAssignedProjectsData,
      filterAssignProjectsData,
      filterUnAssignedProjectsData,
      timesData,
      filterTimesData,
      submitedTimeSheetData,
      activeTabs,
      addNewEmployeeModal,
      // functions
      setTaskTitle,
      setDescribeTask,
      setSelectedProject,
      setSelectTime,
      setFilterAssignProjectsData,
      setFilterUnAssignedProjectData,
      setFilterTimesData,
      setSubmittedTimeSheetData,
      setActiveTabs,
      setAddNewEmployeeModal,
    }),
    [
      popUpOpened,
      setPopUpOpened,
      routeName,
      setRoutName,
      sideBar,
      setSideBar,
      loader,
      setLoader,

      // values
      taskTitle,
      describeTask,
      selectedProject,
      selectTime,
      selectAssginedProjectsData,
      selectUnAssignedProjectsData,
      filterAssignProjectsData,
      filterUnAssignedProjectsData,
      timesData,
      filterTimesData,
      submitedTimeSheetData,
      activeTabs,
      addNewEmployeeModal,

      // functions
      setTaskTitle,
      setDescribeTask,
      setSelectedProject,
      setSelectTime,
      setFilterAssignProjectsData,
      setFilterUnAssignedProjectData,
      setFilterTimesData,
      setSubmittedTimeSheetData,
      setActiveTabs,
      setAddNewEmployeeModal,
    ][
      (popUpOpened, setPopUpOpened, routeName, setRoutName, sideBar, setSideBar)
    ]
  );

  return (
    <AppDataContext.Provider value={memoizedValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };
