import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from 'hooks/useauthcontext';
import { user_Types } from 'constants/commondata/commondata';
import LoadingSpinner from 'components/loadingspinner';

const GuestGuard = (props) => {
  const { userType: guardUserType, children } = props;
  const { isLoggedIn, isInitialized, userType, setAuthError } =
    useAuthContext();
  useEffect(() => {
    if (isLoggedIn) {
      if (!guardUserType?.includes(userType)) {
        setAuthError('Please logout from other user type');
      }
    }
  }, [isLoggedIn, guardUserType, userType, setAuthError]);
  let dashBoardRoute = '/dashboard';
  if (
    guardUserType.includes(user_Types.admin) ||
    guardUserType.includes(user_Types.employee)
  ) {
    dashBoardRoute = '/dashboard';
  }

  if (!isInitialized) {
    return <LoadingSpinner />;
  }
  if (isLoggedIn) {
    if (
      guardUserType.includes(user_Types.admin) ||
      guardUserType.includes(user_Types.employee)
    ) {
      return <Navigate to={dashBoardRoute} />;
    }
  }
  return <>{children}</>;
};

GuestGuard.propTypes = {
  userType: PropTypes.arrayOf(
    PropTypes.oneOf([user_Types.admin, user_Types.employee])
  ),
  children: PropTypes.node,
};

export default GuestGuard;
