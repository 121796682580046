import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/button';
import { eclocklogo, workingimg } from 'resources/images/images';
import Input from 'components/input';
import { useFormik } from 'formik';
import PhoneNumberInput from 'components/phonenumberinput';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuthContext } from 'hooks/useauthcontext';
import { registerApi, registerOtpApi } from 'networking/api/adminregister';
import Popup from 'components/popup';
import { useAppDataContext } from 'hooks/useappdatacontext';
import LoadingSpinner from 'components/loadingspinner';
import styles from './styles.module.css';

const AdminRegister = () => {
  // contexts:
  const { registerAdmin } = useAuthContext();
  const { loader, setLoader } = useAppDataContext();

  //ref
  const popUpRef = useRef();

  // routing
  const navigate = useNavigate();

  // states
  const [currentStep, setCurrentStep] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [schema, setSchema] = useState({
    full_name: Yup.string().required('FullName name is required'),
    company_name: Yup.string().required('Company name  is required'),
  });

  useEffect(() => {
    if (currentStep === 1) {
      setSchema({
        full_name: Yup.string().required('FullName name is required'),
        company_name: Yup.string().required('Company name  is required'),
      });
    }
    if (currentStep === 2) {
      setSchema({
        ...schema,
        email: Yup.string()
          .email('Enter a valid email address')
          .required('Email is required'),
        phone_number: Yup.object().shape({
          value: Yup.string()
            .required('Phone number is required')
            .matches(/^[0-9]+$/, 'Phone number must contain only digits'),
        }),
        otp: undefined,
      });
    }
    if (currentStep === 3) {
      setSchema({
        ...schema,
        otp: Yup.string().required('otp required'),
      });
    }
  }, [currentStep]);

  // registration schema
  const registrationSchema = Yup.object().shape(schema);

  // validations while changing the steps
  const handleNextClick = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
      formik.setTouched({});
    } else if (currentStep === 2) {
      sendRegistrationOtp(formik.values);
      formik.setTouched({});
    } else if (currentStep === 3) {
      handleAdminRegister(formik.values);
    }
  };

  // onclick the scroll button move to (currentstep[1-2-3])
  const handleStepClick = (step) => {
    formik.setSubmitting(false);
    if (
      (step === 1 &&
        formik.values.full_name !== '' &&
        formik.values.company_name !== '') ||
      (step === 2 &&
        formik.values.email !== '' &&
        formik.values.phone_number?.value !== '') ||
      (step === 3 && formik.values.otp !== '')
    ) {
      setCurrentStep(step);
      formik.setSubmitting(true);
    }
  };

  // reset the form
  const handleResetForm = () => {
    formik.resetForm();
  };

  const sendRegistrationOtp = async (values) => {
    try {
      setLoader(true);
      let updateData = { ...values, otp: undefined };
      let response = await registerOtpApi(updateData);
      if (response.data.type === 'success') {
        setLoader(false);
        setCurrentStep(3);
      } else {
        setLoader(false);
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('send RegistrationOtp catch', error.response.data);
    }
  };

  // handle admin register
  const handleAdminRegister = async (values) => {
    try {
      setLoader(true);
      if (currentStep === 3) {
        const response = await registerApi(values);

        if (response.data.type === 'success') {
          setLoader(false);
          setShowPopUp(true);
          handleResetForm();
        } else {
          setLoader(false);
          setErrorMsg(response.data.message);
        }
      }
    } catch (error) {
      setLoader(false);
      console.log('Send AdminRegister error', error.response.data);
    }
  };

  // formik validations

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: '',
      company_name: '',
      email: '',
      phone_number: {
        country_code: '46',
        value: '',
      },
      otp: '',
      account_type: 'Admin',
    },
    validationSchema: registrationSchema,
    onSubmit: handleNextClick,
  });

  const renderEclockHeading = () => {
    return (
      <div className={styles.eclockLogoImgStyles}>
        <img src={eclocklogo} alt="eclocklogo" className={styles.imageWidth} />
      </div>
    );
  };
  const renderAdminRegistration = () => {
    return (
      <div className={styles.adminRegistartionContainerStyles}>
        {renderAdminRegistrationLeftSection()}
        {renderAdminRegistrationRightSection()}
      </div>
    );
  };

  const renderAdminRegistrationLeftSection = () => {
    return (
      <div className={styles.adminRegistrationLeftContainerStyles}>
        <img src={workingimg} alt="" className={styles.imageWidth} />
      </div>
    );
  };

  const renderAdminRegistrationRightSection = () => {
    return (
      <div className={styles.adminRegistrationRightContainerStyles}>
        <div className={styles.scrollButtonSectionStyles}>
          {[1, 2, 3].map((step) => (
            <span
              key={step}
              onClick={() => handleStepClick(step)}
              className={
                currentStep === step
                  ? styles.activeScrollBtnStyles
                  : styles.scrollBtnStyles
              }
            ></span>
          ))}
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className={styles.adminRegistrationFormBlockStyles}
        >
          {renderCreateAccountTopSection()}
          {currentStep === 1 && renderFullNameAndCompanyMiddleSection()}
          {currentStep === 2 && renderEmailAndPhoneNumberSection()}
          {currentStep === 3 && renderOtpSection()}
          {renderNextSubmissionBottomSection()}
        </form>
      </div>
    );
  };

  const renderCreateAccountTopSection = () => {
    return (
      <div className={styles.createAccountTopSectionStyles}>
        <h5>
          {currentStep === 1 || currentStep === 2
            ? 'Create an account'
            : `We sent an OTP to ${formik.values.email}`}
        </h5>
      </div>
    );
  };

  const renderFullNameAndCompanyMiddleSection = () => {
    return (
      <div className={styles.FullNameAndCompanyMiddleSectionStyles}>
        <Input
          name="full_name"
          lableText={'Full Name*'}
          lableTextStyle={styles.fullNameLabelStyles}
          placeholder={'Enter your Full Name'}
          value={formik.values.full_name}
          onChange={formik.handleChange}
          onFocus={() => {
            formik.setFieldError('full_name', '');
          }}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.full_name && formik.errors.full_name
              ? formik.errors.full_name
              : ''
          }
          customInputStyle={styles.fullNameInputStyles}
        />

        <Input
          name="company_name"
          lableText={'CompanyName*'}
          lableTextStyle={styles.companyNameLabelStyle}
          placeholder={'Enter Company Name'}
          value={formik.values.company_name}
          onChange={formik.handleChange}
          onFocus={() => {
            formik.setFieldError('company_name', '');
          }}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.company_name && formik.errors.company_name
              ? formik.errors.company_name
              : ''
          }
          customInputStyle={styles.companyNameInputStyles}
        />
      </div>
    );
  };
  const renderEmailAndPhoneNumberSection = () => {
    return (
      <div className={styles.emailAndPhoneNumberStyles}>
        <Input
          name="email"
          lableText={'Email*'}
          lableTextStyle={styles.fullNameLabelStyles}
          placeholder={'Enter your email ID'}
          value={formik.values.email}
          onChange={formik.handleChange}
          onFocus={() => {
            formik.setFieldError('email', '');
            setErrorMsg('');
          }}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''
          }
          customInputStyle={styles.emailInputStyles}
        />

        <PhoneNumberInput
          labeText="Phone no*"
          phoneNumber={formik.values.phone_number}
          setPhoneNumber={(value) =>
            formik.setFieldValue('phone_number', value)
          }
          customNumberInputContainerStyles={styles.phoneNumberInputStyles}
          onFocus={() => {
            formik.setFieldError('phone_number', '');
          }}
          hasError={
            formik.touched.phone_number?.value &&
            formik.errors.phone_number?.value
              ? formik.errors.phone_number?.value
              : ''
          }
        />
        {errorMsg && <p className={styles.errorMsgStyles}>{errorMsg}</p>}
      </div>
    );
  };

  const renderOtpSection = () => {
    return (
      <div className={styles.otpContainer}>
        <Input
          name="otp"
          lableText={'Enter OTP*'}
          lableTextStyle={styles.companyNameLabelStyle}
          placeholder={'8-digit OTP here'}
          value={formik.values.otp}
          // onChange={formik.handleChange}
          onChange={(e) => {
            formik.setFieldValue('otp', e.target.value.trim());
            setErrorMsg('');
          }}
          onFocus={() => {
            formik.setFieldError('otp', '');
            setErrorMsg('');
          }}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.otp && formik.errors.otp ? formik.errors.otp : ''
          }
          customInputStyle={styles.otpInputStyles}
        />
        {errorMsg && <p className={styles.errorMsgStyles}>{errorMsg}</p>}
        <div className={styles.resendOtpAndEditEmailSection}>
          <p
            className={styles.resendOtpTextStyles}
            onClick={() => sendRegistrationOtp(formik.values)}
          >
            Resend OTP
          </p>
          <p className={styles.orTextStyles}>or</p>
          <p
            className={styles.editEmailTextStyle}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            edit email
          </p>
        </div>
      </div>
    );
  };

  const renderNextSubmissionBottomSection = () => {
    return (
      <div className={styles.nextSubmissionBottomSectionStyles}>
        {renderNextSubmitBtnSection()}
        {renderHaveAccountLoginTextSection()}
      </div>
    );
  };

  const renderNextSubmitBtnSection = () => {
    return (
      <Button
        type="submit"
        title={
          currentStep === 1
            ? 'Next'
            : currentStep === 2
            ? 'Send an OTP'
            : currentStep === 3
            ? 'Finish setup'
            : ''
        }
        btnStyle={
          (currentStep === 1 &&
            formik.values.full_name !== '' &&
            formik.values.company_name !== '') ||
          (currentStep === 2 &&
            formik.values.email !== '' &&
            formik.values.phone_number.value !== '') ||
          (currentStep === 3 && formik.values.otp !== '')
            ? styles.nextButtonStyles
            : styles.sendOtpDisabledStyles
        }
      />
    );
  };

  const renderHaveAccountLoginTextSection = () => {
    return (
      <p className={styles.haveAccountTextStyles}>
        Have an account?{' '}
        <span
          className={styles.loginInTextStyles}
          onClick={() => navigate('/admin/login')}
        >
          Log in
        </span>
      </p>
    );
  };

  const renderSuccessFullPopUp = () => {
    return (
      <Popup
        show={showPopUp}
        customPopUpStyles={styles.popUpStyles}
        popupRef={popUpRef}
        showOverlay={true}
      >
        <div className={styles.successFullPopUpStyles}>
          <div className={styles.successFulTitleStyles}>
            <p className={styles.registerTitleStyles}>
              Your Registration SuccessFull With Your Email
            </p>
            <p className={styles.registerTitleStyles}>
              please click on ok button to login
            </p>
          </div>
          <Button
            title="Ok"
            btnStyle={styles.okBtnStyles}
            onClick={() => navigate('/admin/login')}
          />
        </div>
      </Popup>
    );
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.adminRegistrationMainContainer}>
          {renderEclockHeading()}
          {renderAdminRegistration()}
          {renderSuccessFullPopUp()}
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminRegister;
