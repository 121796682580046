import React, { useRef, useState } from 'react';
import { eclocklogo, workingimg } from 'resources/images/images';
import Input from 'components/input';
import Button from 'components/button';
import styles from './styles.module.css';
import { createPassWordApi } from 'networking/api/adminregister';
import { useNavigate } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useappdatacontext';
import LoadingSpinner from 'components/loadingspinner';
import Popup from 'components/popup';

const CreatePassword = () => {
  //navigate:-
  const navigate = useNavigate();

  //contexts:-
  const { loader, setLoader } = useAppDataContext();

  //ref:-
  const popUpRef = useRef();

  //state:-
  const [confirmPassword, setConfirmPassword] = useState({
    password: '',
    confirm_password: '',
  });
  const [errorMessage, setErrorMessage] = useState({
    password: '',
    confirm_password: '',
    matchedPassword: '',
  });
  const [modal, setModal] = useState(false);
  const [userAccountType, setUserAccountType] = useState(null);

  //get verify link from search path
  const verifyLink = window.location.pathname.split('/')[2];

  const getPathName = window.location.pathname.split('/')[1];

  const handleConfirmPasswordValidation = () => {
    const newErrorMessage = {};

    if (confirmPassword.password === '') {
      newErrorMessage.password = 'Please Enter password';
    }

    if (confirmPassword.confirm_password === '') {
      newErrorMessage.confirm_password = 'Please Enter password';
    }

    if (confirmPassword.password !== confirmPassword.confirm_password) {
      newErrorMessage.matchedPassword = 'Password not match';
    }

    if (Object.keys(newErrorMessage).length > 0) {
      setErrorMessage({ ...errorMessage, ...newErrorMessage });
    } else {
      handleConfirmPassword();
    }
  };

  const handleConfirmPassword = async () => {
    try {
      setLoader(true);
      let data = {
        ...confirmPassword,
        token: verifyLink,
      };
      const response = await createPassWordApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        setModal(true);
        setUserAccountType(response.data.data);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const handleUserNaviagation = () => {
    if (userAccountType?.account_type === 'Employee') {
      navigate('/login');
    } else {
      navigate('/admin/login');
    }
  };

  const renderHeaderTopSection = () => {
    return (
      <div className={styles.eclockImgBlockStyles}>
        <img src={eclocklogo} alt="eclocklogo" className={styles.imageWidth} />
      </div>
    );
  };
  const renderLeftSection = () => {
    return (
      <div className={styles.leftSectionImgStyles}>
        <img src={workingimg} alt="workingimg" className={styles.imageStyles} />
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <form className={styles.forgetPasswordSectionStyles}>
        <p className={styles.forgotPasswordTextStyles}>
          {getPathName === 'createpassword'
            ? 'Create password'
            : 'Reset password'}
        </p>
        <div className={styles.inputAndBtnStyles}>
          <Input
            type="password"
            name="password"
            autoComplete="off"
            placeholder="Enter password here..."
            lableText="Set-Password*"
            value={confirmPassword.password}
            onChange={(e) =>
              setConfirmPassword({
                ...confirmPassword,
                password: e.target.value,
              })
            }
            onFocus={() => {
              setErrorMessage({
                ...errorMessage,
                password: '',
                matchedPassword: '',
              });
            }}
            hasError={errorMessage.password}
          />
          <Input
            type="password"
            name="confirm password"
            autoComplete="off"
            placeholder="Enter password here..."
            lableText="Confirm-Password*"
            value={confirmPassword.confirm_password}
            onChange={(e) =>
              setConfirmPassword({
                ...confirmPassword,
                confirm_password: e.target.value,
              })
            }
            onFocus={() => {
              setErrorMessage({
                ...errorMessage,
                confirm_password: '',
                matchedPassword: '',
              });
            }}
            hasError={errorMessage.confirm_password}
          />
          {errorMessage.matchedPassword && (
            <p className={styles.matchedPasswordStyles}>
              {errorMessage.matchedPassword}
            </p>
          )}
          <Button
            title="Save password"
            onClick={() => handleConfirmPasswordValidation()}
          />
        </div>
      </form>
    );
  };

  const renderCreatedPasswordPopUp = () => {
    return (
      <Popup
        show={modal}
        customPopUpStyles={styles.createPasswordPopUpStyles}
        popupRef={popUpRef}
        showOverlay={true}
      >
        <div className={styles.successFullPopUpStyles}>
          <div className={styles.successFulTitleStyles}>
            <p className={styles.registerTitleStyles}>
              Your Password Created SuccessFully
            </p>
            <p className={styles.registerTitleStyles}>
              please click on ok button to login
            </p>
          </div>
          <Button
            title="Ok"
            btnStyle={styles.okBtnStyles}
            onClick={() => handleUserNaviagation()}
          />
        </div>
      </Popup>
    );
  };

  return (
    <>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.forgotPasswordContainerStyles}>
          {renderHeaderTopSection()}
          <div className={styles.leftRightSectionStyles}>
            {renderLeftSection()} {renderRightSection()}
          </div>
          {renderCreatedPasswordPopUp()}
        </div>
      )}
    </>
  );
};

export default CreatePassword;
