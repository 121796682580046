import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const StaticsCard = (props) => {
  const { count, text, image, onClick = () => {} } = props;
  return (
    <div className={styles.staticsCardContainer} onClick={onClick}>
      <div className={styles.StaticsTopSectionStyles}>
        <p className={styles.staticsCountStyles}>{count}</p>
        <p className={styles.StaticsBottomTextStyles}>{text}</p>
      </div>
      <div className={styles.rightArrowImgStyles}>
        <img src={image} alt="rightarrow" className={styles.imageWidth} />
      </div>
    </div>
  );
};

StaticsCard.propTypes = {
  count: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default StaticsCard;
