import React, { useEffect, useRef, useState } from 'react';
import {
  leftArrowCircleIcon,
  rightArrowCircleIcon,
} from 'resources/images/images';
import styles from './styles.module.css';

const Calender = (props) => {
  // props:
  const { onSelectDate, minDate, enablePreviousMonth } = props;

  // refs
  const calenderRef = useRef();

  // states:
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showMonthsDropdown, setShowMonthsDropdown] = useState(false);
  const [showYearsDropdown, setShowYearsDropdown] = useState(false);

  // onclick outside close the months and years drodown
  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (
        showMonthsDropdown ||
        showMonthsDropdown ||
        calenderRef.current.contains(event.target)
      ) {
        return;
      }
      setShowMonthsDropdown(false);
      setShowMonthsDropdown(false);

      document.addEventListener('mousedown', handleClickOutSide);

      return () => {
        document.removeEventListener('mousedown', handleClickOutSide);
      };
    };
  }, [showMonthsDropdown, showYearsDropdown]);

  // Abbreviated month names
  const abbreviatedMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // functions:
  const handlePreviousMonth = () => {
    setSelectedDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setSelectedDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1)
    );
  };

  const handleMonthChange = (month) => {
    setSelectedDate((prevDate) => new Date(prevDate.getFullYear(), month, 1));
    setShowMonthsDropdown(false);
  };

  const handleYearChange = (year) => {
    setSelectedDate((prevDate) => new Date(year, prevDate.getMonth(), 1));
    setShowYearsDropdown(false);
  };

  // function to handle arrow key events:
  const handleKeyDown = (event) => {
    const dayIncrement = {
      ArrowLeft: -1,
      ArrowRight: 1,
      ArrowUp: -7,
      ArrowDown: 7,
    }[event.key];
    if (dayIncrement !== undefined) {
      setSelectedDate((prevDate) => {
        const newDate = new Date(prevDate);
        newDate.setDate(prevDate.getDate() + dayIncrement);
        return newDate;
      });
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const renderCalendar = () => {
    const currDate = selectedDate;
    const currMonth = currDate.getMonth();
    const currYear = currDate.getFullYear();
    const today = new Date();
    const monthDays = [];
    const daysInMonth = new Date(currYear, currMonth + 1, 0).getDate();

    // Push current month's days
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currYear, currMonth, day);
      let isDisabled = false;

      // Additional logic when enablePreviousMonth is true
      if (enablePreviousMonth) {
        isDisabled = date > today; // Disable dates after today's date
      } else {
        isDisabled =
          !enablePreviousMonth && (date < today || (minDate && date < minDate));
      }

      const formattedDay = day < 10 ? `0${day}` : day;
      monthDays.push(
        <div
          key={day}
          className={`${styles.cellTextStyles} ${
            isDisabled ? styles.disabledCellTextStyles : ''
          } ${selectedDate.getDate() === day ? styles.selectedStyles : ''}`}
          onClick={() => {
            if (!isDisabled) {
              setSelectedDate(date);
              onSelectDate(date);
            }
          }}
        >
          {formattedDay}
        </div>
      );
    }

    return monthDays;
  };
  return (
    <div className={styles.calendar}>
      <div className={styles.headerBlockStyles}>
        <div className={styles.headerStyles}>
          <div
            className={styles.leftArrowImageStyles}
            onClick={handlePreviousMonth}
          >
            <img
              src={leftArrowCircleIcon}
              alt="prevArrow"
              className={styles.imageStyles}
            />
          </div>
          <div className={styles.monthAndYearBlockStyles}>
            <div
              onClick={() => {
                setShowMonthsDropdown(!showMonthsDropdown);
                setShowYearsDropdown(false);
              }}
              className={styles.monthBlockStyles}
            >
              {`${abbreviatedMonths[selectedDate.getMonth()]},`}
              {showMonthsDropdown && (
                <div className={styles.monthDropDownStyles} ref={calenderRef}>
                  {abbreviatedMonths.map((month, index) => (
                    <div
                      key={index}
                      onClick={() => handleMonthChange(index)}
                      className={styles.monthNameTextStyles}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              onClick={() => {
                setShowYearsDropdown(!showYearsDropdown);
                setShowMonthsDropdown(false);
              }}
              className={styles.yearBlockStyles}
            >
              {selectedDate.getFullYear()}
              {showYearsDropdown && (
                <div className={styles.yearDropDownStyles} ref={calenderRef}>
                  {Array.from(
                    { length: 3000 - 2024 + 1 },
                    (_, index) => 2024 + index
                  ).map((year) => (
                    <div
                      key={year}
                      onClick={() => handleYearChange(year)}
                      className={styles.yearTextStyles}
                    >
                      {year}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className={styles.rightArrowImageStyles}
            onClick={handleNextMonth}
          >
            <img
              src={rightArrowCircleIcon}
              alt="nextArrow"
              className={styles.imageStyles}
            />
          </div>
        </div>
        <div className={styles.daysAndCalenderStyles}>
          <div className={styles.daysBlockStyles}>
            <div className={styles.dayTextStyles}>Sun</div>
            <div className={styles.dayTextStyles}>Mon</div>
            <div className={styles.dayTextStyles}>Tue</div>
            <div className={styles.dayTextStyles}>Wed</div>
            <div className={styles.dayTextStyles}>Thu</div>
            <div className={styles.dayTextStyles}>Fri</div>
            <div className={styles.dayTextStyles}>Sat</div>
          </div>
          <div className={styles.datesBlockStyles}>{renderCalendar()}</div>
        </div>
      </div>
    </div>
  );
};

export default Calender;
