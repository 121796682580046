import axios from 'utils/axios';
import * as endpoints from 'networking/endpoints';

export const employeeLoginApi = async (data) =>
  Promise.resolve(axios.post(endpoints.employeeLogin, data));

export const getAllEmployeesApi = async (data) =>
  Promise.resolve(axios.get(endpoints.gellAllEmployees, data));

export const employeeCreationApi = async (data) =>
  Promise.resolve(axios.post(endpoints.employeeCreation, data));

export const getRolesAndTechnologiesApi = async (id) =>
  Promise.resolve(axios.get(endpoints.rolesAndTechnology.replace('{id}', id)));

export const addRolesAndTechnologiesApi = async (data) =>
  Promise.resolve(axios.post(endpoints.addRolesAndTechnology, data));

export const employeeProfileEditApi = async (data) =>
  Promise.resolve(axios.post(endpoints.employeeProfileEdit, data));

export const requestLeavesApi = async (data) =>
  Promise.resolve(axios.post(endpoints.requestLeave, data));

export const getPendingLeaveRequestApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getPendingLeaveRequest.replace('{id}', id))
  );

export const getAllEmployeesLeaveTableApi = async () =>
  Promise.resolve(axios.get(endpoints.getAllEmployeesLeaveTable));

export const getAllLeaveRequestApi = async () =>
  Promise.resolve(axios.get(endpoints.getAllLeaveRequests));

export const getEmployeeProjectListApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getEmployeeProjectList.replace('{id}', id))
  );

export const getIndividualEmployeeTimeSheetApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.individualEmployeeTimeSheet.replace('{id}', id))
  );

export const employeeMarkAsTaskCompleteApi = async (id) =>
  Promise.resolve(
    axios.post(endpoints.employeeMarkAsTaskComplete.replace('{id}', id))
  );
