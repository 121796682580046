import { useState, useEffect } from 'react';
import moment from 'moment';

export function getDatesInRange(startDate, endDate) {
  // Ensure that the start date is not after today
  if (startDate > endDate) {
    return [];
  }

  const date = new Date(startDate.getTime());
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

/**
 *
 * @param {Object} param0
 * @param {Date|Number|String} param0.date
 * @param {Boolean} param0.totalMonth
 * @returns
 */

export function getCurrentMonthDates({ date, totalMonth }) {
  if (!date) {
    date = moment.now();
  }
  let startDate = new Date(moment(date).startOf('month'));
  let endDate;

  if (totalMonth) {
    endDate = new Date(moment(date).endOf('month'));
  } else {
    // Adjust end date to the current date up to the end of the month
    endDate = new Date(moment().endOf('day').valueOf());
  }

  return getDatesInRange(startDate, endDate);
}

/**
 *
 * @param {Date|Number|String|undefined|null} date
 * @returns
 */
export function getCurrentWeekDates(date = moment.now()) {
  let startDate = new Date(moment(date).startOf('week'));
  let endDate = new Date();
  return getDatesInRange(startDate, endDate);
}

/**
 *
 * @param {Date|Number|String|undefined|null} date
 * @returns
 */
export function getLast30Days() {
  let startDate = new Date(moment().subtract(30, 'days'));
  let endDate = new Date(moment.now());
  return getDatesInRange(startDate, endDate);
}

function useDateRange() {
  const [dates, setDates] = useState([]);
  const [totalDates, setTotalDates] = useState([]);

  useEffect(() => {
    setDates(getCurrentMonthDates({ date: moment.now() }));
    setTotalDates(
      getCurrentMonthDates({ date: moment.now(), totalMonth: true })
    );
  }, []);

  return { dates, totalDates };
}

export default useDateRange;
