import axios from 'utils/axios';
import * as endpoints from 'networking/endpoints';

export const registerApi = async (data) =>
  Promise.resolve(axios.post(endpoints.register, data));

export const registerOtpApi = async (data) =>
  Promise.resolve(axios.post(endpoints.registerOtp, data));

export const createPassWordApi = async (data) =>
  Promise.resolve(axios.post(endpoints.createPassword, data));

export const forGetPasswordApi = async (data) =>
  Promise.resolve(axios.post(endpoints.forgetPassword, data));

export const adminLoginApi = async (data) =>
  Promise.resolve(axios.post(endpoints.adminLogin, data));

export const getUserDetailsApi = async (id) =>
  Promise.resolve(axios.get(endpoints.getUserDetails.replace('{id}', id)));

export const createProjectApi = async (data) =>
  Promise.resolve(axios.post(endpoints.createProject, data));

export const getAdminProjectListApi = async (id) =>
  Promise.resolve(axios.get(endpoints.getAdminProjectList.replace('{id}', id)));

export const getIndividualProjectApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getIndividualProjectDetails.replace('{id}', id))
  );

export const getAllPendingAdminLeaveRequestApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getAllPendingAdminLeaveRequest.replace('{id}', id))
  );

export const updateProjectDetailsApi = async (data) =>
  Promise.resolve(axios.post(endpoints.updateProjectDetails, data));

export const adminProfileEditApi = async (data) =>
  Promise.resolve(axios.post(endpoints.adminProfileEdit, data));

export const adminMarkAsProjectCompleteApi = async (id) =>
  Promise.resolve(
    axios.post(endpoints.adminMarkAsProjectComplete.replace('{id}', id))
  );

export const getAIndividualEmployeeDataApi = async (id) =>
  Promise.resolve(
    axios.get(endpoints.getIndividualEmployeeData.replace('{id}', id))
  );

export const deleteEmployeeApi = async (id) =>
  Promise.resolve(axios.delete(endpoints.deleteEmployee.replace('{id}', id)));

export const leaveApprovalApi = async (data) =>
  Promise.resolve(axios.post(endpoints.leaveApproval, data));

export const getTodayLeavesApi = async (id) =>
  Promise.resolve(axios.get(endpoints.todayLeaves.replace('{id}', id)));

export const getAdminStaticsDataApi = async (id) =>
  Promise.resolve(axios.get(endpoints.adminStastics.replace('{id}', id)));

export const uploadImageApi = async (data) =>
  Promise.resolve(axios.post(endpoints.uploadImage, data));
