export const RightCurveArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15417 13.4075C9.04583 13.4817 8.92333 13.5175 8.80083 13.5175C8.60167 13.5175 8.40667 13.4233 8.285 13.2467C8.09 12.9625 8.16167 12.5733 8.44583 12.3775C9.76583 11.4692 10.9958 10.3458 11.0767 9.97417C10.9958 9.6525 9.76583 8.53 8.44583 7.62333C8.1625 7.4275 8.09 7.03833 8.285 6.75417C8.48 6.46917 8.86833 6.3975 9.15417 6.5925C10.3383 7.40583 12.325 8.93 12.325 9.99917C12.325 11.0683 10.3383 12.5933 9.15417 13.4075ZM10 1.875C4.00083 1.875 1.875 4.00167 1.875 10C1.875 15.9983 4.00083 18.125 10 18.125C15.9983 18.125 18.125 15.9983 18.125 10C18.125 4.00167 15.9983 1.875 10 1.875Z"
      fill="#999CA6"
    />
  </svg>
);
export const LeftCurveArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5533 12.3775C11.8375 12.5725 11.91 12.9617 11.7142 13.2467C11.5933 13.4233 11.3975 13.5175 11.1983 13.5175C11.0767 13.5175 10.9533 13.4817 10.845 13.4075C9.66083 12.595 7.675 11.0708 7.675 10.0008C7.675 8.93167 9.66083 7.40667 10.845 6.59333C11.1317 6.39833 11.5192 6.47 11.7142 6.75417C11.91 7.03833 11.8383 7.4275 11.5533 7.62333C10.2333 8.53167 9.00333 9.65417 8.92333 10.0267C9.00333 10.3475 10.2333 11.47 11.5533 12.3775ZM10 1.875C4.00083 1.875 1.875 4.00167 1.875 10C1.875 15.9992 4.00083 18.125 10 18.125C15.9983 18.125 18.125 15.9992 18.125 10C18.125 4.00167 15.9983 1.875 10 1.875Z"
      fill="#999CA6"
    />
  </svg>
);
