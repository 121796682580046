import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/button';
import { useAppDataContext } from 'hooks/useappdatacontext';
import Popup from 'components/popup';
import TimeSheetTable from 'components/timesheettable';
import TimeSheet from 'components/timesheet';
import { useEmployeeDataContext } from 'hooks/useemployeedatacontext';
import moment from 'moment';
import { useTimeSheetContext } from 'hooks/usetimesheetcontext';
import styles from './styles.module.css';

const CheckInCard = () => {
  // contexts:
  const { submitedTimeSheetData, setSubmittedTimeSheetData } =
    useAppDataContext();
  const {
    handleCheckInAndOut,
    checkInOutData,
    showCheckOutModal,
    setShowCheckOutModal,
  } = useEmployeeDataContext();
  const {
    editValue,
    setEditValue,
    updatedTimeSheet,
    setUpdatedTimeSheet,
    handleTimeSheetUpdate,
    handleEditClick,
  } = useTimeSheetContext();

  // refs:
  const popupRef = useRef();

  return (
    <div className={styles.checkInCardContainerStyles}>
      <div className={styles.checkInDateAndDescStyles}>
        <h5 className={styles.checkInHeadingStyles}>
          {moment(new Date()).format('dddd, Do MMMM')}
        </h5>
        <p className={styles.checkInDescStyles}>
          Starting or stared work? Click the button below and we will
          automatically record your check-in time.
        </p>
      </div>
      <Button
        type="submit"
        title={checkInOutData === 'Checkedin' ? 'Check-Out' : 'Check-In'}
        btnStyle={
          checkInOutData === 'Checkedin'
            ? styles.checkOutStyles
            : styles.checkInBtnSyles
        }
        onClick={() => {
          if (checkInOutData === 'Checkedin') {
            setShowCheckOutModal(true);
          } else {
            handleCheckInAndOut();
            setShowCheckOutModal(false);
          }
        }}
      />

      <Popup
        show={showCheckOutModal}
        showOverlay={true}
        popupRef={popupRef}
        customOverLayStyles={styles.timeSheetOverLayStyles}
        customPopUpStyles={styles.timeSheetModalStyles}
      >
        <div className={styles.mainTimeSheetConatinerStyles}>
          <div className={styles.topTimeSheetBlockStyles}>
            <h5 className={styles.topTimeSheetHeaderStyles}>
              Add/Edit/Confirm today’s timesheet(s)
            </h5>
            <div className={styles.middleTimeSheetContentBlockStyles}>
              <div className={styles.timeSheetTableAndDataBlockStyles}>
                <TimeSheetTable
                  customProjectPopUpStyles={styles.checkinProjectPopUpStyles}
                  customTimePopupStyles={styles.checkinTimePopUpStyles}
                />
                {updatedTimeSheet?.length > 0 ? (
                  updatedTimeSheet.map((item, index) => {
                    return (
                      <TimeSheet
                        key={index}
                        item={item}
                        serialNo={
                          (updatedTimeSheet?.length - index)
                            .toString()
                            .padStart(2, '0') + '.'
                        }
                        // handleTimeSheetClick={() => handleEditClick(item)}
                        // handleTimeSheetUpdate={handleTimeSheetUpdate}
                        onClick={
                          editValue
                            ? editValue._id === item._id
                              ? handleTimeSheetUpdate
                              : () => handleEditClick(item)
                            : () => handleEditClick(item)
                        }
                        editValue={editValue}
                        setEditValue={setEditValue}
                      />
                    );
                  })
                ) : (
                  <p className={styles.timeSheetLabelInfoTextStyles}>
                    Click on labels above to start filling your timesheets
                  </p>
                )}
              </div>
              <p className={styles.timeSheetInfoTextStyles}>
                If you already filled the timesheets for today, click “Proceed”
                button below.
              </p>
            </div>
          </div>
          <div className={styles.bottomBtnsBlockStyles}>
            <Button
              title="Cancel"
              btnStyle={styles.cancelBtnStyles}
              onClick={() => {
                setShowCheckOutModal(false);
                setSubmittedTimeSheetData([]);
              }}
            />
            <Button
              title="Proceed"
              btnStyle={styles.proceedBtnStyles}
              onClick={() => {
                if (checkInOutData === 'Checkedin') {
                  handleCheckInAndOut();
                  setShowCheckOutModal(false);
                }
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default CheckInCard;
