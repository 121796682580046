import React, { useEffect, useRef, useState } from 'react';
import DynamicTextArea from 'components/dynamictextarea';
import InputWithDropDown from 'components/inputwithdropdown';
import { dropdownarrow } from 'resources/images/images';
import { selectAssginedProjectData } from 'constants/commondata/commondata';
import Button from 'components/button';
import SearchInput from 'components/searchinput';
import SelectProjectCard from 'components/cards/selectprojectcard';
import SelectTimeCard from 'components/cards/selecttimecard';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import useUserViewsHook from 'hooks/userViewshook';
import Input from 'components/input';
import { employeeTimeSheetApi } from 'networking/api/timesheets';
import { useAuthContext } from 'hooks/useauthcontext';
import { useTimeSheetContext } from 'hooks/usetimesheetcontext';
import Popup from 'components/popup';
import classNames from 'classnames';
import styles from './styles.module.css';

const TimeSheetTable = (props) => {
  const { customProjectPopUpStyles, customTimePopupStyles } = props;
  //contexts
  const {
    // values
    taskTitle,
    describeTask,
    selectedProject,
    selectTime,
    selectAssginedProjectsData,
    filterAssignProjectsData,
    filterUnAssignedProjectsData,
    timesData,
    filterTimesData,
    submitedTimeSheetData,

    // functions
    setTaskTitle,
    setDescribeTask,
    setSelectedProject,
    setSelectTime,
    setFilterAssignProjectsData,
    selectUnAssignedProjectsData,
    setFilterUnAssignedProjectData,
    setFilterTimesData,
    setSubmittedTimeSheetData,
    setLoader,
  } = useAppDataContext();
  const { allEmployeeProjects } = useProjectsDataContext();
  const { user } = useAuthContext();
  const { getTodayTimeSheetsData } = useTimeSheetContext();

  // popups and modal states
  const [selectProjectPopup, setSelectProjectPopup] = useState(false);
  const [selectTimePopup, setSelectTimePopup] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);

  //useEffect to show toaster without checkin
  useEffect(() => {
    setTimeout(() => {
      setOpenToaster(false);
    }, 3000);
  }, [openToaster]);

  // useEffects:
  useEffect(() => {
    setFilterAssignProjectsData(selectAssginedProjectData);
  }, [selectAssginedProjectData]);

  useEffect(() => {
    setFilterUnAssignedProjectData(selectUnAssignedProjectsData);
  }, [selectUnAssignedProjectsData]);

  // submitted time sheet data display onload the page
  useEffect(() => {
    setFilterTimesData(timesData);
  }, [timesData]);

  useEffect(() => {
    setSubmittedTimeSheetData(submitedTimeSheetData);
  }, [submitedTimeSheetData]);

  // Function to submit the timesheet
  const submitTimeSheet = async () => {
    try {
      // setLoader(true);
      let data = {
        admin: user?.admin,
        employee: user?._id,
        project: selectedProject?._id,
        task: taskTitle,
        description: describeTask,
        time: selectTime,
      };
      const response = await employeeTimeSheetApi(data);
      if (response.data.type === 'success') {
        resetTimeSheetData();
        setLoader(false);
        getTodayTimeSheetsData();
      } else if (response.data.type === 'fail') {
        setOpenToaster(true);
        setLoader(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoader(false);
    }
  };

  // function to handle select project option click
  const handleSelecetProjectOptionsClick = (item) => {
    setSelectedProject({ ...item, title: item.title });
    setSelectProjectPopup(false);
  };
  // function to handle select time option click
  const handleTimeOptionsClick = (item) => {
    setSelectTime(item.time);
    setSelectTimePopup(false);
  };

  // function to toogle the select project popup
  const toggleSelectProjectPopup = () => {
    setSelectProjectPopup(!selectProjectPopup);
  };

  // function to toggle the select time popup
  const toggleSelectTimePopup = () => {
    setSelectTimePopup(!selectTimePopup);
  };

  // search the project data
  const handleSearchInputChange = (searchContent) => {
    const filteredAssignProject = selectAssginedProjectsData.filter(
      (assigned) =>
        JSON.stringify(assigned)
          .toLowerCase()
          .includes(searchContent.toLowerCase())
    );
    setFilterAssignProjectsData(filteredAssignProject);

    const filteredUnAssignProject = selectUnAssignedProjectsData.filter(
      (unAssigned) =>
        JSON.stringify(unAssigned)
          .toLowerCase()
          .includes(searchContent.toLowerCase())
    );
    setFilterUnAssignedProjectData(filteredUnAssignProject);

    const filterTime = timesData.filter((time) =>
      JSON.stringify(time).toLowerCase().includes(searchContent.toLowerCase())
    );
    setFilterTimesData(filterTime);
  };

  // function to reset the time sheet  data
  const resetTimeSheetData = () => {
    setTaskTitle('');
    setDescribeTask('');
    setSelectedProject({});
    setSelectTime('');
  };

  const rendetTimeSheetLeftSection = () => {
    return (
      <div className={styles.serialNumberAndSelectProjectStyles}>
        <p className={styles.serialNumberTextStyles}>Sn.</p>
        <InputWithDropDown
          placeholder={
            selectedProject.title ? selectedProject.title : 'Select project'
          }
          customPlaceHolderStyles={
            selectedProject.title ? styles.selectedProjectTextStyles : ''
          }
          rightIcon={dropdownarrow}
          onClick={toggleSelectProjectPopup}
          popupOpen={selectProjectPopup}
          onClose={() => setSelectProjectPopup(false)}
          customSubWrapperStyles={styles.selectProjectInputStyles}
          customPopUpContainerStyles={classNames(
            styles.selectProjectPopupStyles,
            customProjectPopUpStyles
          )}
          selectedItem={selectedProject.title}
        >
          {renderTopSearchInputSection()}
          {renderMiddleAssignedProjectSection()}
          {renderMiddleUnAssignedProjectSection()}
          {renderBottomButtonSection()}
        </InputWithDropDown>
      </div>
    );
  };

  const renderTopSearchInputSection = () => {
    return (
      <SearchInput
        placeholder="Search project..."
        onCloseImgClick={() => setSelectProjectPopup(toggleSelectProjectPopup)}
        onSearchInputChange={handleSearchInputChange}
      />
    );
  };

  const renderMiddleAssignedProjectSection = () => {
    return (
      <div className={styles.assignedMainContainerStyles}>
        <div className={styles.assignedTextCountStyles}>
          <h5 className={styles.assignedHeadingTextStyles}>Assigned to you</h5>
          <div className={styles.assignedCountTextStyles}>
            <p>
              {allEmployeeProjects.onGoing.length.toString().padStart(2, '0')}
            </p>
          </div>
        </div>
        {/* {allEmployeeProjects.onGoing.length > 0 ? (
          allEmployeeProjects.onGoing &&
          allEmployeeProjects.onGoing.map((item, index) => (
            <SelectProjectCard
              key={index}
              onClickSelectProject={() =>
                handleSelecetProjectOptionsClick(item)
              }
              rightArrowImg={true}
              projectName={item.title}
              projectWorkedOn={item.category_tags}
              selectedProject={selectedProject === item.title}
            />
          ))
        ) : (
          <p className={styles.noDataFoundTextStyles}>No Data Found</p>
        )} */}

        {allEmployeeProjects.onGoing.length > 0 ? (
          allEmployeeProjects.onGoing.map((item, index) => {
            return (
              <SelectProjectCard
                key={index}
                onClickSelectProject={() =>
                  handleSelecetProjectOptionsClick(item)
                }
                rightArrowImg={true}
                projectName={item.title}
                projectWorkedOn={item.category_tags}
                selectedProject={selectedProject.title === item.title}
              />
            );
          })
        ) : (
          <p className={styles.noDataFoundTextStyles}>No Data Found</p>
        )}
      </div>
    );
  };

  const renderMiddleUnAssignedProjectSection = () => {
    return (
      <div className={styles.unAssignedMainContainerStyles}>
        <div className={styles.unAssignedTextCountStyles}>
          <h5 className={styles.unAssignedHeadingTextStyles}>Unassigned</h5>
          <p className={styles.unAssignedCountTextStyles}>02</p>
        </div>
        {filterUnAssignedProjectsData.length > 0 ? (
          filterUnAssignedProjectsData &&
          filterUnAssignedProjectsData.map((item, index) => (
            <SelectProjectCard
              key={index}
              onClickSelectProject={() =>
                handleSelecetProjectOptionsClick(item)
              }
              leftArrowImg={true}
              projectName={item.projectName}
              projectWorkedOn={item.projectWorkedOn}
              selectedProject={selectedProject.title === item.projectName}
            />
          ))
        ) : (
          <p className={styles.noDataFoundTextStyles}>No Data Found</p>
        )}
      </div>
    );
  };

  const renderBottomButtonSection = () => {
    return (
      <div className={styles.misallaneousStyles}>
        <Button title="Miscellaneous" btnStyle={styles.misllaneousBtnStyles} />
      </div>
    );
  };

  const renderTimeSheetRightSection = () => {
    return (
      <div className={styles.timeSheetInputsContainerStyles}>
        {renderTaskTitleTextAreaSection()}
        {renderDescribeTaskTextAreaSection()}
        {renderSelectTimeInputSection()}
        {renderSubmitButtonSection()}
      </div>
    );
  };

  const renderTaskTitleTextAreaSection = () => {
    return (
      <DynamicTextArea
        value={taskTitle}
        onChange={setTaskTitle}
        placeholder="Task tile*"
        className={styles.taskTitleTextAreaStyles}
      />
    );
  };

  const renderDescribeTaskTextAreaSection = () => {
    return (
      <DynamicTextArea
        value={describeTask}
        onChange={setDescribeTask}
        placeholder="Describe the task*"
        className={styles.describeTaskTextAreaStyles}
      />
    );
  };

  const renderSelectTimeInputSection = () => {
    return (
      <InputWithDropDown
        name="time"
        placeholder={selectTime ? selectTime : 'Time*'}
        customPlaceHolderStyles={
          selectTime ? styles.selectedTimeTextStyles : ''
        }
        rightIcon={dropdownarrow}
        onClick={toggleSelectTimePopup}
        popupOpen={selectTimePopup}
        onClose={() => setSelectTimePopup(false)}
        customSubWrapperStyles={styles.selectTimeInputStyles}
        customPopUpContainerStyles={classNames(
          styles.selectTimePopupStyles,
          customTimePopupStyles
        )}
        selectedItem={selectTime}
      >
        <SearchInput
          placeholder="Search time..."
          onCloseImgClick={() => setSelectTimePopup(toggleSelectTimePopup)}
          onSearchInputChange={handleSearchInputChange}
        />
        <div className={styles.assignedMainContainerStyles}>
          {filterTimesData.length > 0 ? (
            filterTimesData &&
            filterTimesData.map((item, index) => (
              <SelectTimeCard
                key={index}
                onClickTimeOptions={() => handleTimeOptionsClick(item)}
                time={item.time}
                selectTime={selectTime === item.time}
              />
            ))
          ) : (
            <p className={styles.noDataFoundTextStyles}>No Data Found</p>
          )}
        </div>
      </InputWithDropDown>
      // <Input
      //   name="time"
      //   customInpuContainerStyles={styles.inputTimeContainerStyles}
      //   customInputStyle={styles.timeInputStyles}
      //   type="text"
      //   placeholder="Time*"
      //   onChange={(e) => setSelectTime(e.target.value)}
      //   value={selectTime}
      // />
    );
  };

  const renderSubmitButtonSection = () => {
    return (
      <React.Fragment>
        {selectedProject.title && selectTime && taskTitle && describeTask && (
          <Button
            title={'Submit'}
            onClick={() => {
              submitTimeSheet();
            }}
            btnStyle={styles.submitBtnStyles}
          />
        )}
      </React.Fragment>
    );
  };

  const renderToasterSection = () => {
    return (
      <>
        {openToaster && (
          <div className={styles.toasterContainerStyles}>
            <p className={styles.toasterTextStyles}>Please Check-in first</p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.timeSheetContainerStyles}>
      {rendetTimeSheetLeftSection()}
      {renderTimeSheetRightSection()}
      {renderToasterSection()}
    </div>
  );
};

export default TimeSheetTable;
