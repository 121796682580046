import React, { useEffect, useState } from 'react';
import {
  avatar1,
  crossimg,
  dropDownThickArrow,
  dropdownarrow,
  jamesprofile,
} from 'resources/images/images';
import {
  checkInOutTableHeaderData,
  monthsData,
} from 'constants/commondata/commondata';
import { useAppDataContext } from 'hooks/useappdatacontext';
import ButtonTab from 'components/buttontab';
import OnGoingProjectCard from 'components/cards/ongoingprojectcard';
import { useNavigate } from 'react-router-dom';
import useUserViewsHook from 'hooks/userViewshook';
import { useProjectsDataContext } from 'hooks/useprojectsdatahook';
import { useAuthContext } from 'hooks/useauthcontext';
import { getIndividualEmployeeTimeSheetApi } from 'networking/api/employee';
import moment from 'moment';
import index from 'components/checking';
import useTapOnProject from 'hooks/useTapOnProject';
import Calender from 'components/calender';
import InputWithDropDown from 'components/inputwithdropdown';
import styles from './styles.module.css';

const Employee = () => {
  // routing:
  const navigate = useNavigate();
  //context
  const { setRoutName, setSideBar, setLoader } = useAppDataContext();
  const { isAdmin, isEmployee } = useUserViewsHook();
  const { allEmployeeProjects } = useProjectsDataContext();
  const { user } = useAuthContext();

  //states
  const [checkInOutStatusData, setCheckInOutStatusData] = useState('');
  const [showMonthPopUp, setShowMonthPopUP] = useState(false);
  const [filterTimeType, setFilterTimeType] = useState('This month');
  const [selectDate, setSelectDate] = useState({
    fromDate: '',
    toDate: '',
  });
  const [showDatePopup, setShowDatePopup] = useState({
    fromDate: false,
    toDate: false,
  });

  // hooks:
  const tapOnProject = useTapOnProject(
    isAdmin,
    isEmployee,
    setRoutName,
    navigate,
    setSideBar
  );

  const timeFilterOptions = [
    'This month',
    'This week',
    monthsData[moment().subtract(1, 'months').month()],
    monthsData[moment().subtract(2, 'months').month()],
  ];

  //useEffect
  useEffect(() => {
    if (user?._id) {
      getIndividualCheckInOutData(user?._id);
    }
  }, [user]);

  //api to get the individual check in out
  const getIndividualCheckInOutData = async (employeeid) => {
    try {
      setLoader(true);
      const response = await getIndividualEmployeeTimeSheetApi(employeeid);
      if (response.data.type === 'success') {
        setCheckInOutStatusData(response.data.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  //tasks asign to employee
  const assignedTasks = user?.projects?.filter(
    (item) => item?.status === 'Ongoing'
  );

  //tasks completed to employee
  const completedTasks = user?.projects?.filter(
    (item) => item?.status === 'Completed'
  );

  // function to handle select from and to date
  const handleToSelectFromAndToDate = (dateType, date) => {
    setSelectDate((prevState) => ({
      ...prevState,
      [dateType]: date,
    }));

    setShowDatePopup((prevState) => ({
      ...prevState,
      [dateType]: false,
    }));

    if (dateType === 'toDate' && selectDate?.fromDate) {
      setShowMonthPopUP(false);
    }
  };

  const renderOverViewSection = () => {
    return (
      <div className={styles.overViewStyles}>
        <p className={styles.overViewHeaderStyles}>Overview</p>
        <div className={styles.employeeDetailsStyles}>
          <div className={styles.roleAndProfileStyles}>
            <div className={styles.profileStyles}>
              <img
                src={user?.image ? user?.image : avatar1}
                alt="profile"
                className={styles.profileImgStyles}
              />
            </div>
            <div className={styles.nameAndRoleStyles}>
              <p className={styles.nameTextStyles}>{user?.full_name}</p>
              <p className={styles.roleTextStyles}>
                {user?.roles?.map((item) => item?.name)}
              </p>
            </div>
          </div>
          <div className={styles.taskAndprojectsStyles}>
            <p className={styles.assignedTextStyles}>
              <span>Assigned tasks:</span>{' '}
              {allEmployeeProjects?.onGoing?.length.toString().padStart(2, '0')}
            </p>
            <p className={styles.accountTextStyles}>
              <span>Account type:</span> {user?.account_type}
            </p>
            <p className={styles.completedProjectTextStyles}>
              <span>Completed projects:</span>{' '}
              {allEmployeeProjects?.completed?.length
                .toString()
                .padStart(2, '0')}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderCheckInOutTableSection = () => {
    const filteredData =
      checkInOutStatusData &&
      checkInOutStatusData
        .filter((entry) => {
          const entryDate = moment(entry.date);
          switch (filterTimeType) {
            case 'This month':
              return entryDate.isSame(moment(), 'month');
            case 'This week':
              const startOfWeek = moment().startOf('week');
              const endOfWeek = moment();
              return entryDate.isBetween(
                startOfWeek,
                endOfWeek,
                undefined,
                '[]'
              );
            case monthsData[moment().subtract(1, 'months').month()]:
              return entryDate.isSame(moment().subtract(1, 'months'), 'month');
            case monthsData[moment().subtract(2, 'months').month()]:
              return entryDate.isSame(moment().subtract(2, 'months'), 'month');
            default:
              return false;
          }
        })
        .filter((entry) => {
          if (selectDate.fromDate && selectDate.toDate) {
            const entryDate = moment(entry.date);
            const fromDate = moment(selectDate.fromDate);
            const toDate = moment(selectDate.toDate);
            return entryDate.isBetween(fromDate, toDate, undefined, '[]');
          } else if (selectDate.fromDate || selectDate.toDate) {
            const entryDate = moment(entry.date);
            if (selectDate.fromDate) {
              return entryDate.isSameOrAfter(selectDate.fromDate);
            } else {
              return entryDate.isSameOrBefore(selectDate.toDate);
            }
          } else {
            return true;
          }
        });

    return (
      <div className={styles.checkInOutStyles}>
        <div className={styles.checkInOutTopStyles}>
          <p className={styles.checkInTitleStyles}>Check-In and Check-Out</p>
          <InputWithDropDown
            popupOpen={showMonthPopUp}
            onClick={() => setShowMonthPopUP(true)}
            rightIcon={dropDownThickArrow}
            customSubWrapperStyles={styles.monthFilterBtnStyles}
            customPopUpContainerStyles={styles.monthFilterPopUpStyles}
            customPlaceHolderStyles={styles.timeFilterTextStyles}
            showOverlay={true}
            selectedItem={filterTimeType}
          >
            <>
              <div className={styles.popupTopStyles}>
                <p className={styles.popupTopTextStyles}>Filter by time:</p>
                <div
                  onClick={() => setShowMonthPopUP(false)}
                  className={styles.crossIconStyles}
                >
                  <img src={crossimg} alt="cross icon" />
                </div>
              </div>
              <div className={styles.filterTypesContainerStyles}>
                {timeFilterOptions &&
                  timeFilterOptions?.map((item, index) => (
                    <p
                      className={styles.filterTypeTextStyles}
                      style={
                        item === filterTimeType ? { background: '#ECEEF4' } : {}
                      }
                      key={index}
                      onClick={() => {
                        setFilterTimeType(item);
                        setShowMonthPopUP(false);
                        setSelectDate({});
                      }}
                    >
                      {item}
                    </p>
                  ))}
              </div>
              <div className={styles.dateRangeButtonWrapperStyles}>
                <InputWithDropDown
                  placeholder={'From date'}
                  popupOpen={showDatePopup.fromDate}
                  onClick={() =>
                    setShowDatePopup({ ...showDatePopup, fromDate: true })
                  }
                  onClose={() =>
                    setShowDatePopup({ ...showDatePopup, fromDate: false })
                  }
                  rightIcon={dropdownarrow}
                  selectedItem={
                    selectDate.fromDate
                      ? moment(selectDate?.fromDate)
                          .format('DD/MM/YYYY')
                          .toString()
                      : 'From date'
                  }
                  customPopUpContainerStyles={styles.fromDatePopUpStyles}
                  customPlaceHolderStyles={
                    selectDate.fromDate && styles.fromDateTextStyles
                  }
                >
                  <Calender
                    onSelectDate={(date) =>
                      handleToSelectFromAndToDate('fromDate', date)
                    }
                    enablePreviousMonth={true}
                  />
                </InputWithDropDown>

                <InputWithDropDown
                  placeholder={'To date'}
                  popupOpen={showDatePopup.toDate}
                  onClick={() =>
                    setShowDatePopup({ ...showDatePopup, toDate: true })
                  }
                  onClose={() =>
                    setShowDatePopup({ ...showDatePopup, toDate: false })
                  }
                  rightIcon={dropdownarrow}
                  customPopUpContainerStyles={styles.toDatePopUpStyles}
                  selectedItem={
                    selectDate.toDate
                      ? moment(selectDate.toDate)
                          .format('DD/MM/YYYY')
                          .toString()
                      : 'To date'
                  }
                  customPlaceHolderStyles={
                    selectDate.toDate && styles.toDateTextStyles
                  }
                >
                  <Calender
                    onSelectDate={(date) =>
                      handleToSelectFromAndToDate('toDate', date)
                    }
                    enablePreviousMonth={true}
                  />
                </InputWithDropDown>
              </div>
            </>
          </InputWithDropDown>
        </div>
        <div className={styles.checkInOutBottomStyles}>
          <div className={styles.tableHeaderStyles}>
            {checkInOutTableHeaderData?.map((item) => {
              return (
                <p className={styles.headerTitleStyle} key={index}>
                  {item.header}
                </p>
              );
            })}
          </div>
          <div className={styles.tableSubDescStyles}>
            {filteredData?.length > 0 ? (
              filteredData?.map((entry, entryIndex) => {
                return (
                  <React.Fragment key={entryIndex}>
                    {entry.check_in_out && entry.check_in_out.length > 0 ? (
                      entry.check_in_out.map((item, index) => {
                        // check in - out time
                        const checkInTime = moment(item.check_in);
                        const checkOutTime = moment(item.check_out);
                        const diffrenceInMinutes = checkOutTime.diff(
                          checkInTime,
                          'minutes'
                        );
                        const diffrenceInHours = Math.floor(
                          diffrenceInMinutes / 60
                        );
                        const remainingMinutes = diffrenceInMinutes % 60;
                        return (
                          <div
                            key={`${entryIndex}-${index}`}
                            className={styles.tableDescStyles}
                          >
                            <p className={styles.yearStyles}>
                              <span className={styles.totalMonthStyles}>
                                {moment(item.check_in).format(
                                  'DD MMM YYYY, ddd'
                                )}
                              </span>
                            </p>

                            <p className={styles.checkInStyles}>
                              {checkInTime.format('HH:mm')}
                            </p>
                            <p className={styles.checkOutStyles}>
                              {checkOutTime.format('HH:mm')}
                            </p>
                            <p className={styles.checkOutStyles}>
                              {diffrenceInMinutes <= 60
                                ? `${diffrenceInMinutes} minutes`
                                : `${diffrenceInHours}.${remainingMinutes} hours`}
                            </p>
                          </div>
                        );
                      })
                    ) : entry.leave_status ? (
                      <div className={styles.tableDescStyles}>
                        <p className={styles.yearStyles}>
                          <span className={styles.totalMonthStyles}>
                            {moment(entry.date).format('DD MMM YYYY, ddd')}
                          </span>
                        </p>
                        {[1, 2, 3].map((status, index) => (
                          <p className={styles.leaveStatusStyles} key={index}>
                            {entry.leave_status}
                          </p>
                        ))}
                      </div>
                    ) : (
                      <div className={styles.tableDescStyles}>
                        <p className={styles.yearStyles}>
                          <span className={styles.totalMonthStyles}>
                            {moment(entry.date).format('DD MMM YYYY, ddd')}
                          </span>
                        </p>
                        {[1, 2, 3].map((status, index) => (
                          <p
                            className={styles.notAvailableTextStyles}
                            key={index}
                          >
                            N/A
                          </p>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <p className={styles.noDataFoundTextStyles}>--No Data Found--</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAssignedProjectsSection = () => {
    const projectData = isEmployee && allEmployeeProjects.onGoing;
    return (
      <div className={styles.assignedPojectStyles}>
        <p className={styles.assignedProjectTextStyles}>Assigned projects</p>
        <div className={styles.multipleProjectsStyles}>
          {projectData &&
            projectData?.map((item, index) => (
              <OnGoingProjectCard
                key={index}
                title={item.title}
                icon="#"
                technologies={item.category_tags}
                onClick={() => tapOnProject(item.title, item._id)}
              />
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.employeeContainerStyles}>
      {renderOverViewSection()}
      {renderCheckInOutTableSection()}
      {renderAssignedProjectsSection()}
    </div>
  );
};

export default Employee;
