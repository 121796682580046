/*menu data images*/

import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  calender,
  calenderIcon,
  clock,
  clockIcon,
  createnewemployeeimg,
  createnewprojectimg,
} from 'resources/images/images';

export const user_Types = {
  admin: 'Admin',
  employee: 'Employee',
};

const navigationOptionsData = [
  {
    navigationHeading: 'Dashboard',
    route: '/dashboard',
  },
  {
    navigationHeading: 'Timesheets',
    route: '/admin-timesheet',
  },
  {
    navigationHeading: 'Projects',
    route: '/admin-projects',
  },
  {
    navigationHeading: 'Employees',
    route: '/admin-employees',
  },
  {
    navigationHeading: 'Leaves',
    route: '/admin-leaves',
  },
];

const generalOptionsData = [
  {
    generalHeading: 'Settings',
  },
  {
    generalHeading: 'Help centre',
  },
  {
    generalHeading: 'About',
  },
];

const menuData = [
  {
    icon: createnewprojectimg,
    text: 'Create new project',
    description: 'If you want to add a new project',
  },
  {
    icon: createnewemployeeimg,
    text: 'Add new employee',
    description: 'If you want to add new employee(s)',
  },
];

// select categories data
const categoriesData = [
  '#Research',
  '#UX/UI',
  '#Frontend',
  '#Backend',
  '#Web development',
  '#App development',
];

// backend data [select employees data]

const selectEmployeesData = [
  {
    employeeImg: avatar1,
    employeeName: 'James Carter',
    employeeDesignation: 'Senior Developer',
    employeeCount: '02',
  },
  {
    employeeImg: avatar2,
    employeeName: 'Daniel Watt',
    employeeDesignation: 'Senior Designer',
    employeeCount: '01',
  },
  {
    employeeImg: avatar3,
    employeeName: 'Emma Watson',
    employeeDesignation: 'Senior Sales Manager',
    employeeCount: '03',
  },
  {
    employeeImg: avatar1,
    employeeName: 'James Carter',
    employeeDesignation: 'Senior Developer',
    employeeCount: '02',
  },
  {
    employeeImg: avatar2,
    employeeName: 'Daniel Watt',
    employeeDesignation: 'Senior Designer',
    employeeCount: '01',
  },
  {
    employeeImg: avatar3,
    employeeName: 'Emma Watson',
    employeeDesignation: 'Senior Sales Manager',
    employeeCount: '03',
  },
  {
    employeeImg: avatar4,
    employeeName: 'Harry White',
    employeeDesignation: 'Junior Developer',
    employeeCount: '02',
  },
];

// backend:select no of weeks

const selectNoOfWeeksData = [
  {
    noOfWeeks: '1 week',
  },
  {
    noOfWeeks: '2 weeks',
  },
  {
    noOfWeeks: '3 weeks',
  },
  {
    noOfWeeks: '4 weeks',
  },
];
const selectNoOfWeeksToData = [
  {
    noOfWeeks: '3 week',
  },
  {
    noOfWeeks: '4 weeks',
  },
  {
    noOfWeeks: '5 weeks',
  },
  {
    noOfWeeks: '6 weeks',
  },
];

// backend data:[timesheet time data]

const timeData = [
  {
    time: '15 minutes',
  },
  {
    time: '30 minutes',
  },
  {
    time: '1hr',
  },
  {
    time: '1hr 30 minutes',
  },
];
// backend select project data

const selectAssginedProjectData = [
  {
    projectName: 'RosholmDell',
    projectWorkedOn: 'UX/UI, Web design',
  },
  {
    projectName: 'Employee site design',
    projectWorkedOn: 'UX/UI, Web design',
  },
];

const selectUnAssignedProjectData = [
  {
    projectName: 'Cercino mvp app',
    projectWorkedOn: 'App development',
  },
  {
    projectName: 'Vilje Tech website',
    projectWorkedOn: 'Frontend, Backend, Web development',
  },
];

const monthsData = [
  'January',
  'Febuary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const weeksData = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const employeeRoleData = [
  'Frontend developer',
  'Backend developer',
  'UX designer',
  'Graphic designer',
];

const experianceDurationMonthsData = [
  '3 months',
  '6 months',
  '1 Year',
  '1.5 Year',
];

const experianceDurationYearsData = [
  '3 Years',
  '4 Years',
  '5 Years',
  '6 Years',
];

const techStackDummyData = [
  { value: 'Redux', label: 'Redux' },
  { value: 'GraphQL', label: 'GraphQL' },
  { value: 'React', label: 'React' },
  { value: 'Angular', label: 'Angular' },
  { value: 'Vue.js', label: 'Vue.js' },
  { value: 'Node.js', label: 'Node.js' },
  { value: 'Express.js', label: 'Express.js' },
  { value: 'MongoDB', label: 'MongoDB' },
  { value: 'MySQL', label: 'MySQL' },
  { value: 'Docker', label: 'Docker' },
  { value: 'Kubernetes', label: 'Kubernetes' },
  { value: 'AWS', label: 'Amazon Web Services (AWS)' },
  { value: 'Firebase', label: 'Firebase' },
  { value: 'TypeScript', label: 'TypeScript' },
  { value: 'Next.js', label: 'Next.js' },
];

const notificationData = [
  {
    image: avatar1,
    message: '@James Carter marked #RosholmDell project as complete',
    time: '10 minutes ago',
  },
  {
    image: clock,
    message: 'Remember to fill your timesheets before closing work today',
    time: '15 minutes ago',
  },
  {
    image: avatar2,
    message: '@Daniel Watt assigned you #Employee site design project',
    time: '1.5 hours ago',
  },
  {
    image: calender,
    message:
      'Your paid leave request for 9th, 10th September is approved by @Daniel Watt',
    time: '3 hours ago',
  },
  {
    image: clock,
    message:
      'Hey Daniel, you are yet to check-in today, please click check-in if you already started working',
    time: '10 hours ago',
  },
];

const projectData = [
  {
    projectName: 'RosholmDell',
    technology: 'UX/UI, Web design',
    status: 'Completed',
  },
  {
    projectName: 'Employee site design',
    technology: 'UX/UI, Web design',
    status: 'Ongoing',
  },
  {
    projectName: 'Cercino mvp app',
    technology: 'UX/UI, App design',
    status: 'Ongoing',
  },
  {
    projectName: 'Vilje web development',
    technology: 'Frontend, Backend, Web development',
    status: 'Completed',
  },
];

const employeeData = [
  {
    name: 'James Carter',
    designation: 'Senior Developer',
    assignedNo: 'Assigned: 02',
  },
  {
    name: 'Daniel Watt',
    designation: 'Senior Developer',
    assignedNo: 'Assigned: 01',
  },
  {
    name: 'Emma Watson',
    designation: 'Senior Sales Manager',
    assignedNo: 'Assigned: 03',
  },
];

const checkInOutTableHeaderData = [
  {
    header: 'Date',
  },
  {
    header: 'Check-In',
  },
  {
    header: 'Check-Out',
  },
  {
    header: 'Total time',
  },
];

export {
  navigationOptionsData,
  generalOptionsData,
  menuData,
  timeData,
  categoriesData,
  selectAssginedProjectData,
  selectUnAssignedProjectData,
  selectNoOfWeeksData,
  selectNoOfWeeksToData,
  selectEmployeesData,
  monthsData,
  weeksData,
  employeeRoleData,
  experianceDurationMonthsData,
  experianceDurationYearsData,
  techStackDummyData,
  notificationData,
  projectData,
  employeeData,
  checkInOutTableHeaderData,
};
