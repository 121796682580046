import React from 'react';

const useTapOnProject = (
  isAdmin,
  isEmployee,
  setRouteName,
  navigate,
  setSideBar
) => {
  const tapOnProjectSection = (projectTitle, projectId, projectStatus) => {
    if (isAdmin) {
      let data = ['Projects', projectStatus, projectTitle];
      setRouteName(data);
      navigate('/admin-individualProject', {
        state: { projectId, projectTitle },
      });
    } else if (isEmployee) {
      let data = ['Projects', 'Assigned', projectTitle];
      setRouteName(data);
      navigate('/admin-individualProject', {
        state: { projectId, projectTitle },
      });
    }
    setSideBar(false);
  };

  return tapOnProjectSection;
};

export default useTapOnProject;
