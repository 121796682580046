import React, { useState } from 'react';
import './styles.module.css';
import CalenderInput from 'components/inputcalender';

const EmployeeTmeSheets = () => {
  return (
    <div>
      EmployeeTmeSheets
      <CalenderInput />
    </div>
  );
};

export default EmployeeTmeSheets;
