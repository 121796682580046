import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const DynamicTextArea = ({
  value,
  onChange,
  placeholder,
  style,
  className,
  onFocus,
  onBlur,
}) => {
  const [height, setHeight] = useState('36px');
  const [focused, setFocused] = useState(false);

  const handleInputChange = (e) => {
    const newText = e.target.value;
    onChange(newText);

    // Calculate the new height based on scrollHeight and add some padding
    const newHeight =
      newText === '' ? '36px' : e.target.scrollHeight + 1 + 'px';
    setHeight(newHeight);
  };

  const handleFocus = () => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const textareaStyle = {
    ...style,
    height: focused ? height : '36px',
    overflow: focused ? 'auto' : 'hidden',
  };

  return (
    <textarea
      value={value}
      placeholder={placeholder}
      style={textareaStyle}
      className={classNames(
        focused ? styles.textAreaStyles : styles.focusedStyle2,
        className
      )}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

DynamicTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default DynamicTextArea;
