import React, { useState } from 'react';
import {
  closeBackgroundImg,
  dropdownarrow,
  warningCircleIcon,
} from 'resources/images/images';
import InputWithDropDown from 'components/inputwithdropdown';
import {
  experianceDurationMonthsData,
  experianceDurationYearsData,
} from 'constants/commondata/commondata';
import Input from 'components/input';
import RadioButton from 'components/radionbutton';
import styles from './styles.module.css';
const SelectRoleCard = (props) => {
  const {
    role,
    onClickCloseImgClick = () => {},
    experience,
    setExperiance,
    manualEntryOfDuration,
  } = props;
  const [displayedSelectedItem, setDisplayedSelectedItem] =
    useState(experience);
  const [showExperianceDurationPopup, setShowExperianceDurationPopup] =
    useState(false);
  const [durationData, setDurationData] = useState(
    experianceDurationMonthsData
  );
  const [isYearsSelected, setIsYearsSelected] = useState(false);
  const selectMonthAndYearsOptionsData = [
    {
      duration: 'In months',
      value: experianceDurationMonthsData,
    },
    {
      duration: 'In years',
      value: experianceDurationYearsData,
    },
  ];
  const handleSelectData = (item) => {
    const convertedValue = convertSelectedItemToValue(item);
    setExperiance(convertedValue);
    setDisplayedSelectedItem(item);
    setShowExperianceDurationPopup(false);
  };
  const convertSelectedItemToValue = (selectedItem) => {
    const [numericValue, durationType] = selectedItem.split(' ');
    let convertedValue = parseFloat(numericValue);
    if (durationType === 'Years' || durationType === 'Year') {
      convertedValue *= 12;
    }
    return convertedValue;
  };
  const handleSelectModeOfDuration = (item) => {
    setDurationData(item);
    setIsYearsSelected(item[0] === experianceDurationYearsData[0]);
    setDisplayedSelectedItem('');
  };
  const handleManualEntry = (enteredValue) => {
    const numericValue = parseFloat(enteredValue) || 0;
    const durationType = isYearsSelected ? 'years' : 'months';
    const displayedItem = `${enteredValue} ${durationType}`;
    // Update 'experience' state with multiplied value
    setExperiance(numericValue * (isYearsSelected ? 12 : 1));
    setDisplayedSelectedItem(displayedItem);
  };
  return (
    <div className={styles.selectRoleCardContainerStyles}>
      <div className={styles.leftBlockStyles}>
        <div className={styles.closeImgStyles} onClick={onClickCloseImgClick}>
          <img
            src={closeBackgroundImg}
            alt="closeBackgroundImg"
            className={styles.imageStyles}
          />
        </div>
        <p className={styles.selectedRoleTextStyles}>{role}</p>
      </div>
      <InputWithDropDown
        popupOpen={showExperianceDurationPopup}
        onClick={() =>
          setShowExperianceDurationPopup(!showExperianceDurationPopup)
        }
        onClose={() => setShowExperianceDurationPopup(false)}
        leftIcon={experience || manualEntryOfDuration ? '' : warningCircleIcon}
        placeholder="Experience"
        rightIcon={dropdownarrow}
        customPlaceHolderStyles={styles.selectedMonthTextStyle}
        customSubWrapperStyles={styles.experianceContentInputStyles}
        customPopUpContainerStyles={styles.experiancePopupStyles}
        selectedItem={displayedSelectedItem}
      >
        <div className={styles.durationInMonthsBlockStyles}>
          {durationData?.map((item, index) => (
            <p
              className={styles.monthsTextStyle}
              key={index}
              onClick={() => {
                handleSelectData(item);
                setShowExperianceDurationPopup(false);
              }}
            >
              {item}
            </p>
          ))}
        </div>
        <div className={styles.inputAndRadioBtnsStyles}>
          <Input
            type="text"
            name="duration"
            value={manualEntryOfDuration}
            onChange={(e) => {
              const enteredValue = e.target.value.replace(/[^0-9.]/g, '');
              handleManualEntry(enteredValue);
            }}
            placeholder="Manual enter"
            customInputStyle={styles.manualEnteryInputStyles}
          />
          <div className={styles.monthAndRadioBtnBlockStyles}>
            {selectMonthAndYearsOptionsData?.map((item, index) => (
              <RadioButton
                key={index}
                lable={item.duration}
                isActive={item.value === durationData ? true : false}
                onSelect={() => {
                  handleSelectModeOfDuration(item.value);
                }}
              />
            ))}
          </div>
        </div>
      </InputWithDropDown>
    </div>
  );
};
export default SelectRoleCard;
