export const handleColor = (item) => {
  if (item === '01') {
    return '#D0FFCF';
  } else if (item === '02') {
    return '#FFE5CF';
  } else if (item === '03') {
    return '#FDCFFF';
  } else if (item === '04') {
    return '#FFC6C7';
  } else if (item === '') {
    return '#ffffff';
  } else {
    return 'skyblue';
  }
};

export const handleLeaveColor = (type) => {
  if (type === 'Unpaid') {
    return 'rgba(253, 160, 255, 0.25)';
  } else if (type === 'Sick') {
    return 'rgba(163, 255, 160, 0.25)';
  } else if (type === 'Paid') {
    return 'rgba(160, 210, 255, 0.25)';
  }
};

export const handleLeaveText = (text) => {
  if (text === 'Unpaid') {
    return '😎 Unpaid leave';
  } else if (text === 'Sick') {
    return '🤒 Sick leave';
  } else if (text === 'Paid') {
    return '🤑 Paid leave';
  }
};
